define('sags-front-bo/controllers/password-reset/finish', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['key'],
    passwordType: 'password',
    passwordRepeatType: 'password',

    actions: {
      editPassword: function editPassword(event) {
        this.set('password', event.target.value);
      },
      editRepeatPassword: function editRepeatPassword(event) {
        this.set('repeatPassword', event.target.value);
      },
      clickBlindPassword: function clickBlindPassword() {
        if (this.passwordType === "text") this.set('passwordType', 'password');else this.set('passwordType', 'text');
      },
      clickBlindRepeatPassword: function clickBlindRepeatPassword() {
        if (this.passwordRepeatType === "text") this.set('passwordRepeatType', 'password');else this.set('passwordRepeatType', 'text');
      }
    }
  });
});