define('sags-front-bo/routes/teams/create', ['exports', 'sags-front-bo/mixins/access-controller', 'lodash'], function (exports, _accessController, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    notifications: Ember.inject.service('notifications'),
    currentUser: Ember.inject.service(),

    templateName: 'teams/team/edit',
    controllerName: 'teams/team/edit',

    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);
      controller.set('editTeam', false);

      var users = this.store.query('user', {});
      controller.set('agents', users);

      users.then(function () {
        // s'assurer de charger aussi les équipes
        _this.store.findAll('team');
      });
      // réinitialiser le filtre "agents non rattachés" (pour la cohérence avec l'édition d'équipe)
      controller.set('withoutTeam', false);
    },
    resetController: function resetController(controller) {
      controller.set('selectedAgents', []);
      controller.set('selectedManager', null);
      controller.get('agents').forEach(function (agent) {
        return agent.set('isChecked', false);
      });
    },
    model: function model() {
      var team = this.store.createRecord('team');

      var currentUser = this.get('currentUser.user');
      if (currentUser.get('role') === 'SUPERVISOR' || 'SUPERVISOR_EQPT') {
        team.set('supervisor', currentUser);
      }

      return team;
    },


    actions: {
      cancel: function cancel() {
        this.get('controller.model').rollbackAttributes();
        this.transitionTo('teams');
      },
      save: function save() {
        var _this2 = this;

        this.set('controller.isLoadingSave', true);
        var model = this.get('controller.model');

        // on duplique la liste des agents choisis pour l'enregistrement (le manager est retiré de cette liste)
        var selectedAgents = [].pushObjects(this.controller.get('selectedAgents'));

        var selectedManager = this.controller.get('selectedManager');

        var selectedSupervisor = model.get('supervisor');

        if (_lodash.default.isEmpty(selectedAgents)) {
          this.get('notifications').error('Vous devez choisir au moins un agent !');
          this.set('controller.isLoadingSave', false);
        } else if (_lodash.default.isNil(selectedManager)) {
          this.get('notifications').error('Vous devez choisir un chef d\'équipe !');
          this.set('controller.isLoadingSave', false);
        } else if (_lodash.default.isNil(selectedSupervisor.get('identification'))) {
          this.get('notifications').error('Vous devez choisir un superviseur !');
          this.set('controller.isLoadingSave', false);
        } else {
          model.set('supervisor', selectedSupervisor);
          model.set('manager', selectedManager);

          // Supprimer le manager dans la list des agents de l'équipe.
          _lodash.default.remove(selectedAgents, function (agent) {
            return agent.get('identification') === selectedManager.get('identification');
          });

          model.set('agents', selectedAgents);

          model.save().then(function (team) {
            // mettre à jour les rôles, les superviseurs et l'équipe de tous les agents
            var promises = selectedAgents.map(function (agent) {
              agent.set('role', 'AGENT');
              agent.set('supervisor', selectedSupervisor);
              agent.set('team', team);
              return agent.save();
            });

            selectedManager.set('role', 'MANAGER');
            selectedManager.set('supervisor', selectedSupervisor);
            selectedManager.set('team', team);
            promises.push(selectedManager.save());

            // chaque modification d'agent = une requête = une promise
            // la mise à jour est terminée = toutes les promesses sont résolues
            return Ember.RSVP.all(promises);
          }).then(function () {
            _this2.get('notifications').success('L\'équipe a été créée avec succès');

            _this2.transitionTo('teams');
          }).catch(function () {
            _this2.get('notifications').error('L\'équipe n\'a pas pu être créée. Il est possible qu\'une autre équipe portant le même nom existe déjà.');
          }).finally(function () {
            return _this2.set('controller.isLoadingSave', false);
          });
        }
      },
      willTransition: function willTransition() {
        this.get('controller.model').rollbackAttributes();
      }
    }

  });
});