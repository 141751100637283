define('sags-front-bo/routes/administrator/purge-precontrols', ['exports', 'sags-front-bo/mixins/access-controller'], function (exports, _accessController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    setupController: function setupController(controller) {
      controller.set('serverSideFilters.date.value', new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(23, 59, 0, 0)));
      controller.get('refreshWithServerSideFilter')(controller, this.store);
    }
  });
});