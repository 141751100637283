define('sags-front-bo/components/ccsp-claim/other-attachments', ['exports', 'sweetalert', 'lodash', 'moment', 'sags-front-bo/utils/download-file', 'sags-front-bo/config/environment', 'sags-front-bo/helpers/file-extension-validator'], function (exports, _sweetalert, _lodash, _moment, _downloadFile2, _environment, _fileExtensionValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),

    columnsNames: [{ 'key': 'name', 'displayName': 'Nom du fichier' }],

    actionIcons: [{ icon: 'download', tooltip: 'Télécharger' }, { icon: 'trash', tooltip: 'Supprimer' }],

    actions: {
      downloadFile: function downloadFile(attachment) {
        var _this = this;

        this.set('isLoadingPdfResponse', true);
        this.get('ajax').request(_environment.default.APP.host + '/control/api/ccsp-claims/' + attachment.get('ccspClaim') + '/attachments?fileName=' + attachment.get('internalFileName'), {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          }
        }).then(function (file) {
          (0, _downloadFile2.default)(file.fileName, file.base64, 'application/pdf');
          var action = _this.get('store').createRecord('ccsp-claim-action-event', {
            'action': 'FILE_DOWNLOAD',
            'dateTime': (0, _moment.default)(),
            'agent': _this.get('currentUser.user'),
            'ccspClaim': _this.get('ccspClaim'),
            'fileName': attachment.get('name')
          });
          _this.get('updateActionEvents')(action, 'document annexe');
        }).finally(function () {
          return _this.set('isLoadingPdfResponse', false);
        });
      },
      deleteFile: function deleteFile(attachment) {
        var _this2 = this;

        (0, _sweetalert.default)({
          title: 'Confirmation',
          text: 'Voulez-vous vraiment supprimer cette pièce jointe ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmer',
          cancelButtonText: 'Annuler',
          target: '#app-container'
        }).then(function () {
          _this2.get('ajax').request(_environment.default.APP.host + '/control/api/ccsp-claims/attachments/' + attachment.get('id'), {
            method: 'DELETE',
            headers: {
              Authorization: 'Bearer ' + _this2.get('session.session.authenticated.id_token'),
              City: _this2.get('currentUser.city')
            },
            dataType: 'text',
            contentType: false,
            processData: false
          }).then(function () {
            _this2.get('notifications').success('La pièce jointe a été supprimée avec succès');
            var index = _this2.get('attachments').indexOf(attachment);
            _this2.get('attachments').removeAt(index);
            var action = _this2.get('store').createRecord('ccsp-claim-action-event', {
              'action': 'FILE_DELETION',
              'dateTime': (0, _moment.default)(),
              'agent': _this2.get('currentUser.user'),
              'ccspClaim': _this2.get('ccspClaim'),
              'fileName': attachment.get('name')
            });
            _this2.get('updateActionEvents')(action, 'document annexe');
          }).catch(function () {
            _this2.get('notifications').error('La pièce jointe n\'a pas pu être supprimée');
          });
        }).catch(function () {/* aucun traitement, mais on fait disparaître l'erreur dans la console */});
      },
      uploadAttachment: function uploadAttachment() {
        //  TODO: une fois que l'api de upload soit faite, alerter l'utilisateur dans le cas d'écrasement d'un fichier
        this.send('performUpload');
      },
      performUpload: function performUpload() {
        var _this3 = this;

        var file = $('#upload-file')[0].files[0];
        if (_lodash.default.isNil(file)) {
          this.get('notifications').error('Veuillez sélectionner un fichier');
        } else {
          var fileExtension = file.name.split('.').pop();
          if (!_fileExtensionValidator.default.compute(fileExtension)) {
            this.get('notifications').error('Ce type de fichier n\'est pas autorisé');
          } else {
            this.set('isUploading', true);
            var formData = new FormData();
            formData.append('file', file);
            this.get('ajax').request(_environment.default.APP.host + '/control/api/ccsp-claims/upload/attachments?fileName=' + file.name + '&type=ANNEX_DOCUMENT&ccspClaimId=' + this.get('ccspClaimId'), {
              method: 'POST',
              headers: {
                Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
                City: this.get('currentUser.city')
              },
              data: formData,
              dataType: 'text',
              contentType: false,
              processData: false
            }).then(function (response) {
              var latestUploadedFile = JSON.parse(response);
              _this3.get('attachments').pushObject(Ember.Object.create(latestUploadedFile));
              var action = _this3.get('store').createRecord('ccsp-claim-action-event', {
                'action': 'FILE_UPLOAD',
                'dateTime': (0, _moment.default)(),
                'agent': _this3.get('currentUser.user'),
                'ccspClaim': _this3.get('ccspClaim'),
                'fileName': latestUploadedFile.name
              });
              _this3.get('updateActionEvents')(action, "document annexe");
              _this3.get('notifications').success('Le fichier a été importé avec succès');
            }).catch(function () {
              _this3.get('notifications').error('Une erreur est survenue lors de l\'import du fichier');
            }).finally(function () {
              $('#upload-file').val('');
              _this3.set('isUploading', false);
            });
          }
        }
      }
    },

    ButtonVisibility: function ButtonVisibility(one, elementId, currentUser) {
      var currentUserRole = currentUser.user.data.role;

      one.set('isIcon1Visible_' + elementId, Ember.computed('state', function () {
        return currentUserRole !== 'TERRITORY_MANAGER';
      }));
    }
  });
});