define('sags-front-bo/controllers/controls/monitoring', ['exports', 'lodash', 'sags-front-bo/config/environment'], function (exports, _lodash, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set;
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    monitoring: {},
    isLoadingImport: false,
    date: new Date(),
    error: false,

    initRequest: function () {
      this.getMonitoring(new Date());
    }.on('init'),

    getMonitoring: function getMonitoring() {
      var _this = this;

      set(this, 'isLoadingImport', true);

      return this.get('ajax').request(_environment.default.APP.host + '/control/api/monitoring-by-date?date=' + this.date.toLocaleDateString(), {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          city: this.get('currentUser.city'),
          startDatetime: this.selectedDate
        },
        contentType: 'application/json'
      }).then(function (data) {
        // Tri par ordre alphabétique
        var sortedData = _lodash.default.mapValues(data, function (obj) {
          var sortedKeys = _lodash.default.sortBy(_lodash.default.keys(obj), function (key) {
            if (key === 'AA') {
              return 'ZZ'; // Placer 'AA' après 'Z'
            }
            return key;
          });
          return _lodash.default.zipObject(sortedKeys, _lodash.default.map(sortedKeys, function (key) {
            return obj[key];
          }));
        });

        _this.set('monitoring', sortedData);
      }).catch(function (reason) {
        _this.set('error', true);
        _this.set('monitoring', reason.message);
      }).finally(function () {
        set(_this, 'isLoadingImport', false);
      });
    },

    actions: {
      setDate: function setDate(date) {
        this.set('date', date);
        this.getMonitoring(date);
      },
      reload: function reload() {
        this.getMonitoring(this.date);
      }
    }
  });
});