define('sags-front-bo/controllers/users/user/edit', ['exports', 'sags-front-bo/utils/file-input-to-model', 'lodash'], function (exports, _fileInputToModel, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service(''),
    planningController: Ember.inject.controller('teams.planning'),

    isPlanningAgentOnly: Ember.computed('currentUser.user.role', function () {
      return this.get('currentUser.user.role') === 'AGENT' | this.get('currentUser.user.role') === 'MANAGER';
    }),

    actions: {
      onFileChanged: function onFileChanged(event) {
        (0, _fileInputToModel.default)(event, this.get('model'), 'photoBase64', 'photo');
      },
      selectSupervisor: function selectSupervisor(supervisor) {
        this.set('model.supervisor', supervisor);
      },
      selectCities: function selectCities(cities) {
        this.set('model.cities', cities);
      },
      selectCity: function selectCity(city) {
        this.set('model.city', city);
      },
      planning: function planning() {
        this.set('planningController.agentEdit', this.get('model'));
        this.set('planningController.isPlanningAgentOnly', true);
      },
      setStartDatetime: function setStartDatetime(startDatetime) {
        if (_lodash.default.isNil(startDatetime)) {
          startDatetime = new Date();
          startDatetime.setHours(0, 0, 0, 0);
          this.set('startDatetime', startDatetime);
        }
        Ember.run.next(this, function () {
          this.send('getStatistics', startDatetime, this.get('endDatetime'));
        });
      },
      setEndDatetime: function setEndDatetime(endDatetime) {
        if (_lodash.default.isNil(endDatetime)) {
          endDatetime = new Date();
          endDatetime.setHours(23, 59, 59, 59);
          this.set('endDatetime', endDatetime);
        }
        Ember.run.next(this, function () {
          this.send('getStatistics', this.get('startDatetime'), endDatetime);
        });
      }
    }
  });
});