define('sags-front-bo/helpers/gps-position-corrected', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.gpsPositionCorrected = gpsPositionCorrected;
  function gpsPositionCorrected(params) {

    var result = false;
    var latitude = params[0];
    var longitude = params[1];

    if (latitude !== undefined && longitude !== undefined) {
      var latitudeString = latitude.toString();
      var longitudeString = longitude.toString();

      if (latitudeString.length <= 9 && longitudeString.length <= 8) {
        result = true;
      }
    }

    return result;
  }

  exports.default = Ember.Helper.helper(gpsPositionCorrected);
});