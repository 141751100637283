define('sags-front-bo/models/parking-ticket', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    startDate: _emberData.default.attr('date'),
    endDate: _emberData.default.attr('date'),
    tariffType: _emberData.default.attr('string'),
    urbanParkingSiteId: _emberData.default.attr(),
    chargePaid: _emberData.default.attr('number'),
    parkingPermits: _emberData.default.attr(),
    usedMeansOfPayment: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    significant: _emberData.default.attr('boolean'),
    locationUncertain: _emberData.default.attr('boolean'),
    profilTicketConcentrator: _emberData.default.attr('string'),
    valid: _emberData.default.attr('boolean')
  });
});