define('sags-front-bo/controllers/controls/nfps', ['exports', 'lodash', 'sags-front-bo/config/environment'], function (exports, _lodash, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DATE_FORMAT = { year: 'numeric', month: '2-digit', day: '2-digit' };
  var computed = Ember.computed;
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    nfpsList: [{}],
    nfpsFocus: null,
    isNfpsFocus: false,
    allNfpsByDate: {},
    isLoading: false,
    isOK: true,
    isOKPicture: false,
    errorMessage: "",
    filters: {
      date: new Date(),
      vehiculePlate: ''
    },
    pictureList: null,
    page: 1,
    pageSize: 10,

    paginatedData: computed('nfpsList.[]', 'page', 'pageSize', function () {
      var page = this.page,
          pageSize = this.pageSize,
          nfpsList = this.nfpsList;

      var startIndex = (page - 1) * pageSize;
      var endIndex = startIndex + pageSize;
      return nfpsList.slice(startIndex, endIndex);
    }),
    totalPages: computed('nfpsList.[]', 'pageSize', function () {
      return Math.ceil(this.nfpsList.length / this.pageSize);
    }),
    initRequest: function () {
      this.getNfpsByDate(new Date());
    }.on('init'),
    filterPlate: function filterPlate() {
      var _this = this;

      var nfpsFiltre = _lodash.default.filter(this.allNfpsByDate, function (nfps) {
        return _lodash.default.includes(nfps.vehiclePlate.toLowerCase(), _this.filters.vehiculePlate.toLowerCase());
      });
      this.set('nfpsList', nfpsFiltre);
      if (this.page > this.get('totalPages')) {
        this.set('page', this.get('totalPages'));
      } else if (this.page < 1) {
        this.set('page', 1);
      }
    },
    getNfpsByDate: function getNfpsByDate() {
      var _this2 = this;

      this.set('isLoading', true);
      return this.get('ajax').request(_environment.default.APP.host + '/control/api/nfps?date=' + this.filters.date.toLocaleDateString('fr', DATE_FORMAT), {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          city: this.get('currentUser.city'),
          startDatetime: this.selectedDate
        },
        contentType: 'application/json'
      }).then(function (data) {
        _this2.set('isOK', true);
        _this2.set('allNfpsByDate', data);
        _this2.filterPlate();
      }).catch(function (reason) {
        _this2.set('isOK', false);
      }).finally(function () {
        _this2.set('isLoading', false);
      });
    },
    getNfpsById: function getNfpsById(id) {
      var _this3 = this;

      this.set('isLoading', true);
      return this.get('ajax').request(_environment.default.APP.host + '/control/api/get-nfps-by-id?id=' + id, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          city: this.get('currentUser.city'),
          startDatetime: this.selectedDate
        },
        contentType: 'application/json'
      }).then(function (data) {
        _this3.set('nfpsFocus', data);
        _this3.set('isNfpsFocus', true);
        _this3.setPicture();
      }).finally(function () {
        _this3.set('isLoading', false);
      });
    },
    setPicture: function setPicture(id) {
      this.set('isLoading', true);
      var controlPicture = this.get('nfpsFocus.controlPictureDTOList');
      var precontrolPicture = this.get('nfpsFocus.precontrolPictureDTOList');
      if (controlPicture !== null && controlPicture.length > 0) {
        this.set('pictureList', controlPicture);
      } else if (precontrolPicture !== null && precontrolPicture.length > 0) {
        this.set('pictureList', precontrolPicture);
      } else {
        this.set('pictureList', null);
      }
    },

    actions: {
      nextPage: function nextPage() {
        if (this.page < this.get('totalPages')) {
          this.incrementProperty('page');
        }
      },
      previousPage: function previousPage() {
        if (this.page > 1) {
          this.decrementProperty('page');
        }
      },
      changeDate: function changeDate() {
        this.getNfpsByDate();
      },
      filter: function filter() {
        this.filterPlate();
      },
      openModal: function openModal(id) {
        this.getNfpsById(id);
      },
      closeModal: function closeModal() {

        this.set('nfpsFocus', null);
      }
    }
  });
});