define('sags-front-bo/controllers/administrator/external-server-error', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    tableColumns: [{
      'key': 'partnerName',
      'displayName': 'Nom du prestataire'
    }, {
      'key': 'errorMessage',
      'displayName': 'Message d\'erreur'
    }, {
      'key': 'errorDate',
      'displayName': 'Date de l\'erreur'
    }, {
      'key': 'countError',
      'displayName': 'Nombre d\'erreur'
    }],
    filters: {
      ErrorDate: {
        comparator: 'contains',
        value: ''
      }
    },
    serverSideFilters: {
      creationDate: {
        comparator: 'ErrorDate',
        value: new Date()
      }
    },

    refreshWithServerSideFilter: function refreshWithServerSideFilter(controller, store) {
      var creationDate = controller.get('serverSideFilters.creationDate.value');
      if (_lodash.default.isNil(creationDate)) {
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        // Remplir le champ "Date de création" avec la date du jour
        controller.set('serverSideFilters.creationDate.value', today);
        // Filtre par la date d'aujourd'hui
        controller.set('content', store.query('external-server-error', {
          creationDate: today.toLocaleDateString()
        }));
      } else {
        creationDate.setHours(0, 0, 0, 0);
        var query = store.query('external-server-error', {
          creationDate: creationDate.toLocaleDateString()
        });
        controller.set('content', query);
      }
    },

    actions: {
      setCreationDate: function setCreationDate() {
        this.refreshWithServerSideFilter(this, this.store);
      },
      reload: function reload() {
        this.refreshWithServerSideFilter(this, this.store);
      }
    }
  });
});