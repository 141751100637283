define('sags-front-bo/controllers/white-list-bis/control-mode', ['exports', 'sags-front-bo/utils/dictionary', 'sags-front-bo/config/environment'], function (exports, _dictionary, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service('current-user'),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    selectedMode: -1,
    controlModes: _dictionary.default.convertToArray(_dictionary.default.controlModes),

    actions: {
      changeModeValue: function changeModeValue(key) {
        var _this = this;

        if (this.selectedMode === key) {
          return;
        }
        this.set('selectedMode', key);

        var value = this.selectedMode;

        this.get('ajax').request(_environment.default.APP.host + '/control/api/white-list-bis-mode', {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          },
          data: {
            key: 'controlMode',
            datatype: 'INTEGER',
            value: value
          },
          contentType: 'application/json'
        }).then(function () {
          _this.get('notifications').success("Mode de contrôle mis à jour");
        }).catch(function (error) {
          _this.get('notifications').error(error);
        });
      }
    }
  });
});