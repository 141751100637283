define('sags-front-bo/utils/dictionary', ['exports', 'lodash', 'sags-front-bo/config/environment'], function (exports, _lodash, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  // Permet de convertir les identifiants techniques en texte affichable.

  var dictionary = {
    convertToArray: function convertToArray(dictionary) {
      var prepend = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      var append = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      var keyName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'key';
      var valueName = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'value';

      var array = prepend;
      Object.keys(dictionary).forEach(function (key) {
        var item = {};
        item[keyName] = key;
        item[valueName] = dictionary[key];
        array.push(item);
      });
      append.forEach(function (obj) {
        return array.push(obj);
      });
      return array;
    },
    convertToPowerSelectOptions: function convertToPowerSelectOptions(dictionary) {
      var prepend = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      var append = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

      return this.convertToArray(dictionary, prepend, append, 'name', 'value');
    },
    fetchSettingDictionaries: function fetchSettingDictionaries(ajax, session, currentUser) {
      return ajax.request(_environment.default.APP.host + '/control/api/settings', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + session.get('session.authenticated.id_token'),
          City: currentUser.get('city')
        },
        contentType: 'application/json'
      }).then(function (settings) {
        var result = {
          fpsReasons: {},
          controlIssues: {},
          tariffZones: {},
          tariffZonesFps: {},
          residentialSectors: {},
          observationCategories: {},
          tariffZonesColor: {},
          tariffZonesColorsFps: {},
          siret: settings.siret,
          ccspWebSiteUrl: settings.ccspWebSiteUrl,
          // Paramètres optionnels, falsy si non présent dans control_ville.settings
          isFpsPicturesVisible: settings.isFpsPicturesVisible,
          isBanVisible: settings.isBanVisible,
          zoomVAO: settings.zoomVAO,
          typeMap: settings.typeMap,
          frequentIssues: [],
          isVehicleTypeRequired: true,
          vehicleTypes: {},
          statregColors: []
        };
        settings.fpsReasons.forEach(function (reason) {
          return result.fpsReasons[reason.code] = reason.shortLabel;
        });
        settings.controlIssues.forEach(function (issue) {
          return result.controlIssues[issue.issue] = issue.name;
        });
        settings.tariffZones.forEach(function (zone) {
          return result.tariffZones[zone.id] = zone.label;
        });
        settings.tariffZonesFps.forEach(function (zone) {
          return result.tariffZonesFps[zone.id] = zone.label;
        });
        settings.resSectors.forEach(function (sector) {
          return result.residentialSectors[sector.id] = sector.label;
        });
        settings.observations.forEach(function (category) {
          return result.observationCategories[category.categoryName] = category.reasons;
        });
        settings.tariffZones.forEach(function (zone) {
          return result.tariffZonesColor[zone.id] = zone.color;
        });
        settings.tariffZonesFps.forEach(function (zone) {
          return result.tariffZonesColorsFps[zone.id] = zone.color;
        });
        settings.statregColors.forEach(function (statreg) {
          return result.statregColors[statreg.label] = statreg.color;
        });

        // Les issues spécifiques à la ville. Si vide ou inexistantes, ne se passe rien.
        if (settings.specifiqueControlIssues) {
          settings.specifiqueControlIssues.forEach(function (issue) {
            return result.controlIssues[issue.issue] = issue.name;
          });
        }
        // Les accès rapides aux issues les plus fréquentes
        if (settings.frequentIssues) {
          settings.frequentIssues.forEach(function (issue) {
            var element = {};
            element.key = issue;
            element.value = result.controlIssues[issue];
            result.frequentIssues.push(element);
          });
        }
        // Les types de véhicules. Pas nécessaire si non requis.
        if (result.isVehicleTypeRequired && settings.vehicleTypes) {
          settings.vehicleTypes.forEach(function (type) {
            return result.vehicleTypes[type.id] = type.type;
          });
        }
        return result;
      });
    },
    fetchSettingsFromRapo: function fetchSettingsFromRapo(ajax, session, currentUser) {
      return ajax.request(_environment.default.APP.host + '/rapo/api/settings', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + session.get('session.authenticated.id_token'),
          City: currentUser.get('city')
        },
        contentType: 'application/json'
      }).then(function (settings) {
        var result = {
          doubleValidationEnabled: settings.doubleValidationEnabled
        };
        return result;
      });
    },
    fetchSettingsForWhiteListBis: function fetchSettingsForWhiteListBis(ajax, session, currentUser) {
      return ajax.request(_environment.default.APP.host + '/control/api/settings', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + session.get('session.authenticated.id_token'),
          City: currentUser.get('city')
        },
        contentType: 'application/json'
      }).then(function (settings) {
        var result = {
          nbPlateCharacters: settings.nbPlateCharacters,
          autoAddApp: settings.autoAddApp,
          autoAddRAPO: settings.autoAddRAPO,
          controlMode: settings.controlMode
        };
        return result;
      });
    },


    pmrQuestions: {
      '6802711': 'true',
      '6802717': 'true',
      '6802723': 'false'
    },

    codifications: {
      1: 'Contrôle',
      2: 'Saisie',
      3: 'RAPO'
    },

    controlModes: {
      '1': 'Mode standard',
      '2': 'Mode LSS 1',
      '3': 'Mode contrôle 2'
    },

    autoModes: {
      'autoAddApp': 'Ajout auto plaque contrôlée valide appli',
      'autoAddRAPO': 'Ajout auto plaque contrôlée RAPO'
    },

    civilities: {
      'MR': 'Monsieur',
      'MRS': 'Madame',
      'COM': 'Société'
    },

    rapoStatus: {
      'TO_INSTRUCT': 'A instruire',
      'INSTRUCTING': 'En instruction',
      'TO_REINSTRUCT': 'A ré-instruire',
      'REINSTRUCTING': 'En ré-instruction',
      'TO_VALIDATE': 'Instruction à valider',
      'TO_RESPOND': 'Réponse à envoyer',
      'RESPONDED': 'Réponse envoyée',
      'TO_SEND_COMPLETION': 'Complétion à envoyer',
      'TO_COMPLETE': 'À compléter'
    },
    rapoIssue: {
      'ACCEPTANCE': 'Annulation du FPS',
      'REJECTION': 'Rejet du RAPO',
      'CORRECTION': 'FPS rectificatif'
    },
    // Les identifiants des motifs proviennent du formulaire papier de création de RAPO
    rapoMotivation: {
      '11': 'Mon véhicule a été volé ou détruit avant que l\'absence de paiement immédiat ne soit constatée',
      '12': 'Je ne suis pas le titulaire de la carte grise',
      '13': 'Mon véhicule a été cédé ou vendu avant que l\'absence de paiement immédiat ne soit constatée',
      '14': 'Mes plaques ont été usurpées',

      '21': 'Je n\'avais pas à payer le stationnement car je bénéficie d\'une gratuité permanente (carte de stationnement pour personnes à mobilité réduite, service public, ...) pour laquelle je prouve que le justificatif correspondant était correctement apposé dans le véhicule',
      '22': 'Je n\'avais pas à payer le stationnement car la période concernée bénéficiait d\'une gratuité temporaire (épisode pollution, période quotidienne gratuite, ...)',
      '23': 'Je prouve que le justificatif du paiement immédiat de la redevance était correctement apposé à l\'intérieur du véhicule et que sa durée de validité n\'avait pas expiré au moment où l\'avis de paiement du FPS a été établi',
      '24': 'Je prouve que la durée de validité du FPS déjà émis n\'avait pas expiré au moment où le nouvel avis de paiement du FPS a été établi',
      '25': 'Je prouve que le justificatif du paiement immédiat de la redevance avait été transmis par voie dématérialisée et que sa durée de validité n\'avait pas expiré au moment où l\'avis de paiement du FPS a été établi',

      '31': 'Le montant du tarif du FPS mentionné dans l\'avis de paiement, hors déduction du montant de la redevance payée immédiatement, est erroné',
      '32': 'Le justificatif du paiement immédiat de la redevance non valide pris en compte dans l\'avis de paiement établi est exact mais je prouve que le montant de la déduction retenu ne correspond pas à celui indiqué sur ce justificatif en transmettant sa copie',
      '33': 'Le justificatif du paiement immédiat de la redevance non valide pris en compte dans l\'avis de paiement établi n\'est pas celui qui aurait dû être retenu pour effectuer la déduction',

      '41': 'Absence ou insuffisance de marquage au sol',
      '42': 'Absence de panneau de signalisation au droit de l\'horodateur',
      '43': 'Absence de panneau de signalisation en entrée de zone payante',

      '51': 'L\'avis de paiement du FPS est incomplet ou mal rédigé (hors mention relative au montant du FPS)',
      '53': 'La durée de validité indiquée sur le précédent avis de paiement qui m\'a été délivré est erronée et rend nul et non avenu l\'avis de paiement contesté',
      '54': 'Les textes instituant le stationnement payant ne sont pas conformes',
      '55': 'Le FPS ne comporte pas certaines mentions obligatoires ou comporte des mentions erronées',
      '56': 'Autres motifs de contestation'
    },

    rapoMotivationShort: {
      '11': 'Véhicule volé ou détruit',
      '12': 'Usager non titulaire de la carte grise',
      '13': 'Véhicule cédé ou vendu',
      '14': 'Plaques usurpées',

      '21': 'Bénéficie d\'une gratuité permanente',
      '22': 'Bénéficiait d\'une gratuité temporaire',
      '23': 'Ticket valide et correctement apposé',
      '24': 'FPS précédent toujours valide',
      '25': 'Ticket dématérialisé valide',

      '31': 'Montant erroné (hors déduction)',
      '32': 'Déduction incorrecte',
      '33': 'Ticket incorrect pris en compte pour la déduction',

      '41': 'Absence ou insuffisance de marquage au sol',
      '42': 'Absence de panneau au droit de l\'horodateur',
      '43': 'Absence de panneau en entrée de zone payante',

      '51': 'FPS incomplet ou mal rédigé',
      '53': 'Durée de validité erronée sur le FPS',
      '54': 'Textes légaux non conformes',
      '55': 'Mentions obligatoires manquantes sur le FPS',
      '56': 'Autre'
    },

    rapoAttachmentTypes: {
      'FPS_SCAN': 'Copie de l\'avis de paiement contesté',
      'REGISTRATION_PROOF': 'Copie du certificat d\'immatriculation du véhicule',
      'SELLING_PROOF': 'Copie de la déclaration de cession du véhicule',
      'MANDATE_PROOF': 'Copie de l\'acte d\'habilitation de la personne désignée par le titulaire du certificat d\'immatriculation, le locataire ou le nouvel acquéreur',
      'TICKET_SCAN_OR_MOBILE_PAYMENT_PROOF': 'Copie du ticket ou facture d\'achat paiement mobile',
      'WINDSHIELD_PHOTO': 'Photo du pare brise justifiant de l\'apposition des éléments relatifs au stationnement (vignette résident, badge, ticket, etc...)',
      'PMR_PROOF': 'Copie du justificatif (carte PMR, badge, ou autre justificatif)',
      'CONTEXT_PHOTO': 'Photo de contexte',
      'LAW_TEXT': 'Textes relatifs au stationnement payant',
      'LAW_TEXT_MANDATORY_MENTIONS': 'Textes relatifs aux mentions obligatoires portées sur le FPS',
      'PREVIOUS_FPS_SCAN': 'Copie du précédent FPS',
      'DESTRUCTION_OR_COMPLAINT_FILING_PROOF': 'Certificat de destruction du véhicule ou procès-verbal de dépôt de plainte',
      'SELLING_REGISTRATION_PROOF': 'Copie de l\'accusé d\'enregistrement de la cession dans le système d\'immatriculation des véhicules',
      'COMPLAINT_FILING_PROOF': 'Procès-verbal de dépôt de plainte ou récepissé d\'enregistrement de main courante',
      'MOTIVATION': 'Motif',
      'OTHER_ATTACHMENT': 'Pièce jointe supplémentaire',
      'RAPO_FORM': 'Courrier numérisé'
    },

    // List des roles de user
    userRoles: {
      'ADMIN': 'Administrateur technique',
      'ANONYMOUS': 'Anonyme',
      'TECHNICAL': 'Utilisateur technique',
      'LAPI': 'LAPI',
      'AGENT': 'Agent',
      'AGENT_BO': 'Agent BO',
      'MANAGER': 'Chef d\'équipe',
      'SUPERVISOR': 'Superviseur',
      'ADMINISTRATOR': 'Administrateur',
      'RAPO_MANAGER': 'Responsable RAPO',
      'TERRITORY_MANAGER': 'Responsable territorial',
      'CITY': 'Ville',
      'AGENT_VAO': 'Agent VAO',
      'VERIF_VAO': 'Agent Vérification VAO',
      'CITY_CCSP': 'Ville CCSP',
      'CITY_VISU': 'Visualisation des villes',
      'TERRITORY_MANAGER_EQPT': 'Responsable territorial équipement',
      'SUPERVISOR_EQPT': 'Superviseur équipement'
    },

    // List statuts de user
    userStatuses: {
      'INITIALIZED': 'Initialisé',
      'ACTIVE': 'Actif',
      'BLOCKED': 'Bloqué',
      'CLOSED': 'Fermé'
    },
    fpsType: {
      'INITIAL': 'Initial',
      'CORRECTION': 'Rectificatif',
      'CANCELLED': 'Annulé'
    },
    fpsStatus: {
      'INITIALIZED': 'Initialisé',
      'SENT': 'Envoyé',
      'CANCELLED': 'Annulé'
    },
    vehiculeType: {
      'CAR': 'VL',
      'BUS': 'Bus',
      'TRUCK': 'PL',
      'MOTORBIKE': '2RM',
      'UNDEFINED': 'Non défini',
      'UNREGULATED': 'Non réglementé'

    },
    nfpsStatus: {
      'NO_FPS_TECHNICAL': 'NFPS Technique',
      'NO_FPS_NO_TECHNICAL': 'NFPS non technique',
      'CONFIRMED': 'PMR confirmé'
    },
    nfpsReason: {
      'PMR': 'Véhicule sur place PMR',
      'UNPAID_PARKING': 'Hors bande payante',
      'PMR_MACARON': 'HDMR / Ticket CMI',
      'CITY_MACARON': 'Macaron Ville',
      'PROCESSED_PRECONTROL': 'Pré-contrôle déjà traité',
      'OK_WITH_TICKET': 'Ticket valide',
      'OK_WITH_TICKET_PMR': 'Ticket PMR valide',
      'FOREIGN_PLATE': 'Plaque étrangère',
      'UNREADABBLE_PLATE': 'Plaque illisible',
      'TWO_WHEELS': '2 roues',
      'STOP_CIRCULATION': 'Présence dans le véhicule ou à proximité',
      'DELIVERY_PLACE': 'Place réservée (livraison, PMR, taxi, trsp fonds, …)',
      'TICKET_VALID_BAD_ZONE': 'Ticket valide mais mauvaise zone',
      'OTHER': 'Autre',
      'OK': 'Véhicule autorisé',
      '7505': 'Non payé',
      '7506': 'Ticket expiré',
      'WHITE_LIST': 'Liste blanche',
      'CAR_STOP': 'Véhicule à l’arrêt',
      'CAR_ON_ROAD': 'Véhicule en circulation',
      'PLACE_FORBIDDEN': 'stationnement interdit',
      'SUBSCRIPTION_VALID': 'Abonnement valide',
      'TOLERANCE_TIME': 'Tolérance temps dépassé',
      'NO_FRONT_PHOTO': 'Vue arrière',
      'ALREADY_TREATED': 'Déjà traité',
      'VEHICLE_STOP': 'Présence dans le véhicule ou à proximité',
      'WRONG_ADDRESS': 'Nom de rue incorrect',
      'NOT_A_BUS_PARKING_SPACE': 'Autocar hors place autocar',
      'WRONG_SCAN': 'Scan ne correspond pas à un véhicule',
      'OVEREXPOSED_PHOTO': 'Photo contexte sur/sous-exposée',
      'OTHER_ISSUE': 'Problème technique autre',
      'FALSE_PLATE': 'Plaque dissimulée ou falsifiée',
      'VALID_AFTER_RECHECK': 'Contrôle valide post correction'
    },
    nfpsUserType: {
      'PDA': 'Agent en trottinette',
      'AUTOMATED_CONTROL_VEHICLE': 'LAPI'
    },
    fpsCancellationReason: {
      'PRINT_ERROR': 'Erreur d’impression du FPS',
      'USER_AT_TICKET_METER': 'Usager à l’horodateur au moment du contrôle',
      'SERVICE_VEHICLE': 'Véhicule de fonction (Ville/SAGS)',
      'VALID_PARKING': 'Stationnement valide (ticket, abonnement, PMR)',
      'CITY_INDULGENCE': 'Indulgence de la Ville',
      'DUPLICATE': 'Doublon',
      'FPS_TEST': 'FPS émis dans le cadre de tests',
      'FREE_ZONE': 'Zone non soumise au stationnement payant',
      'ENTRY_ERROR': 'Erreur de saisie lors de l’émission du FPS (marque du véhicule, immatriculation)',
      'OTHER': 'Autre'
    },
    paymentStatus: {
      'PAID': 'Payé',
      'OVERPAID': 'Remboursement en attente',
      'PENDING': 'Paiement en attente'
    },

    parkingStatus: {
      'true': 'En règle',
      'false': 'En infraction'
    },

    controlStatus: {
      'WAITING': 'En attente de statut',
      'NO_FPS': 'Pas de FPS créé',
      'FPS_CREATED': 'FPS créé',
      'CONFIRMED': 'PMR confirmé',
      'NO_FPS_TECHNICAL': 'NFPS technique',
      'NO_FPS_NO_TECHNICAL': 'NFPS non technique'
    },

    tariffType: {
      'TICKET': 'Visiteur',
      'SUBSCRIPTION': 'Résident',
      'OTHER': 'Autre'
    },

    ticketType: {
      'FINE': 'FPS',
      'TICKET': 'Ticket',
      'CONTRACT': 'Abonnement',
      'FREE': 'Libre de stationnement',
      'OTHER': 'Autre'
    },

    parkingPermit: {
      'EMPLOYEE_PERMIT': 'Employé',
      'RESIDENT_PERMIT': 'Résident',
      'ELECTRIC_VEHICLE_PERMIT': 'Véhicule électrique',
      'DISABLED_PERMIT': 'PMR',
      'SUBSCRIBER_PERMIT': 'Abonnemnent',
      'FREE_TIME_PERMIT': 'Libre de stationnement',
      'NO_PERMIT': 'Aucun',
      'ARTISAN': 'Artisan',
      'HEALTH_PROFESSIONNAL': 'Professionnel Santé',
      'VISITOR': 'Horaire'
    },

    preControlStatus: {
      'CREATED': 'Créé',
      'VEHICLE_ABSENT': 'Véhicule absent',
      'VEHICLE_VALID': 'Véhicule en règle',
      'LOCKED': 'Réservé',
      'PROCESSED': 'Pris en compte',
      'REVIEWED': 'Précontrolé'
    },

    customerType: {
      'VISITOR': 'Visiteur',
      'SUBSCRIPTOR': 'Abonné',
      'PMR': 'PMR'
    },

    pictureType: {
      'PMR': 'PMR',
      'PLATE': 'Plaque d\'immatriculation',
      'WINDSHIELD': 'Pare-brise',
      'GLOBAL': 'Vue d\'ensemble'
    },

    deviceType: {
      'PHONE': 'Téléphone',
      'TABLET': 'Tablette'
    },
    deviceStatus: {
      'WORKING': 'En service',
      'BLOCKED': 'Bloqué'
    },

    // /!\ Ultra important de conserver l'ordre d'apparition dans la liste d'instructions du RAPO /!\
    rapoInstruction: {
      'INVALID_TICKET': 'Un ticket valide est présent, la date de début et la date de fin sont dans la tranche horaire de création du FPS',

      'DEADLINE_RESPECTED': 'Requête introduite hors-délai : Aux termes de l’article R.2333-120-13 du Code général des collectivités territoriales le recours administratif préalable obligatoire prévu au VI de l’article L. 2333-87 du même code doit être exercé dans le délai d’un mois à compter de la date de notification de l’avis de paiement du FPS. En l’espèce, le recours ayant été introduit en dehors du délai légalement prévu, la demande est manifestement irrecevable et le recours doit être rejeté.',
      'REQUEST_CHANNEL_RESPECTED': 'Envoi du RAPO en lettre simple : Aux termes de l’article R.2333-120-13 1° du Code général des collectivités territoriales le recours administratif préalable obligatoire prévu au VI de l’article L.2333-87 du même code doit être présenté par lettre recommandée avec demande d’avis de réception ou, le cas échéant, par l’intermédiaire du procédé électronique mentionné dans l’avis de paiement à peine d’irrecevabilité. En l’espèce, le recours n’ayant pas été exercé conformément aux exigences prévues par la loi, la demande est manifestement irrecevable et le recours doit être rejeté.',
      'REGISTRATION_CERTIFICATE_PRESENT': 'Absence du certificat d’immatriculation : Aux termes de l’article R.2333-120-13 3° du Code général des collectivités territoriales le recours administratif préalable obligatoire prévu au VI de l’article L.2333-87 du même code doit être accompagné d’une copie du certificat d’immatriculation du véhicule concerné. En l’espèce, le recours n’étant pas accompagné de l’intégralité des pièces exigées, la demande est manifestement irrecevable et le recours doit être rejeté.',
      'FPS_COPY_PRESENT': 'Absence de la copie du FPS : Aux termes de l’article R.2333-120-13 3° du Code général des collectivités territoriales le recours administratif préalable obligatoire prévu au VI de l’article L.2333-87 du même code doit être accompagné d’une copie de l’avis de paiement contesté. En l’espèce, le recours n’étant pas accompagné de cette pièce obligatoire, la demande est manifestement irrecevable et le recours doit être rejeté.',
      'MOTIVE_RAPO': 'Absence de motivation du recours : Aux termes de l’article R.2333-120-13 2° du Code général des collectivités territoriales le recours administratif préalable obligatoire prévu au VI de l’article L.2333-87 du même code doit être assorti de l’exposé des faits et moyens sur lesquels la demande est fondée à peine d’irrecevabilité. En l’espèce, la demande formulée ne comportant aucun argument, celle-ci est infondée et le recours doit être rejeté. Exemple : recours demandant uniquement l’annulation du FPS sans aucun motif pour la justifier. ',
      'VEHICLE_CESSION': 'Absence de pièces justificatives obligatoires dans le cas d’une vente : Aux termes de l’article R.2333-120-13 3° du Code général des collectivités territoriales le recours administratif préalable obligatoire prévu au VI de l’article L.2333-87 du même code doit être accompagné d’une copie du certificat d’immatriculation du véhicule concerné ou de la déclaration de cession du véhicule et de son accusé d’enregistrement dans le système d’immatriculation des véhicule et, le cas échéant, des pièces permettant d’apprécier le bien-fondé de la demande à peine d’irrecevabilité. En l’espèce, le recours n’étant pas accompagné de l’intégralité des pièces exigées, la demande est manifestement irrecevable et le recours doit être rejeté.',
      'MANDATARY': 'Incompétence de l’auteur du recours (absence de mandat) : Aux termes de l’article R.2333-120-13 du Code général des collectivités territoriales le titulaire du certificat d’immatriculation, le locataire ou l’acquéreur du véhicule ayant fait l’objet d’un forfait post-stationnement peut habiliter toute personne pour former en son nom et pour son compte le recours administratif préalable obligatoire prévu au VI de l’article L.2333-87 du même code, le mandat devant être produit avec le recours. En l’espèce, au regard de l’absence de production du mandat par le titulaire du certificat d’immatriculation du véhicule, la demande est manifestement irrecevable et le recours doit être rejeté. Exemple : une personne demande à un tiers de formuler son recours pour lui mais ne produit pas de mandant dans ses pièces jointes',

      'COURTESY': 'Arguments injurieux : Aux termes de l’article R.2333-120-13 2° du Code général des collectivités territoriales le recours administratif préalable obligatoire prévu au VI de l’article L.2333-87 du même code doit être assorti de l’exposé des faits et moyens sur lesquels la demande est fondée à peine d’irrecevabilité. En l’espèce, seuls des arguments injurieux ayant été exposés, la demande est infondée et le recours doit être rejeté. Exemples : dans son recours la personne insulte l’agent de contrôle, la société, la commune, le maire …',
      'ARGUMENT_FOR_FPS': 'Arguments sans rapport avec le FPS contesté : Aux termes de l’article R.2333-120-13 2° du Code général des collectivités territoriales le recours administratif préalable obligatoire prévu au VI de l’article L.2333-87 du même code doit être assorti de l’exposé des faits et moyens sur lesquels la demande est fondée à peine d’irrecevabilité. En l’espèce, seuls des arguments sans rapport avec l’avis de paiement contesté ayant été exposés, la demande est infondée et le recours doit être rejeté. Exemples : « il pleuvait », « j’étais pressé » …',
      'SAME_PLATE_FPS': 'Cas de l’erreur de saisie de la plaque d’immatriculation : Aux termes de l’article R.2333-120-13 2° du Code général des collectivités territoriales le recours administratif préalable obligatoire prévu au VI de l’article L.2333-87 du même code doit être assorti de l’exposé des faits et moyens sur lesquels la demande est fondée à peine d’irrecevabilité. En l’espèce, l’erreur commise lors de la saisie de la plaque d’immatriculation étant sans rapport avec l’avis de paiement contesté, la demande est infondée et le recours doit être rejeté.',
      'FALSE_ARGUMENT': 'Arguments relevant de la pure querelle : Aux termes de l’article R.2333-120-13 2° du Code général des collectivités territoriales le recours administratif préalable obligatoire prévu au VI de l’article L.2333-87 du même code doit être assorti de l’exposé des faits et moyens sur lesquels la demande est fondée à peine d’irrecevabilité. En l’espèce, seuls des arguments relevant de la pure querelle ayant été exposés, la demande est infondée et le recours doit être rejeté.	Exemple : les moyens du recours sont « je n’avais dépassé que de 5 mn … » « encore un moyen de récupérer de l’argent du contribuable … » etc….',

      'EVIDENCE_PRESENT': 'Absence d’éléments de preuve : Aux termes de l’article L.2333-87 du Code général des collectivités territoriales les mentions portées sur l’avis de paiement du FPS par l’agent assermenté font foi jusqu’à preuve du contraire. Par conséquent, il appartient au destinataire de l’avis de paiement objet du recours d’apporter la preuve des faits qu’il expose. En l’espèce, l’examen approfondi de la demande n’a pas permis de démontrer que le/les argument(s) invoqué(s) étai(en)t sérieux en l’absence de production d’éléments de preuve. Ainsi, la demande étant irrecevable, le recours doit être rejeté.	Exemple : « le ticket était encore valide et apposé sur le pare-brise » (pas de photo de contexte)',
      'TICKET_MISSING': 'Cas du ticket absent (la personne soutient que son ticket était présent) : Aux termes de l’article L2333-87 du Code général des collectivités territoriales les mentions portées sur l’avis de paiement du FPS par l’agent assermenté font foi jusqu’à preuve du contraire. Par conséquent, il appartient au destinataire de l’avis de paiement objet du recours d’apporter la preuve des faits qu’il expose. En l’espèce, aucun élément de preuve ne permettant d’attester que le ticket était placé derrière le pare-brise du véhicule, la demande est irrecevable et le recours doit être rejeté.',
      'PMR_MISSING': 'Cas du badge PMR (la personne soutient qu’elle avait apposé un badge sur son pare-brise) : Aux termes de l’article L2333-87 du Code général des collectivités territoriales les mentions portées sur l’avis de paiement du FPS par l’agent assermenté font foi jusqu’à preuve du contraire. Par conséquent, il appartient au destinataire de l’avis de paiement objet du recours d’apporter la preuve des faits qu’il expose. En l’espèce, aucun élément de preuve ne permettant d’attester que la carte de stationnement pour personne handicapée/carte mobilité inclusion était placée derrière le pare-brise du véhicule, la demande est irrecevable et le recours doit être rejeté.',
      'OTHER_MISSING': 'Cas des autres badges permettant de bénéficier d’une gratuité permanente : Aux termes de l’article L2333-87 du Code général des collectivités territoriales les mentions portées sur l’avis de paiement du FPS par l’agent assermenté font foi jusqu’à preuve du contraire. Par conséquent, il appartient au destinataire de l’avis de paiement objet du recours d’apporter la preuve des faits qu’il expose. En l’espèce, aucun élément de preuve ne permettant d’attester que le badge permettant de bénéficier d’une gratuité du stationnement était placé derrière le pare-brise du véhicule, la demande est irrecevable et le recours doit être rejeté.',
      'FALSE_PARKING_METER_FAILURE': 'Cas des pannes d’horodateurs (la personne soutient que l’horodateur le plus proche était en panne) : Il ressort de l’instruction de votre recours que l’horodateur situé *position horodateur en panne* présentait une panne lors de votre stationnement. Cependant, en cas de dysfonctionnement il est précisé sur l’horodateur que l’utilisateur doit utiliser l’appareil le plus proche afin de régler sa redevance. Or, l’instruction n’a pas permis d’établir que l’intégralité des horodateurs dans le périmètre du véhicule dysfonctionnait. Ainsi, la demande n’étant pas fondée, le recours être rejeté.',
      'MARKINGS_MISSING': 'Cas de l’absence de signalisation : Aux termes du Code de la route et de la loi n° 2014-58 du 27 janvier 2014 les emplacements soumis au paiement de la redevance de stationnement doivent faire l’objet d’une signalisation horizontale ou verticale. Par ailleurs, il ressort de l’instruction de votre recours que les conditions de signalisation au lieu d’établissement du FPS étaient conformes aux règles de matérialisation. Ainsi, la demande n’étant pas fondée, le recours doit être rejeté.',
      'ELEMENT_AGAINST_PAYEMENT': 'Eléments de preuve ne permettant pas d’établir l’illégalité de l’avis de paiement : Aux termes de l’article L2333-87 du Code général des collectivités territoriales les mentions portées sur l’avis de paiement du FPS par l’agent assermenté font foi jusqu’à preuve du contraire. Par conséquent, il appartient au destinataire de l’avis de paiement objet du recours d’apporter la preuve des faits qu’il expose. En l’espèce, les moyens de preuve produits étant insuffisants et ne permettant pas de démontrer que le/les argument(s) invoqué(s) dans la demande étai(en)t sérieux, celle-ci est irrecevable et le recours doit être rejeté.	Exemple : « la période de stationnement bénéficiait d’une gratuité temporaire » (non recensé dans notre base de données et absence de justificatifs permettant d’en apporter la preuve). ',
      'WRONG_FPS': 'Rédaction du FPS : L’article R. 2333-120-4 du Code général des collectivités territoriales fixe la liste des mentions devant figurer sur l’avis de paiement du forfait post-stationnement. En l’espèce, il ressort de l’instruction du recours que l’intégralité des mentions obligatoires figurent sur l’avis de paiement émis par l’agent assermenté. Par conséquent, la demande n’étant pas fondée, le recours doit être rejeté. Exemple : « il manque une mention obligatoire sur le FPS ».',
      'ACCEPTABLE_TICKET': 'Demande de recalcul du montant du FPS : Aux termes de l’article R. 2333-120-5 du Code général des collectivités territoriales le montant de la redevance réglée dès le début du stationnement doit être déduite du montant du FPS. Or, si plusieurs justificatifs sont apposés dans le véhicule, seul le dernier en date de ces justificatifs de paiement est pris en compte pour opérer la déduction prévue. En l’espèce, conformément à la loi, seul le dernier ticket valide a été pris en compte pour calculer le montant de la déduction lors de l’établissement de l’avis de paiement. Le recours tendant à la modification du montant du FPS doit être rejeté.	Exemple : la personne demande la déduction de tous les tickets qu’elle avait pris. '
    },

    // Remplissage du champ "Décision de l'instruction" dans le cas d'un refus de RAPO
    decisionInstructionRejection: {
      'INVALID_TICKET': 'Un ticket valide est présent, la date de début et la date de fin sont dans la tranche horaire de création du FPS',

      'DEADLINE_RESPECTED': 'Aux termes de l’article R.2333-120-13 du Code général des collectivités territoriales le recours administratif préalable obligatoire prévu au VI de l’article L. 2333-87 du même code doit être exercé dans le délai d’un mois à compter de la date de notification de l’avis de paiement du FPS. En l’espèce, le recours ayant été introduit en dehors du délai légalement prévu, la demande est manifestement irrecevable et le recours doit être rejeté.',
      'REQUEST_CHANNEL_RESPECTED': 'Aux termes de l’article R.2333-120-13 1° du Code général des collectivités territoriales le recours administratif préalable obligatoire prévu au VI de l’article L.2333-87 du même code doit être présenté par lettre recommandée avec demande d’avis de réception ou, le cas échéant, par l’intermédiaire du procédé électronique mentionné dans l’avis de paiement à peine d’irrecevabilité. En l’espèce, le recours n’ayant pas été exercé conformément aux exigences prévues par la loi, la demande est manifestement irrecevable et le recours doit être rejeté.',
      'REGISTRATION_CERTIFICATE_PRESENT': 'Aux termes de l’article R.2333-120-13 3° du Code général des collectivités territoriales le recours administratif préalable obligatoire prévu au VI de l’article L.2333-87 du même code doit être accompagné d’une copie du certificat d’immatriculation du véhicule concerné. En l’espèce, le recours n’étant pas accompagné de l’intégralité des pièces exigées, la demande est manifestement irrecevable et le recours doit être rejeté.',
      'FPS_COPY_PRESENT': 'Aux termes de l’article R.2333-120-13 3° du Code général des collectivités territoriales le recours administratif préalable obligatoire prévu au VI de l’article L.2333-87 du même code doit être accompagné d’une copie de l’avis de paiement contesté. En l’espèce, le recours n’étant pas accompagné de cette pièce obligatoire, la demande est manifestement irrecevable et le recours doit être rejeté.',
      'MOTIVE_RAPO': 'Aux termes de l’article R.2333-120-13 2° du Code général des collectivités territoriales le recours administratif préalable obligatoire prévu au VI de l’article L.2333-87 du même code doit être assorti de l’exposé des faits et moyens sur lesquels la demande est fondée à peine d’irrecevabilité. En l’espèce, la demande formulée ne comportant aucun argument, celle-ci est infondée et le recours doit être rejeté.',
      'VEHICLE_CESSION': 'Aux termes de l’article R.2333-120-13 3° du Code général des collectivités territoriales le recours administratif préalable obligatoire prévu au VI de l’article L.2333-87 du même code doit être accompagné d’une copie du certificat d’immatriculation du véhicule concerné ou de la déclaration de cession du véhicule et de son accusé d’enregistrement dans le système d’immatriculation des véhicule et, le cas échéant, des pièces permettant d’apprécier le bien-fondé de la demande à peine d’irrecevabilité. En l’espèce, le recours n’étant pas accompagné de l’intégralité des pièces exigées, la demande est manifestement irrecevable et le recours doit être rejeté.',
      'MANDATARY': 'Aux termes de l’article R.2333-120-13 du Code général des collectivités territoriales le titulaire du certificat d’immatriculation, le locataire ou l’acquéreur du véhicule ayant fait l’objet d’un forfait post-stationnement peut habiliter toute personne pour former en son nom et pour son compte le recours administratif préalable obligatoire prévu au VI de l’article L.2333-87 du même code, le mandat devant être produit avec le recours. En l’espèce, au regard de l’absence de production du mandat par le titulaire du certificat d’immatriculation du véhicule, la demande est manifestement irrecevable et le recours doit être rejeté.',

      'COURTESY': 'Aux termes de l’article R.2333-120-13 2° du Code général des collectivités territoriales le recours administratif préalable obligatoire prévu au VI de l’article L.2333-87 du même code doit être assorti de l’exposé des faits et moyens sur lesquels la demande est fondée à peine d’irrecevabilité. En l’espèce, seuls des arguments injurieux ayant été exposés, la demande est infondée et le recours doit être rejeté.',
      'ARGUMENT_FOR_FPS': 'Aux termes de l’article R.2333-120-13 2° du Code général des collectivités territoriales le recours administratif préalable obligatoire prévu au VI de l’article L.2333-87 du même code doit être assorti de l’exposé des faits et moyens sur lesquels la demande est fondée à peine d’irrecevabilité. En l’espèce, seuls des arguments sans rapport avec l’avis de paiement contesté ayant été exposés, la demande est infondée et le recours doit être rejeté.',
      'SAME_PLATE_FPS': 'Aux termes de l’article R.2333-120-13 2° du Code général des collectivités territoriales le recours administratif préalable obligatoire prévu au VI de l’article L.2333-87 du même code doit être assorti de l’exposé des faits et moyens sur lesquels la demande est fondée à peine d’irrecevabilité. En l’espèce, l’erreur commise lors de la saisie de la plaque d’immatriculation étant sans rapport avec l’avis de paiement contesté, la demande est infondée et le recours doit être rejeté.',
      'FALSE_ARGUMENT': 'Aux termes de l’article R.2333-120-13 2° du Code général des collectivités territoriales le recours administratif préalable obligatoire prévu au VI de l’article L.2333-87 du même code doit être assorti de l’exposé des faits et moyens sur lesquels la demande est fondée à peine d’irrecevabilité. En l’espèce, seuls des arguments relevant de la pure querelle ayant été exposés, la demande est infondée et le recours doit être rejeté.',

      'EVIDENCE_PRESENT': 'Aux termes de l’article L.2333-87 du Code général des collectivités territoriales les mentions portées sur l’avis de paiement du FPS par l’agent assermenté font foi jusqu’à preuve du contraire. Par conséquent, il appartient au destinataire de l’avis de paiement objet du recours d’apporter la preuve des faits qu’il expose. En l’espèce, l’examen approfondi de la demande n’a pas permis de démontrer que le/les argument(s) invoqué(s) étai(en)t sérieux en l’absence de production d’éléments de preuve. Ainsi, la demande étant irrecevable, le recours doit être rejeté.',
      'TICKET_MISSING': 'Aux termes de l’article L2333-87 du Code général des collectivités territoriales les mentions portées sur l’avis de paiement du FPS par l’agent assermenté font foi jusqu’à preuve du contraire. Par conséquent, il appartient au destinataire de l’avis de paiement objet du recours d’apporter la preuve des faits qu’il expose. En l’espèce, aucun élément de preuve ne permettant d’attester que le ticket était placé derrière le pare-brise du véhicule, la demande est irrecevable et le recours doit être rejeté.',
      'PMR_MISSING': 'Aux termes de l’article L2333-87 du Code général des collectivités territoriales les mentions portées sur l’avis de paiement du FPS par l’agent assermenté font foi jusqu’à preuve du contraire. Par conséquent, il appartient au destinataire de l’avis de paiement objet du recours d’apporter la preuve des faits qu’il expose. En l’espèce, aucun élément de preuve ne permettant d’attester que la carte de stationnement pour personne handicapée/carte mobilité inclusion était placée derrière le pare-brise du véhicule, la demande est irrecevable et le recours doit être rejeté.',
      'OTHER_MISSING': 'Aux termes de l’article L2333-87 du Code général des collectivités territoriales les mentions portées sur l’avis de paiement du FPS par l’agent assermenté font foi jusqu’à preuve du contraire. Par conséquent, il appartient au destinataire de l’avis de paiement objet du recours d’apporter la preuve des faits qu’il expose. En l’espèce, aucun élément de preuve ne permettant d’attester que le badge permettant de bénéficier d’une gratuité du stationnement était placé derrière le pare-brise du véhicule, la demande est irrecevable et le recours doit être rejeté.',
      'FALSE_PARKING_METER_FAILURE': 'Il ressort de l’instruction de votre recours que l’horodateur situé *position horodateur en panne* présentait une panne lors de votre stationnement. Cependant, en cas de dysfonctionnement il est précisé sur l’horodateur que l’utilisateur doit utiliser l’appareil le plus proche afin de régler sa redevance. Or, l’instruction n’a pas permis d’établir que l’intégralité des horodateurs dans le périmètre du véhicule dysfonctionnait. Ainsi, la demande n’étant pas fondée, le recours être rejeté.',
      'MARKINGS_MISSING': 'Aux termes du Code de la route et de la loi n° 2014-58 du 27 janvier 2014 les emplacements soumis au paiement de la redevance de stationnement doivent faire l’objet d’une signalisation horizontale ou verticale. Par ailleurs, il ressort de l’instruction de votre recours que les conditions de signalisation au lieu d’établissement du FPS étaient conformes aux règles de matérialisation. Ainsi, la demande n’étant pas fondée, le recours doit être rejeté.',
      'ELEMENT_AGAINST_PAYEMENT': 'Aux termes de l’article L2333-87 du Code général des collectivités territoriales les mentions portées sur l’avis de paiement du FPS par l’agent assermenté font foi jusqu’à preuve du contraire. Par conséquent, il appartient au destinataire de l’avis de paiement objet du recours d’apporter la preuve des faits qu’il expose. En l’espèce, les moyens de preuve produits étant insuffisants et ne permettant pas de démontrer que le/les argument(s) invoqué(s) dans la demande étai(en)t sérieux, celle-ci est irrecevable et le recours doit être rejeté.',
      'WRONG_FPS': 'L’article R. 2333-120-4 du Code général des collectivités territoriales fixe la liste des mentions devant figurer sur l’avis de paiement du forfait post-stationnement. En l’espèce, il ressort de l’instruction du recours que l’intégralité des mentions obligatoires figurent sur l’avis de paiement émis par l’agent assermenté. Par conséquent, la demande n’étant pas fondée, le recours doit être rejeté.',
      'ACCEPTABLE_TICKET': 'Aux termes de l’article R. 2333-120-5 du Code général des collectivités territoriales le montant de la redevance réglée dès le début du stationnement doit être déduite du montant du FPS. Or, si plusieurs justificatifs sont apposés dans le véhicule, seul le dernier en date de ces justificatifs de paiement est pris en compte pour opérer la déduction prévue. En l’espèce, conformément à la loi, seul le dernier ticket valide a été pris en compte pour calculer le montant de la déduction lors de l’établissement de l’avis de paiement. Le recours tendant à la modification du montant du FPS doit être rejeté.'
    },

    // Valeur qui sera donnée au champ "decisionReason" dans le cas d'un rejet de RAPO
    decisionReasonRejection: {
      'INVALID_TICKET': 'Un ticket valide est présent, la date de début et la date de fin sont dans la tranche horaire de création du FPS',

      'DEADLINE_RESPECTED': 'Requête introduite hors-délai',
      'REQUEST_CHANNEL_RESPECTED': 'Envoi du RAPO en lettre simple',
      'REGISTRATION_CERTIFICATE_PRESENT': 'Absence du certificat d’immatriculation',
      'FPS_COPY_PRESENT': 'Absence de la copie du FPS',
      'MOTIVE_RAPO': 'Absence de motivation du recours',
      'VEHICLE_CESSION': 'Absence de pièces justificatives obligatoires dans le cas d’une vente',
      'MANDATARY': 'Incompétence de l’auteur du recours (absence de mandat)',

      'COURTESY': 'Arguments injurieux',
      'ARGUMENT_FOR_FPS': 'Arguments sans rapport avec le FPS contesté',
      'SAME_PLATE_FPS': 'Cas de l’erreur de saisie de la plaque d’immatriculation',
      'FALSE_ARGUMENT': 'Arguments relevant de la pure querelle',

      'EVIDENCE_PRESENT': 'Absence d’éléments de preuve',
      'TICKET_MISSING': 'Cas du ticket absent (la personne soutient que son ticket était présent)',
      'PMR_MISSING': 'Cas du badge PMR (la personne soutient qu’elle avait apposé un badge sur son pare-brise)',
      'OTHER_MISSING': 'Cas des autres badges permettant de bénéficier d’une gratuité permanente',
      'FALSE_PARKING_METER_FAILURE': 'Cas des pannes d’horodateurs (la personne soutient que l’horodateur le plus proche était en panne)',
      'MARKINGS_MISSING': 'Cas de l’absence de signalisation',
      'ELEMENT_AGAINST_PAYEMENT': 'Eléments de preuve ne permettant pas d’établir l’illégalité de l’avis de paiement',
      'WRONG_FPS': 'Rédaction du FPS',
      'ACCEPTABLE_TICKET': 'Demande de recalcul du montant du FPS'
    },

    decisionInstructionAcceptance: 'Au vu des arguments et des documents transmis à l’appui du recours, il ressort de l’instruction de votre recours que le forfait de post-stationnement objet de la demande doit être annulé.',
    decisionReasonAcceptance: 'Acceptation du RAPO',

    // Remplissage du champ "Décision de l'instruction" dans le cas d'un FPS rectificatif
    decisionInstructionCorrection: {
      'COURTESY': 'Aux termes de l’article R.2333-120-13 2° du Code général des collectivités territoriales le recours administratif préalable obligatoire doit être assorti de l’exposé des faits et moyens sur lesquels la demande est fondée à peine d’irrecevabilité. En l’espèce, la demande en annulation du FPS n’étant pas fondée, elle ne peut être que rejetée. En revanche, il ressort de l’instruction de votre recours que la déduction à appliquer lors de l’établissement du FPS était de ${ticketValue}. Ainsi, le montant du FPS a été recalculé et un avis de paiement rectificatif vous est transmis ce-jour.',
      'ARGUMENT_FOR_FPS': 'Aux termes de l’article R.2333-120-13 2° du Code général des collectivités territoriales le recours administratif préalable obligatoire doit être assorti de l’exposé des faits et moyens sur lesquels la demande est fondée à peine d’irrecevabilité. En l’espèce, la demande en annulation du FPS n’étant pas fondée, elle ne peut être que rejetée. En revanche, il ressort de l’instruction de votre recours que la déduction à appliquer lors de l’établissement du FPS était de ${ticketValue}. Ainsi, le montant du FPS a été recalculé et un avis de paiement rectificatif vous est transmis ce-jour.',
      'SAME_PLATE_FPS': 'Aux termes de l’article R.2333-120-13 2° du Code général des collectivités territoriales le recours administratif préalable obligatoire doit être assorti de l’exposé des faits et moyens sur lesquels la demande est fondée à peine d’irrecevabilité. En l’espèce, la demande en annulation du FPS n’étant pas fondée, elle ne peut être que rejetée. En revanche, il ressort de l’instruction de votre recours que la déduction à appliquer lors de l’établissement du FPS était de ${ticketValue}. Ainsi, le montant du FPS a été recalculé et un avis de paiement rectificatif vous est transmis ce-jour.',
      'FALSE_ARGUMENT': 'Aux termes de l’article R.2333-120-13 2° du Code général des collectivités territoriales le recours administratif préalable obligatoire doit être assorti de l’exposé des faits et moyens sur lesquels la demande est fondée à peine d’irrecevabilité. En l’espèce, la demande en annulation du FPS n’étant pas fondée, elle ne peut être que rejetée. En revanche, il ressort de l’instruction de votre recours que la déduction à appliquer lors de l’établissement du FPS était de ${ticketValue}. Ainsi, le montant du FPS a été recalculé et un avis de paiement rectificatif vous est transmis ce-jour.',

      'EVIDENCE_PRESENT': 'Aux termes de l’article L2333-87 du Code général des collectivités territoriales les mentions portées sur l’avis de paiement du FPS par l’agent assermenté font foi jusqu’à preuve du contraire ; il appartient alors au demandeur d’apporter la preuve des faits qu’il expose. En l’espèce, les moyens de preuve produits ne permettant pas de démontrer que le/les argument(s) invoqué(s) dans la demande étai(en)t sérieux, celle-ci ne peut être que rejetée. En revanche, il ressort de l’instruction de votre recours que la déduction à appliquer lors de l’établissement du FPS était de ${ticketValue}. Ainsi, le montant du FPS a été recalculé et un avis de paiement rectificatif vous est transmis ce-jour.',
      'TICKET_MISSING': 'Aux termes de l’article L2333-87 du Code général des collectivités territoriales les mentions portées sur l’avis de paiement du FPS par l’agent assermenté font foi jusqu’à preuve du contraire ; il appartient alors au demandeur d’apporter la preuve des faits qu’il expose. En l’espèce, les moyens de preuve produits ne permettant pas de démontrer que le/les argument(s) invoqué(s) dans la demande étai(en)t sérieux, celle-ci ne peut être que rejetée. En revanche, il ressort de l’instruction de votre recours que la déduction à appliquer lors de l’établissement du FPS était de ${ticketValue}. Ainsi, le montant du FPS a été recalculé et un avis de paiement rectificatif vous est transmis ce-jour.',
      'PMR_MISSING': 'Aux termes de l’article L2333-87 du Code général des collectivités territoriales les mentions portées sur l’avis de paiement du FPS par l’agent assermenté font foi jusqu’à preuve du contraire ; il appartient alors au demandeur d’apporter la preuve des faits qu’il expose. En l’espèce, les moyens de preuve produits ne permettant pas de démontrer que le/les argument(s) invoqué(s) dans la demande étai(en)t sérieux, celle-ci ne peut être que rejetée. En revanche, il ressort de l’instruction de votre recours que la déduction à appliquer lors de l’établissement du FPS était de ${ticketValue}. Ainsi, le montant du FPS a été recalculé et un avis de paiement rectificatif vous est transmis ce-jour.',
      'OTHER_MISSING': 'Aux termes de l’article L2333-87 du Code général des collectivités territoriales les mentions portées sur l’avis de paiement du FPS par l’agent assermenté font foi jusqu’à preuve du contraire ; il appartient alors au demandeur d’apporter la preuve des faits qu’il expose. En l’espèce, les moyens de preuve produits ne permettant pas de démontrer que le/les argument(s) invoqué(s) dans la demande étai(en)t sérieux, celle-ci ne peut être que rejetée. En revanche, il ressort de l’instruction de votre recours que la déduction à appliquer lors de l’établissement du FPS était de ${ticketValue}. Ainsi, le montant du FPS a été recalculé et un avis de paiement rectificatif vous est transmis ce-jour.',
      'FALSE_PARKING_METER_FAILURE': 'Aux termes de l’article L2333-87 du Code général des collectivités territoriales les mentions portées sur l’avis de paiement du FPS par l’agent assermenté font foi jusqu’à preuve du contraire ; il appartient alors au demandeur d’apporter la preuve des faits qu’il expose. En l’espèce, les moyens de preuve produits ne permettant pas de démontrer que le/les argument(s) invoqué(s) dans la demande étai(en)t sérieux, celle-ci ne peut être que rejetée. En revanche, il ressort de l’instruction de votre recours que la déduction à appliquer lors de l’établissement du FPS était de ${ticketValue}. Ainsi, le montant du FPS a été recalculé et un avis de paiement rectificatif vous est transmis ce-jour.',
      'MARKINGS_MISSING': 'Aux termes de l’article L2333-87 du Code général des collectivités territoriales les mentions portées sur l’avis de paiement du FPS par l’agent assermenté font foi jusqu’à preuve du contraire ; il appartient alors au demandeur d’apporter la preuve des faits qu’il expose. En l’espèce, les moyens de preuve produits ne permettant pas de démontrer que le/les argument(s) invoqué(s) dans la demande étai(en)t sérieux, celle-ci ne peut être que rejetée. En revanche, il ressort de l’instruction de votre recours que la déduction à appliquer lors de l’établissement du FPS était de ${ticketValue}. Ainsi, le montant du FPS a été recalculé et un avis de paiement rectificatif vous est transmis ce-jour.',
      'ELEMENT_AGAINST_PAYEMENT': 'Aux termes de l’article L2333-87 du Code général des collectivités territoriales les mentions portées sur l’avis de paiement du FPS par l’agent assermenté font foi jusqu’à preuve du contraire ; il appartient alors au demandeur d’apporter la preuve des faits qu’il expose. En l’espèce, les moyens de preuve produits ne permettant pas de démontrer que le/les argument(s) invoqué(s) dans la demande étai(en)t sérieux, celle-ci ne peut être que rejetée. En revanche, il ressort de l’instruction de votre recours que la déduction à appliquer lors de l’établissement du FPS était de ${ticketValue}. Ainsi, le montant du FPS a été recalculé et un avis de paiement rectificatif vous est transmis ce-jour.',
      'WRONG_FPS': 'Aux termes de l’article L2333-87 du Code général des collectivités territoriales les mentions portées sur l’avis de paiement du FPS par l’agent assermenté font foi jusqu’à preuve du contraire ; il appartient alors au demandeur d’apporter la preuve des faits qu’il expose. En l’espèce, les moyens de preuve produits ne permettant pas de démontrer que le/les argument(s) invoqué(s) dans la demande étai(en)t sérieux, celle-ci ne peut être que rejetée. En revanche, il ressort de l’instruction de votre recours que la déduction à appliquer lors de l’établissement du FPS était de ${ticketValue}. Ainsi, le montant du FPS a été recalculé et un avis de paiement rectificatif vous est transmis ce-jour.',

      'ACCEPTABLE_TICKET': 'Aux termes de l’article R. 2333-120-5 du Code général des collectivités territoriales le montant de la redevance réglée dès le début du stationnement doit être déduite du montant du forfait post-stationnement. Il ressort de l’instruction de votre recours que la déduction à appliquer lors de l’établissement du FPS était de ${ticketValue}. Ainsi, le montant du FPS a été recalculé et un avis de paiement rectificatif vous est transmis ce-jour.'
    },

    // Origine du RAPO
    rapoSourceFormat: {
      'PAPER': 'Papier',
      'ELECTRONIC': 'Electronique'
    },

    claimerQuality: {
      'OWNER': 'Propriétaire',
      'SELLER': 'Vendeur ou acquéreur',
      'TENANT': 'Locataire',
      'OTHER': 'Autre'
    },

    events: {
      // Tournées agent
      'GUIDED_WALKING': 'Guidée épi/bataille & 2RM',
      'GUIDED_FIXED': 'Guidée fixe',
      'GUIDED_DYNAMIC': 'Guidée dynamique',
      'PMR': 'Guidée PMR',
      'UNGUIDED': 'Non-guidée',

      // Tournées LAPI
      'GUIDED_LAPI': 'LAPI guidée',
      'UNGUIDED_LAPI': 'LAPI non-guidée',

      // Impact le teemps réel de travail
      'TRAINING': 'Formation',
      'GUIDE': 'Accompagnateur',
      'WORK_ACCIDENT': 'Accident du travail',
      'JUSTIFIED_ABSENCE': 'Absence justifiée',
      'PAID_VACATION': 'Congés payés',
      'INITIATIVE_MEETING': 'Réunion initiative employeur',
      'IRP_MEETING': 'Réunion IRP',
      'COMPENSATORY_REST': 'Repos compensateur',
      'RECOVERY_REST': 'Repos récupérateur',
      'SPLITTING_DAYS': 'Jour de fractionnement',
      'SICK_LEAVE': 'Maladie',
      'BREAK': 'Pause',

      // N'impacte pas le temps de travail
      'UNPAID_VACATION': 'Congés sans solde',
      'UNJUSTIFIED_ABSENCE': 'Absence injustifiée',
      'VARIOUS_ABSENCE': 'Absence diverse'
    },
    carType: {
      'CAR': 'Voiture',
      'SCOOTER': 'Scooter'
    },
    carStatus: {
      'WORKING': 'En service',
      'REPAIRING': 'En réparation'
    },

    rapoEvent: {
      'CREATION': 'Saisie du RAPO',
      'START_INSTRUCTION': 'Instruction débutée',
      'STOP_INSTRUCTION': 'Instruction stoppée',
      'FINISH_INSTRUCTION': 'Instruction finie',
      'REFUSE_INSTRUCTION': 'Instruction refusée',
      'VALIDATE_INSTRUCTION': 'Instruction validée',
      'SEND_COMPLETION': 'Demande de complétude envoyée',
      'COMPLETION': 'Completion',
      'SEND_RESPONSE': 'Réponse envoyée',
      'SEND_EMAIL_ERROR': 'Erreur d\'envoi d\'e-mail',
      'GENERATE_CCSP': 'Génération du mémoire en défense'
    },

    responseFormats: {
      'PAPER_MANUAL': 'Réponse par courrier manuel',
      'PAPER_J2': 'Réponse par courrier J+2',
      'PAPER_J4': 'Réponse par courrier J+4',
      'ELECTRONIC': 'Réponse par mail'
    },

    ccspClaimAttachmentTypes: {
      'DEFENSE_MEMORY': 'Mémoire en défense',
      'DECISION': 'Décision',
      'ANNEX_DOCUMENT': 'Document annexe'
    },

    ccspClaimEvents: {
      'CREATION': 'Création',
      'FILE_UPLOAD': 'Chargement du fichier',
      'FILE_DOWNLOAD': 'Téléchargement du fichier',
      'FILE_DELETION': 'Suppression du fichier',
      'UPDATE_TAG': 'Mise à jour du motif',
      'COMMENT': 'Ajout d\'un commentaire',
      'DECISION_SAVE': 'Enregistrement de la décision',
      'SUBMISSION': 'Soumission'
    },

    ccspClaimStatus: {
      'CREATED': 'Créé',
      'SUBMITTED': 'Soumis',
      'CLOSED': 'Clos'
    },

    ccspClaimDecision: {
      'TOTAL_CANCELLATION': 'Annulation Totale',
      'REJECT': 'Rejeté',
      'PARTIAL_CANCELLATION_INCREASE': 'Majoration Annulée',
      'PARTIAL_CANCELLATION': 'Annulation Partielle'
    },

    ccspClaimTags: {
      'FPS_WAS_NOT_AT_THE_VEHICLE_CONTROL_SITE': 'FPS - Ne se trouvait pas sur les lieux du contrôle du véhicule',
      'FPS_VEHICLE_PLATE_CLONING': 'FPS - Usurpation de plaque',
      'FPS_USER_HOLDING_A_PMR_TITLE': 'FPS - usager titulaire d’un titre PMR',
      'FPS_DENUNCIATION_TRANSFERRED_OR_SOLD': 'FPS - Dénonciation - Véhicule cédé ou vendu',
      'FPS_DENUNCIATION_VEHICLE_LOANED_OR_LEASED': 'FPS - Dénonciation - Véhicule prêté ou loué',
      'FPS_BENEFICIARY_OF_A_TEMPORARY_OR_PERMANENT_GRATUITY': 'FPS - Bénéficiaire d’une gratuité temporaire ou permanente',
      'FPS_VEHICLE_LOCATION_ERROR': 'FPS - Erreur de localisation du véhicule',
      'FPS_VALID_TICKET_OR_SUBSCRIPTION': 'FPS - Ticket valide / abonnement valide',
      'FPS_PLATE_ENTRY_ERROR': 'FPS - erreur de saisie de plaque',
      'FPS_STOLEN_OR_DESTROYED_VEHICLE': 'FPS - Véhicule volé ou détruit',
      'FPS_VALID_PREVIOUS_FPS': 'FPS - FPS précédent valide',
      'FPS_WRONG_FPS_AMOUNT': 'FPS - Montant du FPS erroné ',
      'FPS_NO_INSUFFICIENT_PAY_AREA_SIGNAGE': 'FPS - Absence/insuffisance de signalisation en zone payante',
      'FPS_TIMESTAMP_DOWN': 'FPS - Horodateur en panne',
      'FPS_AUTHORIZED_VEHICLE': 'FPS - Véhicule autorisé',
      'FPS_FPS_INCOMPLETE_POORLY_WRITTEN': 'FPS - FPS incomplet/mal rédigé',
      'FPS_REQUEST_FOR_INDULGENCE': 'FPS - Demande d’indulgence',
      'TE_INITIAL_FPS_NOT_RECEIVED': 'TE - FPS initial non reçu',
      'TE_FPS_INCOMPLETE_POORLY_WRITTEN': 'TE - FPS initial incomplet/mal rédigé',
      'TE_PAYMENT_OF_THE_INITIAL_FPS_WITHIN_THE_STATUTORY_DEADLINE': 'TE - Paiement du FPS initial dans le délai légal',
      'TE_STOLEN_OR_DESTROYED_VEHICLE': 'TE - Véhicule volé ou détruit',
      'TE_VEHICLE_PLATE_CLONING': 'TE - Usurpation de plaque',
      'TE_DENUNCIATION_TRANSFERRED_OR_SOLD_VEHICLE_': 'TE - Dénonciation - Véhicule cédé ou vendu',
      'TE_DENUNCIATION_VEHICLE_LOANED_OR_LEASED': 'TE - Dénonciation - Véhicule prêté ou loué',
      'TE_WAS_NOT_AT_THE_VEHICLE_CONTROL_SITE': 'TE - Ne se trouvait pas sur les lieux du contrôle du véhicule',
      'TE_USER_WITH_A_PMR_DESIGNATION': 'TE - Usager titulaire d’un titre PMR ',
      'TE_BENEFICIARY_OF_A_TEMPORARY_OR_PERMANENT_GRATUITY': 'TE - Bénéficiaire d’une gratuité temporaire ou permanente ',
      'TE_VALID_TICKET_OR_SUBSCRIPTION': 'TE - Ticket valide /abonnement valide',
      'TE_PLATE_ENTRY_ERROR': 'TE - erreur de saisie de la plaque',
      'TE_VALID_PREVIOUS_FPS': 'TE - FPS précédent valide',
      'TE_WRONG_FPS_AMOUNT': 'TE - Montant du FPS erroné ',
      'TE_NO_INSUFFICIENT_PAY_AREA_SIGNAGE': 'TE - Absence/insuffisance de signalisation en zone payante',
      'TE_TIMESTAMP_DOWN': 'TE - Horodateur en panne',
      'TE_AUTHORIZED_VEHICLE': 'TE - Véhicule autorisé',
      'TE_VEHICLE_LOCATION_ERROR': 'TE - Erreur de localisation du véhicule',
      'TE_REQUEST_FOR_INDULGENCE': 'TE - Demande d’indulgence',
      'OTHER': 'Autre'
    },

    streetNumberBis: {
      'B': 'Bis',
      'T': 'Ter',
      'Q': 'Quarter',
      'C': 'Quinquies',
      'F': 'En face',
      'A': 'A côté'
    },

    streetType: {
      'RUE': 'Rue',
      'AV': 'Avenue',
      'BD': 'Boulevard',
      'ALL': 'Allée',
      'CHEM': 'Chemin',
      'CTRE': 'Centre',
      'CCAL': 'Centre commercial',
      'HAM': 'Hameau',
      'IMM': 'Immeuble(s)',
      'IMP': 'Impasse',
      'LD': 'Lieu-dit',
      'LOT': 'Lotissement',
      'PAS': 'Passage',
      'PL': 'Place',
      'RES': 'Résidence',
      'RPT': 'Rond-point',
      'RTE': 'Route',
      'SENT': 'Sentier',
      'SQ': 'Square',
      'VLGE': 'Village',
      'ZA': 'Zone d\'activité',
      'ZAC': 'Zone d\'aménagement concerté',
      'ZAD': 'Zone d\'aménagement différé',
      'ZI': 'Zone industrielle',
      'QUAI': 'Quai',
      'QUAR': 'Quartier',
      'PARK': 'Parking',
      'TRAV': 'Traverse',
      'CAR': 'Carrefour',
      'COUR': 'Cours',
      'VOIE': 'Voie',
      'VSN': 'Voie sans nom',
      'COR': 'Corniche',
      'MONT': 'Montée'
    },

    tourType: {
      'FIXED_GUIDED': 'Guidée Fixe',
      'DYNAMIC_GUIDED': 'Guidée dynamique',
      'NOT_GUIDED': 'Tournée Libre'
    },

    tripType: {
      'A': 'Aller',
      'R': 'Retour',
      'DD': 'Départ-Départ',
      'AA': 'Arrivée-Arrivée',
      'DN': 'Départ-N\'importe où',
      'AN': 'Arrivée-N\'importe où'
    },

    userType: {
      'AGENT': 'Agent',
      'PMR_AGENT': 'PMR',
      'LAPI': 'Lapi'
    },

    tourStatus: {
      'READY': 'Pas encore démarrée',
      'START': 'Démarrée',
      'PAUSE': 'Suspendue',
      'STOP': 'Arrêtée',
      'FINISH': 'Terminée'
    },

    parity: {
      'EVEN': 'Pair',
      'ODD': 'Impair',
      'NONE': 'Non renseigné'
    },

    responseSendingType: {
      'MANUAL': 'Manuel',
      'DAY2': 'J+2',
      'DAY4': 'J+4'
    },

    selectionTypes: {
      'PMR': 'PMR',
      '2RM': 'MOTORBIKE',
      'VL (dont épi/bataille)': 'CAR',
      'Autocar': 'BUS',
      'Poids Lourd': 'TRUCK',
      'FPS': 'FPS',
      'NON FPS non technique': 'NFPS_NO_TECHNICAL',
      'NON FPS technique (image inexploitable)': 'NFPS_TECHNICAL_SCAN_INEX',
      'NON FPS technique (hors bande payante': 'NFPS_TECHNICAL_NOT_PAYING'
    },

    visitFrequencies: {
      'DAY': 'Jour',
      'WEEK': 'Semaine',
      'MONTH': 'Mois',
      'GLOBAL': 'Global'
    },

    ruleType: {
      'OBLIGATION': 'Obligation',
      'PROHIBITION': 'Interdiction'
    },

    dayOfWeek: {
      'MONDAY': 'Lundi',
      'TUESDAY': 'Mardi',
      'WEDNESDAY': 'Mercredi',
      'THURSDAY': 'Jeudi',
      'FRIDAY': 'Vendredi',
      'SATURDAY': 'Samedi',
      'SUNDAY': 'Dimanche'
    },

    httpError: {
      '422': 'Erreur systeme : Il existe déja un FPS portant ce numéro',
      '500': 'Erreur dans les serveurs interne'
    }
  };

  // tous les rôles SAGS (excluant donc les rôles techniques)
  dictionary.sagsUserRoles = _lodash.default.pick(dictionary.userRoles, ['AGENT', 'MANAGER', 'AGENT_BO', 'SUPERVISOR', 'CITY', 'RAPO_MANAGER', 'TERRITORY_MANAGER', 'ADMINISTRATOR', 'AGENT_VAO', 'VERIF_VAO', 'CITY_CCSP', 'CITY_VISU', 'TERRITORY_MANAGER_EQPT', 'SUPERVISOR_EQPT']);

  if (Ember.testing) {
    // Roles à tester : tous les rôles SAGS (y compris Chef d'équipe)
    // ce qui permet de voir si les champs qui apparaissent lors de l'édition d'un chef d'équipe sont les bons
    dictionary.createUserRoles = dictionary.sagsUserRoles;
  } else {
    // Roles à choisir lors de la création d'un utilisateur avec compte administrateur
    // (tous, sauf Chef d'équipe)
    dictionary.createUserRoles = _lodash.default.pick(dictionary.userRoles, ['AGENT', 'AGENT_BO', 'SUPERVISOR', 'CITY', 'RAPO_MANAGER', 'TERRITORY_MANAGER', 'ADMINISTRATOR', 'AGENT_VAO', 'VERIF_VAO', 'CITY_CCSP', 'CITY_VISU', 'TERRITORY_MANAGER_EQPT', 'SUPERVISOR_EQPT']);
  }

  // Roles à choisir lors de la création d'un utilisateur avec compte non administrateur (resp. territorial)
  // (tous, sauf Chef d'équipe et Administrateur)
  dictionary.createUserRolesNonAdmin = _lodash.default.pick(dictionary.userRoles, ['AGENT', 'AGENT_BO', 'SUPERVISOR', 'CITY', 'RAPO_MANAGER', 'TERRITORY_MANAGER', 'AGENT_VAO', 'VERIF_VAO', 'CITY_CCSP', 'TERRITORY_MANAGER_EQPT', 'SUPERVISOR_EQPT']);

  exports.default = dictionary;
});