define('sags-front-bo/constants/message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MESSAGES = {
    ERROR_COLLISION: 'L\'événement que vous essayez de modifier rentre en collision avec un de ses voisins. Merci de choisir une heure valide ou d\'annuler l\'action',
    ERROR_START_HOUR: 'L\'heure de début ne correspond pas à un créneau de 15 minutes ou est postérieure à l\'heure de fin',
    ERROR_END_HOUR: 'L\'heure de fin ne correspond pas à un créneau de 15 minutes ou est antérieure à l\'heure de début',
    ERROR_NO_TEAM: 'Vous n\'avez pas d\'équipes attribuées',
    ERROR_NO_FPS_VAO: 'Il n\'y a plus de pré-contrôles à traiter',
    ERROR_NO_VAO_FOR_YOU: 'Vous n\'êtes pas habilité pour le traitement VAO',
    ERROR_SERVER: 'Une erreur s\'est produite. Merci de réessayer plus tard.',
    ERROR_API_EXERNAL: 'Une erreur externe s\'est produite au niveau du serveur de FPS/tickets.',
    ERROR_BLACKLISTED_PRECONTROL: 'Code : %@',
    ERROR_NO_FREE_INDEX_NUMBER: 'Il n\'existe plus de numéro d\'établissement FPS disponible. Veuillez contacter votre responsable pour qu\'il recherche et réutilise/modifie une fiche utilisateur existante d\'un collaborateur ne faisant plus partie de SAGS.',
    ERROR_VAO_PRECONTROL_INVALID: 'Le système a corrigé le statut du précontrôle à afficher. Veuillez rafraichir la page.'
  };

  exports.default = Ember.assign({}, MESSAGES);
});