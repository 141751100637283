define('sags-front-bo/routes/administrator/external-server-error', ['exports', 'sags-front-bo/mixins/access-controller'], function (exports, _accessController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    setupController: function setupController(controller) {
      controller.set('serverSideFilters.creationDate.value', new Date());
      controller.get('refreshWithServerSideFilter')(controller, this.store);
    }
  });
});