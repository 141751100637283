define('sags-front-bo/controllers/administrator/tour-management', ['exports', 'moment', 'sags-front-bo/config/environment', 'lodash'], function (exports, _moment, _environment, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tours: [],
    toursIds: [],
    tourSelected: '',
    agents: [],
    selectedAgent: '',

    // Colonnes du tableau
    tableColumns: [{
      'key': 'id',
      'displayName': 'Id de la tournée'
    }, {
      'key': 'identification',
      'displayName': 'Matricule Agent'
    }, {
      'key': 'tourType',
      'displayName': 'Type'
    }, {
      'key': 'startDate',
      'displayName': 'Date de début',
      transform: function transform(date) {
        if (_lodash.default.isNil(date)) {
          return '...';
        }
        return (0, _moment.default)(date).format('DD/MM/YYYY HH:mm:ss');
      }
    }, {
      'key': 'endDate',
      'displayName': 'Date de fin',
      transform: function transform(date) {
        if (_lodash.default.isNil(date)) {
          return '...';
        }
        return (0, _moment.default)(date).format('DD/MM/YYYY HH:mm:ss');
      }
    }, {
      'key': 'sectorName',
      'displayName': 'Secteur'
    }],

    serverSideFilters: {
      date: {
        value: new Date()
      }
    },

    initRequest: function () {
      this.getAllToursReady(this);
    }.on('init'),

    // Recuperation des tournées disponibles
    getAllToursReady: function getAllToursReady() {
      var _this = this;

      return this.get('ajax').request(_environment.default.APP.host + '/gis/api/all-tours-ready', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          city: 'Paris',
          dateTime: this.serverSideFilters.date.value.toLocaleString()
        },
        contentType: 'application/json'
      }).then(function (data) {

        _this.set('tours', data);

        // Pas de données
        if (data === undefined) {
          _this.get('notifications').info('Aucune tournée disponible');
        } else {
          var tmpTourIdList = [];
          for (var i = 0; i < Object.keys(data).length; i++) {
            tmpTourIdList.push(data[i].id);
          }

          _this.set('toursIds', tmpTourIdList);
        }
      }).catch(function (reason) {
        _this.get('notifications').error('Erreur lors de la récupération des tournées: ' + reason);
      });
    },


    // Recuperation des agents disponibles pour reaffectation
    getAgentsAvailable: function getAgentsAvailable() {
      var _this2 = this;

      // Recuperer les infos necessaire selon le tour id choisi
      var sectorId = void 0,
          agentId = void 0;
      var tours = this.get('tours');

      tours.forEach(function (obj) {
        if (obj.id === _this2.get('tourSelected')) {
          sectorId = obj.sectorId;
          agentId = obj.agentId;
        }
      });

      return this.get('ajax').request(_environment.default.APP.host + '/gis/api/agents-for-tour', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          city: 'Paris',
          tourId: this.get('tourSelected'),
          sectorId: sectorId,
          agentId: agentId
        },
        contentType: 'application/json'
      }).then(function (data) {
        _this2.set('model', data);

        // Pas de données
        if (data === undefined) {
          _this2.get('notifications').info('Aucun agent disponible pour ce secteur');
        } else {
          // Recuperation des matricules agents
          var tmpAgentList = [];
          for (var i = 0; i < Object.keys(data).length; i++) {
            tmpAgentList.push(data[i].identification);
          }

          _this2.set('agents', tmpAgentList);
        }
      }).catch(function (reason) {
        _this2.get('notifications').error('Erreur lors de la récupération des agents: ' + reason);
      });
    },


    // Reaffectation de la tournee a un agent
    reaffectTourToAgent: function reaffectTourToAgent() {
      var _this3 = this;

      return this.get('ajax').request(_environment.default.APP.host + '/gis/api/reaffect-tour', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          city: 'Paris',
          tourId: this.get('tourSelected'),
          identification: this.get('selectedAgent')
        },
        contentType: 'application/json'
      }).then(function () {
        _this3.get('notifications').success('Tournée mise à jour');
        _this3.set('selectedAgent', '');
        _this3.set('tourSelected', '');
        _this3.getAllToursReady(_this3);
      }).catch(function () {
        _this3.get('notifications').error('Une erreur est survenue lors de la mise à jour de la tournée');
      });
    },


    actions: {
      setTour: function setTour(tour) {
        this.set('tourSelected', tour);
        this.getAgentsAvailable();
      },
      setAgent: function setAgent(agent) {
        this.set('selectedAgent', agent);
      },
      reaffectTour: function reaffectTour() {
        this.reaffectTourToAgent();
      }
    }
  });
});