define('sags-front-bo/models/rapo', ['exports', 'ember-data', 'lodash', 'sags-front-bo/utils/dictionary', 'ember-data-copyable'], function (exports, _emberData, _lodash, _dictionary, _emberDataCopyable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_emberDataCopyable.default, {
    isPmr: _emberData.default.attr('boolean'),
    number: _emberData.default.attr('string'),
    date: _emberData.default.attr('date'),
    motivations: _emberData.default.attr(),
    instructingDate: _emberData.default.attr('date'), // date d'instruction du RAPO
    validatingDate: _emberData.default.attr('date'), // date de validation du RAPO
    sendingDate: _emberData.default.attr('date'), // date d'envoi de la réponse
    entryDate: _emberData.default.attr('date'), // date de saisie du RAPO
    issue: _emberData.default.attr('string'), // Décision concernant le RAPO
    decisionArgument: _emberData.default.attr('string'), // Décision concernant le RAPO
    decisionReason: _emberData.default.attr('string'), // Motif de rejet du RAPO
    comment: _emberData.default.attr('string'),
    agentComment: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'), // énumération (RapoStatus)
    fps: _emberData.default.attr(),
    fpsLegalId: _emberData.default.attr('string'),
    fpsExternalId: _emberData.default.attr('string'),
    vehiclePlate: _emberData.default.attr('string'),
    vehicleBrand: _emberData.default.attr('string'),
    vehicleModel: _emberData.default.attr('string'),
    claimerCivility: _emberData.default.attr('string'), // civilité ('M.', 'Mme') Attention, pas de Melle svp.
    claimerFirstName: _emberData.default.attr('string'),
    claimerLastName: _emberData.default.attr('string'),
    claimerStreetNumber: _emberData.default.attr('string'),
    claimerStreetNumberBis: _emberData.default.attr('string'),
    claimerStreetType: _emberData.default.attr('string'),
    claimerStreetName: _emberData.default.attr('string'),
    claimerAdditionalAddress: _emberData.default.attr('string'),
    claimerZipCode: _emberData.default.attr('string'),
    claimerCity: _emberData.default.attr('string'),
    claimerCountry: _emberData.default.attr('string'),
    claimerQuality: _emberData.default.attr('string'), // qualité ('owner', 'seller', 'tenant' (locataire), 'other')
    claimerEmail: _emberData.default.attr('string'),
    mandataryCivility: _emberData.default.attr('string'),
    mandataryFirstName: _emberData.default.attr('string'),
    mandataryLastName: _emberData.default.attr('string'),
    mandataryStreetNumber: _emberData.default.attr('string'),
    mandataryStreetNumberBis: _emberData.default.attr('string'),
    mandataryStreetType: _emberData.default.attr('string'),
    mandataryStreetName: _emberData.default.attr('string'),
    mandataryAdditionalAddress: _emberData.default.attr('string'),
    mandataryZipCode: _emberData.default.attr('string'),
    mandataryCity: _emberData.default.attr('string'),
    mandataryCountry: _emberData.default.attr('string'),
    mandataryEmail: _emberData.default.attr('string'),
    lockedByAgent: _emberData.default.belongsTo('user'), // agent qui a réservé le RAPO
    validatedByAgent: _emberData.default.belongsTo('user'), // agent qui a validé le RAPO
    sentByAgent: _emberData.default.belongsTo('user'), // agent qui a envoyé la réponse et la date
    instructedByAgent: _emberData.default.belongsTo('user'), // agent qui a instruit le RAPO
    enteredByAgent: _emberData.default.belongsTo('user'), // agent qui a saisi le RAPO
    attachments: _emberData.default.attr('', { defaultValue: function defaultValue() {
        return [];
      } }),
    mandatoryMissingAttachments: _emberData.default.attr('', { defaultValue: function defaultValue() {
        return [];
      } }),
    missingAttachments: _emberData.default.attr('', { defaultValue: function defaultValue() {
        return [];
      } }),
    actionEvents: _emberData.default.hasMany('rapo-action-event'),
    sourceFormat: _emberData.default.attr('string'),
    responseFormat: _emberData.default.attr('string'),
    instructionResponses: _emberData.default.attr(),
    code: _emberData.default.attr('string'),
    fpsAgentAuthorizedNumber: _emberData.default.attr('string'), // agent qui a créé le FPS
    hasCcspClaim: _emberData.default.attr('boolean'),
    limitCompletionDate: _emberData.default.attr('date'),
    workflowProcessId: _emberData.default.attr('string'),
    newBuyer: _emberData.default.attr('', { defaultValue: function defaultValue() {
        return {
          civility: null,
          lastName: null,
          firstName: null,
          streetNumber: null,
          streetNumberBis: null,
          streetName: null,
          streetType: null,
          additionalAddress: null,
          zipCode: null,
          city: null,
          country: null,
          email: null
        };
      } }),
    correctiveFps: _emberData.default.attr('', { defaultValue: function defaultValue() {
        return {
          id: null,
          externalId: null,
          vehiclePlate: null,
          vehicleBrand: null,
          vehicleModel: null,
          creationDate: null,
          validityDate: null,
          reducedDate: null,
          amount: null,
          reducedAmount: null,
          status: null,
          legalId: null,
          significantParkingTickets: null,
          agent: {
            identification: null,
            name: null,
            authorizedNumber: null,
            indexNumber: null
          },
          tariffZone: null,
          residentialSector: null,
          streetNumber: null,
          streetNumberBis: null,
          streetType: null,
          streetName: null,
          deductionTotal: null
        };
      } }),

    claimerFullname: Ember.computed('claimerFirstName', 'claimerLastName', function () {
      if (_lodash.default.isNil(this.get('claimerLastName'))) {
        // chargement en cours
        return '...';
      }
      if (_lodash.default.isNil(this.get('claimerFirstName'))) {
        return this.get('claimerLastName');
      }

      return this.get('claimerFirstName') + ' ' + this.get('claimerLastName');
    }),
    mandataryFullname: Ember.computed('mandataryFirstName', 'mandataryLastName', function () {
      if (_lodash.default.isNil(this.get('mandataryLastName'))) {
        // chargement en cours
        return '...';
      }
      if (_lodash.default.isNil(this.get('mandataryFirstName'))) {
        return this.get('mandataryLastName');
      }

      return this.get('mandataryFirstName') + ' ' + this.get('mandataryLastName');
    }),

    claimerPostalAddress: Ember.computed('claimerStreetNumber', 'claimerStreetNumberBis', 'claimerStreetType', 'claimerStreetName', function () {
      var address = '';
      if (!_lodash.default.isEmpty(this.get('claimerStreetNumber'))) {
        address = this.get('claimerStreetNumber');
      }
      if (!_lodash.default.isEmpty(this.get('claimerStreetNumberBis'))) {
        address += (_lodash.default.isEmpty(address) ? '' : ' ') + _dictionary.default.streetNumberBis[this.get('claimerStreetNumberBis')];
      }
      address += (_lodash.default.isEmpty(address) ? '' : ' ') + _dictionary.default.streetType[this.get('claimerStreetType')] + ' ' + this.get('claimerStreetName');
      if (!_lodash.default.isEmpty(this.get('claimerAdditionalAddress'))) {
        address += (_lodash.default.isEmpty(address) ? '' : ' ') + this.get('claimerAdditionalAddress');
      }
      return address;
    }),

    mandataryPostalAddress: Ember.computed('mandataryStreetNumber', 'mandataryStreetNumberBis', 'mandataryStreetType', 'mandataryStreetName', function () {
      var address = '';
      if (!_lodash.default.isEmpty(this.get('mandataryStreetNumber'))) {
        address = this.get('mandataryStreetNumber');
      }
      if (!_lodash.default.isEmpty(this.get('mandataryStreetNumberBis'))) {
        address += (_lodash.default.isEmpty(address) ? '' : ' ') + _dictionary.default.streetNumberBis[this.get('mandataryStreetNumberBis')];
      }
      address += (_lodash.default.isEmpty(address) ? '' : ' ') + _dictionary.default.streetType[this.get('mandataryStreetType')] + ' ' + this.get('mandataryStreetName');
      if (!_lodash.default.isEmpty(this.get('mandataryAdditionalAddress'))) {
        address += (_lodash.default.isEmpty(address) ? '' : ' ') + this.get('mandataryAdditionalAddress');
      }
      return address;
    })
  });
});