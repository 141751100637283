define('sags-front-bo/controllers/administrator/service-accessed', ['exports', 'sags-front-bo/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    model: [],
    selectedDate: new Date(),

    // Colonnes du tableau
    tableColumns: [{
      'key': 'identification',
      'displayName': 'Matricule'
    }, {
      'key': 'service',
      'displayName': 'Service'
    }, {
      'key': 'datetime',
      'displayName': 'Date de consultation'
    }],

    // Recuperation des data
    getAccessedServices: function getAccessedServices() {
      var _this = this;

      return this.get('ajax').request(_environment.default.APP.host + '/control/api/accessed-services', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          city: 'Paris',
          selectedDate: this.get('selectedDate').toLocaleDateString()
        },
        contentType: 'application/json'
      }).then(function (data) {
        _this.set('model', data);

        if (data === undefined) {
          _this.get('notifications').info('Aucune donnée pour cette date');
        }
      }).catch(function (reason) {
        _this.get('notifications').error('Erreur lors de la récupération des données: ' + reason);
      });
    },


    actions: {
      setDate: function setDate(date) {
        this.set('selectedDate', date);
      },
      update: function update() {
        this.getAccessedServices();
      }
    }
  });
});