define('sags-front-bo/controllers/white-list-bis/auto-mode', ['exports', 'sags-front-bo/utils/dictionary', 'sags-front-bo/config/environment'], function (exports, _dictionary, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service('current-user'),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),

    autoModeNames: _dictionary.default.convertToArray(_dictionary.default.autoModes),
    selectedModes: {},

    actions: {
      changeModeValue: function changeModeValue(key) {
        var _this = this;

        Ember.set(this.selectedModes, key, !this.selectedModes[key]);
        var value = this.selectedModes[key];

        this.get('ajax').request(_environment.default.APP.host + '/control/api/white-list-bis-mode', {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          },
          data: {
            key: key,
            datatype: 'BOOLEAN',
            value: value
          },
          contentType: 'application/json'
        }).then(function () {
          _this.get('notifications').success("Mode d\'ajout automatique mis à jour");
          _this.set('isLoadingImport', false);
        }).catch(function (error) {
          _this.get('notifications').error(error);
        });
      }
    }
  });
});