define('sags-front-bo/routes/rapo/create', ['exports', 'sags-front-bo/mixins/access-controller', 'lodash', 'sags-front-bo/utils/dictionary'], function (exports, _accessController, _lodash, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var setupRapo = function setupRapo() {
    // redimensionner tous les éléments en dynamic-height avec :
    //  la hauteur de la fenêtre - le décalage en haut (top) - 30px (offset en bas + une marge)
    var height = Ember.$(window).height();
    var allElts = Ember.$('.dynamic-height');
    for (var i = 0; i < allElts.length; i++) {
      var elt = allElts.get(i);
      Ember.$(elt).height(height - elt.getBoundingClientRect().top - 30);
    }
  };

  exports.default = Ember.Route.extend(_accessController.default, {
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    model: function model() {
      return this.store.createRecord('rapo');
    },
    activate: function activate() {
      // on écoute les redimensionnements de la fenêtre et on initialise
      Ember.$(window).on('resize', setupRapo);
      window.scrollTo(0, 0);
      setTimeout(setupRapo, 0);

      // + on empêche le scroll global
      Ember.$(document.body).addClass('rapo-creation');
    },
    deactivate: function deactivate() {
      // on débranche l'événement et on réactive le scroll global
      Ember.$(window).off('resize', setupRapo);
      Ember.$(document.body).removeClass('rapo-creation');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      if (_lodash.default.isNil(controller.get('availableCountries'))) {
        this.store.findAll('country').then(function (countries) {
          controller.set('availableCountries', countries.map(function (c) {
            return {
              name: c.get('isoCode'),
              value: c.get('name')
            };
          }));
        });
      }
      if (_lodash.default.isNil(controller.get('availableVehicleBrands'))) {
        this.store.findAll('vehicle-brand').then(function (brands) {
          controller.set('availableVehicleBrands', brands.sortBy('name').map(function (brand) {
            return { name: brand.get('name'), value: brand.get('name') };
          }));
        });
      }
      if (_lodash.default.isNil(controller.get('availableAttachmentTypes'))) {
        // on ne veut pas afficher le type "Formulaire RAPO" (il est déjà uploadé à droite de l'écran)
        var availableAttachmentTypes = _dictionary.default.convertToPowerSelectOptions(_dictionary.default.rapoAttachmentTypes);
        controller.set('availableAttachmentTypes', availableAttachmentTypes.filter(function (type) {
          return type.name !== 'RAPO_FORM';
        }));
      }

      if (_lodash.default.isNil(controller.get('availableMotivations'))) {
        controller.set('availableMotivations', Object.keys(_dictionary.default.rapoMotivationShort).map(function (motivationCode) {
          return {
            name: motivationCode,
            value: motivationCode + '. ' + _dictionary.default.rapoMotivationShort[motivationCode]
          };
        }));
      }

      _dictionary.default.fetchSettingDictionaries(this.get('ajax'), this.get('session'), this.get('currentUser')).then(function (settings) {
        return controller.set('siret', settings.siret);
      });
    },
    resetController: function resetController(controller) {
      controller.set('isLoadingSearch', false);
      controller.set('isLoadingCreateRapo', false);

      // réinitialiser les variables liées au RAPO mais non stockées dans le RAPO
      controller.set('fpsLegalIdEnd', undefined);
      controller.set('rapoForm', undefined);
      controller.set('hasMandatary', undefined);
      controller.set('attachments', []);

      // on vide le SIRET aussi (il est rechargé à chaque fois pour prendre en charge les changements de ville)
      controller.set('siret', undefined);

      // réinitialiser les power-selects
      controller.set('selectedVehicleBrand', undefined);
      controller.set('selectedClaimerCivility', undefined);
      controller.set('selectedClaimerStreetNumberBis', undefined);
      controller.set('selectedClaimerStreetType', undefined);
      controller.set('selectedClaimerQuality', undefined);
      controller.set('selectedClaimerCountry', undefined);
      controller.set('selectedMandataryCivility', undefined);
      controller.set('selectedMandataryStreetNumberBis', undefined);
      controller.set('selectedMandataryStreetType', undefined);
      controller.set('selectedMandataryCountry', undefined);
      controller.set('selectedMotivation', undefined);
      controller.set('selectedBuyerCivility', undefined);
      controller.set('selectedBuyerStreetNumberBis', undefined);
      controller.set('selectedBuyerStreetType', undefined);
      controller.set('selectedBuyerCountry', undefined);
      controller.set('showBuyerSection', false);

      // ensuite, rollback les modifications apportées au RAPO
      // (ou juste détruire le modèle RAPO créé en local)
      controller.get('model').rollbackAttributes();
    }
  });
});