define('sags-front-bo/controllers/black-list/list', ['exports', 'sags-front-bo/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var typeTable = [];
  var codeTable = [];
  var plateTable = [];
  var agentTable = [];

  var filename = "";

  exports.default = Ember.Controller.extend({

    currentUser: Ember.inject.service('current-user'),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),

    // Liste des variables
    filters: {
      number: {
        comparator: 'contains',
        value: ''
      }
    },
    exporter: {
      text: {
        type: {
          comparator: 'contains',
          value: ''
        },
        code: {
          comparator: 'contains',
          value: ''
        },
        plate: {
          comparator: 'contains',
          value: ''
        },
        agent: {
          comparator: 'contains',
          value: ''
        }
      }
    },
    importer: {
      table: {
        comparator: 'contains',
        value: ''
      }
    },
    verification: {
      peutTraiter: {
        comparator: 'equals',
        value: true
      }
    },

    exportToCsv: function exportToCsv() {
      var CsvString = "Type,Code,vehicle_plate,agent_id\r\n";
      var type = this.get('exporter.text.type.value');
      var code = this.get('exporter.text.code.value');
      var plate = this.get('exporter.text.plate.value');
      var agent = this.get('exporter.text.agent.value');
      for (var pos = 0; pos < type.length; pos++) {
        CsvString += type[pos] + "," + code[pos] + "," + plate[pos] + "," + agent[pos] + "\r\n";
      }
      CsvString = "data:application/xlsx," + encodeURIComponent(CsvString);
      var x = document.createElement("A");
      x.setAttribute("href", CsvString);
      x.setAttribute("download", "List-data.xlsx");
      document.body.appendChild(x);
      x.click();
    },
    verificationImport: function verificationImport() {
      if (plateTable.length !== 0) {
        for (var i = 0; i < plateTable.length; i++) {
          if (plateTable[i].includes("-")) {
            this.set('verification.peutTraiter.value', false);
          }
        }
      }
    },


    actions: {
      Importer: function Importer() {
        var _this = this;

        this.set("isLoading", true);
        this.verificationImport();
        if (this.get('verification.peutTraiter.value')) {
          // On remplace la bdd par les éléments du fichier excel
          this.get('ajax').request(_environment.default.APP.host + '/control/api/black-lists-replace', {
            method: 'POST',
            headers: {
              Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
              City: this.get('currentUser.city')
            },
            data: {
              agentId: agentTable,
              code: codeTable,
              plate: plateTable,
              type: typeTable
            },
            contentType: 'application/json'
          }).then(function () {
            _this.set("isLoading", false);
            _this.get('notifications').success("L'importation a bien été effectué avec le fichier " + filename);
          }).catch(function () {
            _this.set("isLoading", false);
            _this.set('verification.peutTraiter.value', true);
            // Pour le moment c'est la seule erreur trouvé, si jamais d'autres erreurs sont possible il faudra ajouter une procédure de traitement pour gérer les différents cas
            _this.get('notifications').error("Les plaques ne sont pas unique dans le fichier !");
          });
        } else {
          this.set("isLoading", false);
          this.set('verification.peutTraiter.value', true);
          this.get('notifications').error("Problèmes dans la manière de rédiger votre fichier, vérifier qu'il n'y ait pas de tiret(s) ou que le fichier a bien été séléctionné");
        }
      },
      Exporter: function Exporter() {
        var _this2 = this;

        this.set("isLoading", true);
        // Récupère tout les éléments de la bd
        this.get('ajax').request(_environment.default.APP.host + '/control/api/black-lists-getAll', {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          },
          contentType: 'application/json'
        }).then(function (rep) {
          // Variable pour récupérer les données
          var nb = rep.length;
          var type = [];
          var code = [];
          var plate = [];
          var agent = [];
          // On récupère les données
          for (var i = 0; i < nb; i++) {
            type.push(rep.get(i).type);
            code.push(rep.get(i).code);
            plate.push(rep.get(i).vehiclePlate);
            agent.push(rep.get(i).agentId);
          }
          // On les écrit pour préparer l'exportation
          _this2.set('exporter.text.type.value', type);
          _this2.set('exporter.text.code.value', code);
          _this2.set('exporter.text.plate.value', plate);
          _this2.set('exporter.text.agent.value', agent);
          _this2.exportToCsv();
          _this2.set("isLoading", false);
          _this2.get('notifications').success("L'exportation a bien été effectué dans vos téléchargement sous le nom de List-data");
        });
      },
      ChoixFichier: function ChoixFichier() {
        document.getElementById('fichier').addEventListener('change', function () {
          var _this3 = this;

          var fichier = new FileReader();
          fichier.onload = function () {
            _this3.arrayBuffer = fichier.result;
            var data = new Uint8Array(_this3.arrayBuffer);
            var arr = new Array();

            for (var i = 0; i !== data.length; i++) {
              arr[i] = String.fromCharCode(data[i]);
            }

            var bstr = arr.join('');
            // eslint-disable-next-line no-undef
            var workbook = XLSX.read(bstr, {
              type: 'binary',
              cellDates: true
            });
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            // eslint-disable-next-line no-undef
            _this3.worksheet = XLSX.utils.sheet_to_json(worksheet, {
              raw: true
            });
            // On créé des tableaux temporaires
            var type = [];
            var code = [];
            var plate = [];
            var agent = [];
            // On lit et on écrit les éléments un par un
            for (var _i = 0; _i < _this3.worksheet.length; _i++) {
              type.push(_this3.worksheet[_i].Type);
              code.push(_this3.worksheet[_i].Code);
              plate.push(_this3.worksheet[_i].vehicle_plate);
              agent.push(_this3.worksheet[_i].agent_id);
            }
            // On remplace le tableau par le nouveau
            typeTable = type;
            codeTable = code;
            plateTable = plate;
            agentTable = agent;
          };
          fichier.readAsArrayBuffer(this.files[0]);
          filename = this.files[0].name;
        });
      }
    }
  });
});