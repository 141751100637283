define('sags-front-bo/routes/teams/index', ['exports', 'sags-front-bo/mixins/access-controller', 'lodash', 'sweetalert'], function (exports, _accessController, _lodash, _sweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    notifications: Ember.inject.service('notifications'),
    currentUser: Ember.inject.service(),

    model: function model() {
      return this.store.findAll('team').catch(function () {
        console.log('City may not exists');
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('agents', this.store.findAll('user'));
    },

    actions: {
      deleteTeam: function deleteTeam(team) {
        var _this = this;

        var agents = team.get('agents');

        if (!_lodash.default.isNil(team)) {
          (0, _sweetalert.default)({
            title: 'Confirmation',
            text: 'Voulez-vous vraiment supprimer l\'\xE9quipe "' + team.get('identification') + '" ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmer',
            cancelButtonText: 'Annuler',
            target: '#app-container'
          }).then(function () {
            Ember.run(function () {
              team.destroyRecord().then(function () {
                // retirer les agents de leur équipe, et transformer le chef d'équipe en simple agent
                agents.map(function (agent) {
                  agent.set('team', null);
                });

                return team.get('manager').then(function (manager) {
                  manager.set('role', 'AGENT');
                  manager.set('team', null);
                  return manager.save();
                });
              }).then(function () {
                _this.get('notifications').success('L\'équipe a été supprimée avec succès');
              }).catch(function () {
                _this.get('notifications').error('L\'équipe n\'a pas pu être supprimée');
              });
            });
          }).catch(function () {/* aucun traitement, mais on fait disparaître l'erreur dans la console */});
        }
      }
    }
  });
});