define('sags-front-bo/controllers/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    authenticator: 'authenticator:jwt',
    passwordType: 'password',

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        this.set('loading', true);
        this.set('loginError', false);
        this.set('blockedUserError', false);

        var credentials = this.getProperties('identification', 'password');

        this.get('session').authenticate(this.authenticator, credentials).catch(function (error) {
          if (error === 'Blocked') {
            _this.set('blockedUserError', true);
          } else {
            _this.set('loginError', true);
          }
        }).finally(function () {
          _this.set('loading', false);
        });
      },
      clickBlindPassword: function clickBlindPassword() {
        if (this.passwordType === "text") this.set('passwordType', 'password');else this.set('passwordType', 'text');
      }
    }
  });
});