define('sags-front-bo/controllers/white-list-bis/purge', ['exports', 'sags-front-bo/config/environment', 'sweetalert'], function (exports, _environment, _sweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    notifications: Ember.inject.service('notifications'),
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    actions: {
      deleteWhiteListBis: function deleteWhiteListBis() {
        var _this = this;

        (0, _sweetalert.default)({
          title: 'Confirmation',
          text: 'Voulez-vous vraiment supprimer la liste ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmer',
          cancelButtonText: 'Annuler',
          target: '#app-container'
        }).then(function () {
          _this.set('isLoading', true);
          Ember.run(function () {
            _this.get('ajax').request(_environment.default.APP.host + '/control/api/white-list-bis', {
              method: 'DELETE',
              headers: {
                Authorization: 'Bearer ' + _this.get('session.session.authenticated.id_token'),
                City: _this.get('currentUser.city')
              } }).then(function () {
              _this.get('notifications').success('La liste a été supprimée');
            }).catch(function () {
              var notificationService = _this.get('notifications');
              notificationService.error('Une erreur est survenue lors de la suppression de la liste');
            }).finally(function () {
              return _this.set('isLoading', false);
            });
          });
        }).catch(function () {/* aucun traitement, mais on fait disparaître l'erreur dans la console */});
      }
    }
  });
});