define('sags-front-bo/routes/sectors/assignment', ['exports', 'sags-front-bo/config/environment', 'sags-front-bo/mixins/access-controller', 'sags-front-bo/utils/coordinates-mapper', 'sags-front-bo/utils/marker-factory', 'sweetalert'], function (exports, _environment, _accessController, _coordinatesMapper, _markerFactory, _sweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),

    model: function model() {
      return Ember.RSVP.hash({
        teams: this.get('currentUser.user.role') === 'SUPERVISOR' || this.get('currentUser.user.role') === 'SUPERVISOR_EQPT' ? this.store.query('team', { supervisorId: this.get('currentUser.user.id') }) : this.store.findAll('team'),
        sectors: this.store.findAll('sector'),
        agents: null
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.store.findAll('aggregated-section', { reload: true }).then(function (aggregatedSections) {
        var coordinates = [];
        var epiBatailleList = [];
        aggregatedSections.forEach(function (section) {
          if (section.get('typeStat') === 'EPI/BATAILLE/2RM') {
            epiBatailleList.push(section.get('coordinates').map(function (coordinate) {
              return [coordinate[1], coordinate[0]];
            }));
          } else {
            coordinates.push(section.get('coordinates').map(function (coordinate) {
              return [coordinate[1], coordinate[0]];
            }));
          }
        });

        controller.set('aggregatedSectionsLocations', coordinates);
        controller.set('epiBatailleList', epiBatailleList);
      });

      var startZonesCoordinates = [];
      var stopZonesCoordinates = [];

      this.store.findAll('sector').then(function (sectors) {
        var sectorsCoordinatesAndName = _coordinatesMapper.default.switchLatitudeLongitudeOfPolygonesOfSectors(sectors);
        // la map est centrée sur l'ensemble des secteurs
        var bounds = [];
        sectorsCoordinatesAndName.forEach(function (sector) {
          sector.coordinates.forEach(function (coordinate) {
            bounds.push(L.latLng(coordinate[0], coordinate[1]));
          });
          if (sector.startZone !== undefined && sector.startZone !== null) {
            startZonesCoordinates.push({
              coordinate: [sector.startZone[1], sector.startZone[0]],
              icon: _markerFactory.default.createStartIcon(),
              pointName: decodeURIComponent(sector.startName)
            });
          }
          if (sector.stopZone !== undefined && sector.stopZone !== null) {
            stopZonesCoordinates.push({
              coordinate: [sector.stopZone[1], sector.stopZone[0]],
              icon: _markerFactory.default.createStopIcon(),
              pointName: decodeURIComponent(sector.stopName)
            });
          }
        });
        controller.set('bounds', bounds);

        controller.set('sectors', sectorsCoordinatesAndName);
        controller.set('startZonesLocations', startZonesCoordinates);
        controller.set('stopZonesLocations', stopZonesCoordinates);
      });

      // Indicateur d'exécution de requête
      controller.set('isLoadingSave', false);
    },


    actions: {
      save: function save() {
        var _this = this;

        this.set('controller.isLoadingSave', true);
        (0, _sweetalert.default)({
          title: 'Confirmation',
          text: 'Voulez-vous vraiment valider ces affectations ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmer',
          cancelButtonText: 'Annuler',
          target: '#app-container'
        }).then(function () {
          var agents = _this.get('controller.model.agents').map(function (agent) {
            agent.set('sector', agent.get('sector.id'));
            return agent;
          });
          var url = _environment.default.APP.host + '/gis/api/agents/sectorAssignment';
          _this.get('ajax').request(url, {
            method: 'PUT',
            headers: {
              Authorization: 'Bearer ' + _this.get('session.session.authenticated.id_token'),
              City: _this.get('currentUser.city')
            },
            data: agents,
            contentType: 'application/json'
          }).then(function () {
            _this.get('notifications').success('Les affectations ont bien été enregistrées');
            _this.refresh();
          }).catch(function () {
            _this.get('notifications').error('Les affectations n\'ont pas pu être enregistrées');
          });
        }).catch(function () {
          /* aucun traitement, mais on fait disparaître l'erreur dans la console */
        }).finally(function () {
          return _this.set('controller.isLoadingSave', false);
        });
      }
    }
  });
});