define('sags-front-bo/routes/sectors/create', ['exports', 'sags-front-bo/config/environment', 'sags-front-bo/mixins/access-controller', 'sags-front-bo/utils/coordinates-mapper', 'sags-front-bo/utils/marker-factory', 'sweetalert', 'lodash', 'sags-front-bo/utils/gis'], function (exports, _environment, _accessController, _coordinatesMapper, _markerFactory, _sweetalert, _lodash, _gis) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    currentUser: Ember.inject.service(),
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      controller.set('hasToBeCanceled', false);

      // Indicateur d'exécution de requête
      controller.set('isLoadingSave', false);

      var aggregatedSectionsBounds = [];
      this.store.findAll('aggregated-section', { reload: true }).then(function (aggregatedSections) {
        var coordinates = [];
        var epiBatailleList = [];
        aggregatedSections.forEach(function (section) {
          if (section.get('typeStat') === 'EPI/BATAILLE/2RM') {
            epiBatailleList.push(section.get('coordinates').map(function (coordinate) {
              return [coordinate[1], coordinate[0]];
            }));
          } else {
            coordinates.push(section.get('coordinates').map(function (coordinate) {
              return [coordinate[1], coordinate[0]];
            }));
          }
        });
        // la map est centré sur l'ensemble des tronçons aggrégés de la ville
        epiBatailleList.forEach(function (coordinate) {
          aggregatedSectionsBounds.push(L.latLng(coordinate[0], coordinate[1]));
        });
        coordinates.forEach(function (coordinate) {
          aggregatedSectionsBounds.push(L.latLng(coordinate[0], coordinate[1]));
        });
        controller.set('epiBatailleList', epiBatailleList);
        controller.set('aggregatedSectionsLocations', coordinates);

        return _this.store.findAll('sector');
      }).then(function (sectors) {
        if (!_lodash.default.isNil(sectors) && sectors.get('length') !== 0) {
          var sectorsCoordinatesAndName = _coordinatesMapper.default.switchLatitudeLongitudeOfPolygonesOfSectors(sectors);
          var allDrawnFeatures = [];
          var bounds = [];
          sectorsCoordinatesAndName.forEach(function (sector) {
            // la map est centrée sur l'ensemble des secteurs
            sector.coordinates.forEach(function (coordinate) {
              bounds.push(L.latLng(coordinate[0], coordinate[1]));
            });
            // construire un PolygonLayer
            allDrawnFeatures.push(L.polygon(sector.get('coordinates')));

            // Affichage point de départ
            if (sector.startZone !== undefined && sector.startZone !== null) {
              allDrawnFeatures.push(_markerFactory.default.createPoint(
              // C'est un point de départ
              true,
              // Inversion des coordonnées
              [sector.startZone[1], sector.startZone[0]], sector.startName !== null ? sector.startName : ''));
            }
            if (sector.stopZone !== undefined && sector.stopZone !== null) {
              allDrawnFeatures.push(_markerFactory.default.createPoint(
              // C'est un point d'arrivée
              false,
              // Inversion des coordonnées
              [sector.stopZone[1], sector.stopZone[0]], sector.stopName !== null ? sector.stopName : ''));
            }
          });
          controller.set('bounds', bounds);
          controller.set('allDrawnFeatures', L.featureGroup(allDrawnFeatures).toGeoJSON().features);
          controller.set('allLayers', allDrawnFeatures);
          controller.set('sectorIds', sectors.mapBy('id'));
        } else {
          controller.set('bounds', aggregatedSectionsBounds);
        }

        controller.set('displayDrawControl', true);
      });

      this.set('controller.drawOptions', {
        polyline: false,
        rectangle: false,
        circle: false,
        circlemarker: false
      });
    },
    resetController: function resetController(controller) {
      controller.set('aggregatedSectionsLocations', undefined);
      controller.set('epiBatailleList', undefined);
      controller.set('bounds', undefined);

      controller.set('allDrawnFeatures', []);
      controller.set('allLayers', []);
      controller.set('sectorIds', []);

      controller.set('displayDrawControl', false);
      controller.set('isMarkerInfoVisible', false);
      controller.set('editableLayerInitialized', false);
      controller.set('sectorChanged', false);
    },


    actions: {
      save: function save() {
        var _this2 = this;

        this.set('controller.isLoadingSave', true);
        var features = this.get('controller.allDrawnFeatures');
        var drawnPolygons = [];
        var startMarkers = [];
        var stopMarkers = [];
        features.forEach(function (feature) {
          if (feature.geometry.type === 'Polygon') {
            drawnPolygons.push(feature);
          }
        });

        var allLayers = this.get('controller.allLayers');

        allLayers.forEach(function (layer) {
          if (layer.layerType !== undefined && layer.layerType === 'Gate') {
            if (layer.isStart) {
              startMarkers.push(layer);
            } else {
              stopMarkers.push(layer);
            }
          }
        });

        if (_lodash.default.isEmpty(drawnPolygons) || _lodash.default.isEmpty(startMarkers) || _lodash.default.isEmpty(stopMarkers)) {
          this.get('notifications').error('Vous devez définir au moins un secteur avec un point de départ et un point d\'arrivée');
          this.set('controller.isLoadingSave', false);
        } else if (drawnPolygons.length !== startMarkers.length) {
          this.get('notifications').error('Le nombre de secteurs et de points de départ doit être identique');
          this.set('controller.isLoadingSave', false);
        } else {

          var numberOfPoints = new Array();
          drawnPolygons.forEach(function (polygon) {
            var poly = polygon.geometry.coordinates;
            var pointNumber = 0;
            startMarkers.forEach(function (point) {
              var p = point.toGeoJSON().geometry.coordinates;
              if ((0, _gis.contains)(poly[0], p)) {
                pointNumber++;
              }
            });
            numberOfPoints.push(pointNumber);
          });

          if (numberOfPoints.every(function (elt) {
            return elt === 1;
          })) {
            var warningMessage = void 0;
            if (this.get('controller.sectorChanged')) {
              warningMessage = 'Voulez-vous vraiment remplacer les secteurs et points de départ ?';
            } else {
              warningMessage = 'Voulez-vous vraiment modifier les points de départ ?';
            }

            (0, _sweetalert.default)({
              title: 'Confirmation',
              text: warningMessage,
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Confirmer',
              cancelButtonText: 'Annuler',
              target: '#app-container'
            }).then(function () {
              var sectorChanged = _this2.get('controller.sectorChanged');

              var sectorsToCreate = [];
              var nbZones = drawnPolygons.length;
              for (var numZone = 0; numZone < nbZones; numZone++) {
                // Il faut retrouver les marqueurs de cette zone
                var numStart = -1;
                var numStop = -1;
                var polyZone = drawnPolygons[numZone].geometry.coordinates[0];
                for (var numMarker = 0; numMarker < nbZones; numMarker++) {
                  if ((0, _gis.contains)(polyZone, startMarkers[numMarker].toGeoJSON().geometry.coordinates)) {
                    numStart = numMarker;
                  }
                  if ((0, _gis.contains)(polyZone, stopMarkers[numMarker].toGeoJSON().geometry.coordinates)) {
                    numStop = numMarker;
                  }
                }

                var sectorObject = Ember.Object.create({
                  name: (numZone + 1).toString(),
                  coordinates: polyZone,
                  startZone: startMarkers[numStart].toGeoJSON().geometry.coordinates,
                  startName: startMarkers[numStart].pointName,
                  stopZone: stopMarkers[numStop].toGeoJSON().geometry.coordinates,
                  stopName: stopMarkers[numStop].pointName
                });

                if (!sectorChanged) {
                  sectorObject.set('id', _this2.get('controller.sectorIds')[numZone]);
                }

                sectorsToCreate.push(sectorObject);
              }

              var url = void 0;
              var method = void 0;
              if (sectorChanged) {
                // écrasement de tous les secteurs
                method = 'POST';
                url = _environment.default.APP.host + '/gis/api/sectors/replace';
              } else {
                // mise à jour des points de départ
                method = 'PUT';
                url = _environment.default.APP.host + '/gis/api/sectors/start-zones';
              }

              _this2.get('ajax').request(url, {
                method: method,
                headers: {
                  Authorization: 'Bearer ' + _this2.get('session.session.authenticated.id_token'),
                  City: _this2.get('currentUser.city')
                },
                data: sectorsToCreate,
                contentType: 'application/json'
              }).then(function () {
                _this2.get('notifications').success('Les secteurs ont bien été enregistrés');
                _this2.store.unloadAll('sector');
                _this2.transitionTo('sectors.assignment');
              }).catch(function () {
                _this2.get('notifications').error('Les secteurs n\'ont pas pu être enregistrés');
              });
            }).catch(function () {
              /* aucun traitement, mais on fait disparaître l'erreur dans la console */
            }).finally(function () {
              return _this2.set('controller.isLoadingSave', false);
            });
          } else {
            this.get('notifications').error('Chaque secteur doit avoir un point de départ');
            this.set('controller.isLoadingSave', false);
          }
        }
      }
    }
  });
});