define('sags-front-bo/components/maps/here-map-routing', ['exports', 'lodash', 'sags-front-bo/config/environment', 'sags-front-bo/utils/dictionary', 'sags-front-bo/helpers/find-by-key', 'sags-front-bo/utils/map'], function (exports, _lodash, _environment, _dictionary, _findByKey, _map) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var platform = new H.service.Platform({
    app_id: _environment.default.APP.here.id,
    app_code: _environment.default.APP.here.code,
    useCIT: _environment.default.APP.here.cit,
    useHTTPS: _environment.default.APP.here.https,
    apikey: _environment.default.APP.here.keyApi
  });

  // Layer Satellite
  var rasterTileServiceSat = platform.getRasterTileService({
    queryParams: {
      'style': 'explore.satellite.day'
    }
  });

  var rasterTileProviderSat = new H.service.rasterTile.Provider(rasterTileServiceSat);
  var rasterTileLayerSat = new H.map.layer.TileLayer(rasterTileProviderSat);

  // Layer MapView
  var rasterTileServiceMapview = platform.getRasterTileService({
    queryParams: {
      'style': 'lite.day'
    }
  });

  var rasterTileProviderMapview = new H.service.rasterTile.Provider(rasterTileServiceMapview);
  var rasterTileLayerMapview = new H.map.layer.TileLayer(rasterTileProviderMapview);

  // routeur personnalisé qui passe les waypoints dans le body de la requête (pour éviter que l'URL soit trop longue)
  var customRouter = {
    baseUrl: null,
    apiKey: _environment.default.APP.here.keyApi,
    configure: function configure(appId, appCode, useHTTPS, useCIT, opt_baseUrl, apikey) {
      if (!_lodash.default.isNil(opt_baseUrl)) {
        // l'URL donnée contient déjà le domaine (HTTPS ou non, CIT ou non) et l'App ID/App Code en query param
        customRouter.baseUrl = opt_baseUrl;
        customRouter.apiKey = apikey;
      } else {
        customRouter.baseUrl = H.service.Url.parse('https://router.hereapi.com');
      }
    },
    calculateRoute: function calculateRoute(origin, destination, ajax, calculateRouteParams, onResult, onError) {
      var url = customRouter.baseUrl.clone();
      // insérer les paramètres (hors waypoints) dans les URL
      url.mergeQuery(calculateRouteParams);
      var marker = {
        origin: origin,
        destination: destination
        // rajouter le path à la fin de l'URL
      };url.setPath(_environment.default.APP.here.routingVersion + '/routes');
      return ajax.request(url.toString(), {
        method: 'GET',
        contentType: 'application/x-www-form-urlencoded'
      }).then(function (data) {
        return onResult(marker, data);
      }).catch(function (error) {
        return onError(error);
      });
    }
  };

  // Fonction pour dessiner un polygone sur une carte
  var addPolygonToMap = function addPolygonToMap(ui, map, polygonCoordinates, polygonMetaInfo) {
    var lineString = new H.geo.LineString(polygonCoordinates);
    var polygon = new H.map.Polygon(lineString, {
      style: {
        fillColor: '#829',
        strokeColor: '#829',
        lineWidth: 8
      }
    });

    // Afficher une infobulle des informations du tronçon agrégé au clic
    polygon.addEventListener('tap', function (evt) {
      var cursorPosition = map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY);
      var bubble = new H.ui.InfoBubble(cursorPosition, {
        content: polygonMetaInfo
      });

      // Supprimer toutes les infobulles
      ui.getBubbles().forEach(function (bubs) {
        ui.removeBubble(bubs);
      });

      // Afficher l'infobulle
      ui.addBubble(bubble);
    }, false);

    map.addObject(polygon);
  };

  // Ajouter une infobulle pour les positions
  var addPositionBubble = function addPositionBubble(ui, marker, positions) {
    marker.addEventListener('tap', function (evt) {
      var bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
        content: positions
      });

      // Supprimer toutes les infobulles
      ui.getBubbles().forEach(function (bubs) {
        ui.removeBubble(bubs);
      });

      // Afficher l'infobulle
      ui.addBubble(bubble);
    }, false);
  };

  // Fonction pour récupérer les informations du tronçon agrégé
  var getInfosAggregatedSection = function getInfosAggregatedSection(aggregatedSection, tariffZones, residentialSectors) {
    var polygonMetaInfo = [];
    polygonMetaInfo.push('Id public : ' + aggregatedSection['publicId']);
    polygonMetaInfo.push('Type de rue : ' + _dictionary.default.streetType[aggregatedSection['roadType']]);
    polygonMetaInfo.push('Nom de rue : ' + aggregatedSection['roadName']);
    polygonMetaInfo.push('Parité : ' + _dictionary.default.parity[aggregatedSection['parity']]);
    polygonMetaInfo.push('Zone tarifaire : ' + _findByKey.default.compute([tariffZones, aggregatedSection['tariffZone']]));
    if (aggregatedSection['resSector']) {
      polygonMetaInfo.push('Secteur résidentiel : ' + _findByKey.default.compute([residentialSectors, aggregatedSection['resSector']]));
    }
    polygonMetaInfo.push('Nombre de places : ' + aggregatedSection['numberOfPlaces']);
    return polygonMetaInfo.join('<br/>');
  };

  // Fonction pour récupérer les coordonnées du tronçon agrégé
  var getCoordinatesAggregatedSection = function getCoordinatesAggregatedSection(aggregatedSection) {
    var polygonCoordinates = [];
    var coordinates = aggregatedSection['coordinates'];
    for (var indexCoordinate = 0; indexCoordinate < coordinates.length; indexCoordinate++) {
      var coordinate = coordinates.objectAt(indexCoordinate);
      polygonCoordinates.push(coordinate[1]);
      polygonCoordinates.push(coordinate[0]);
      polygonCoordinates.push(0);
    }
    return polygonCoordinates;
  };

  exports.default = Ember.Component.extend({
    notifications: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    showSectionsPositions: false,
    positionsMarkers: [],

    didInsertElement: function didInsertElement() {
      var _this = this;

      // La carte
      var map = new H.Map(this.$('#map')[0], rasterTileLayerMapview);

      // Gestion du zoom et interface par défaut
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

      // Gestion du menu
      var ui = new H.ui.UI(map);

      ui.addControl('custom_settings_control', new H.ui.MapSettingsControl({
        baseLayers: [{
          label: 'Normal Map',
          layer: rasterTileLayerMapview
        }, {
          label: 'Satellite',
          layer: rasterTileLayerSat
        }],
        layers: []
      }));

      var userType = this.get('tour.userType');
      var transportMode = '';
      var avoid = "";

      if (userType === 'LAPI') {
        transportMode = 'car';
        avoid = "tollroad,tunnel,uTurns";
      } else {
        transportMode = 'bicycle';
        avoid = "tollroad,tunnel";
      }

      // Paramètres du routing
      var routingParameters = {
        'routingMode': 'fast',
        'transportMode': transportMode,
        'avoid[features]': avoid,
        'return': 'polyline',
        'apiKey': _environment.default.APP.here.keyApi
      };
      var viaTab = [];
      var origin = "";
      var destination = "";

      // Ajouter les waypoints de la tournée au paramètres du Routing
      var route = this.get('tour.route');
      var nbWaypoints = route.length;
      for (var indexWaypoint = 0; indexWaypoint < nbWaypoints; indexWaypoint++) {
        var coordinate = route.objectAt(indexWaypoint);
        switch (indexWaypoint) {
          case 0:
            origin = coordinate['latitude'] + ',' + coordinate['longitude'];
            break;
          case nbWaypoints - 1:
            destination = coordinate['latitude'] + ',' + coordinate['longitude'];
            break;
          default:
            viaTab.push(coordinate['latitude'] + ',' + coordinate['longitude']);
        }
      }

      // On récupère les zones tarifaires/secteurs du serveur
      _dictionary.default.fetchSettingDictionaries(this.get('ajax'), this.get('session'), this.get('currentUser')).then(function (settings) {
        // Ajouter les tronçons agrégés à la carte
        var aggregatedSections = _this.get('tour.aggregatedSections');
        for (var indexAggSection = 0; indexAggSection < aggregatedSections.length; indexAggSection++) {
          var aggregatedSection = aggregatedSections.objectAt(indexAggSection);
          addPolygonToMap(ui, map, getCoordinatesAggregatedSection(aggregatedSection), getInfosAggregatedSection(aggregatedSection, _dictionary.default.convertToArray(settings.tariffZones), _dictionary.default.convertToArray(settings.residentialSectors)));
        }
      });

      // Fonction de callback pour traiter la réponse du routing
      var onResult = function onResult(marker, result) {
        var route = void 0,
            routeShape = void 0,
            startPoint = void 0,
            endPoint = void 0,
            linestring = void 0;
        if (result.routes) {
          // Prendre la première route de la response
          route = result.routes[0];
          for (var i = 0; i < route.sections.length; i++) {
            // Prendre la forme de l'itinéraire
            routeShape = decode(route.sections[i].polyline);

            // Créer une linestring pour l'utiliser comme une source de points pour la route
            linestring = new H.geo.LineString();

            // Injecter les points de l'itinéraire dans la linestring
            routeShape.polyline.forEach(function (point) {
              linestring.pushLatLngAlt(point[0], point[1]);
            });

            // Récupérer la position du point de départ et d'arrivée
            startPoint = route.sections[i].departure.place.location;
            endPoint = route.sections[i].arrival.place.originalLocation;

            // Créer une polyline pour afficher route
            var routeOutline = new H.map.Polyline(linestring, {
              style: {
                lineWidth: 8,
                strokeColor: 'rgba(0, 128, 255, 0.7)',
                lineTailCap: 'arrow-tail',
                lineHeadCap: 'arrow-head'
              }
            });

            // Créer une polyline pour afficher des fleche
            var routeArrows = new H.map.Polyline(linestring, {
              style: {
                lineWidth: 8,
                fillColor: 'white',
                strokeColor: 'rgba(255, 255, 255, 1)',
                lineDash: [0, 2],
                lineTailCap: 'arrow-tail',
                lineHeadCap: 'arrow-head'
              }
            });

            var startMarker = void 0;
            if (marker.origin) {
              // Marqueur pour le point de départ
              startMarker = new H.map.Marker({
                lat: startPoint.lat,
                lng: startPoint.lng
              });
            }
            var endMarker = void 0;
            if (marker.destination) {
              if (i === route.sections.length - 1) {
                // Marqueur pour le point d'arrivée
                endMarker = new H.map.Marker({
                  lat: endPoint.lat,
                  lng: endPoint.lng
                }, {
                  icon: new H.map.Icon(_environment.default.rootURL + 'assets/images/marker_stop.png')
                });
              }
            }
            if (marker.destination && i === route.sections.length - 1) {
              // Ajouter la polyline et les deux marqueurs à la route
              map.addObjects([routeOutline, routeArrows, endMarker]);
            } else if (marker.origin) {
              // Ajouter la polyline et les deux marqueurs à la route
              map.addObjects([routeOutline, routeArrows, startMarker]);
            } else {
              // Ajouter la polyline et les deux marqueurs à la route
              map.addObjects([routeOutline, routeArrows]);
            }

            // Editer le viewport de la map pour afficher la route
            map.getViewModel().setLookAtData({ bounds: routeOutline.getBoundingBox(), zoom: 15 });
          }
        }
      };

      var DECODING_TABLE = [62, -1, -1, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, -1, -1, -1, -1, -1, -1, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, -1, -1, -1, -1, 63, -1, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51];

      var FORMAT_VERSION = 1;

      var Num = Number;
      function decode(encoded) {
        var decoder = decodeUnsignedValues(encoded);
        var header = decodeHeader(decoder[0], decoder[1]);

        var factorDegree = Math.pow(10, header.precision);
        var factorZ = Math.pow(10, header.thirdDimPrecision);
        var thirdDim = header.thirdDim;


        var lastLat = 0;
        var lastLng = 0;
        var lastZ = 0;
        var res = [];

        var i = 2;
        for (; i < decoder.length;) {
          var deltaLat = toSigned(decoder[i]) / factorDegree;
          var deltaLng = toSigned(decoder[i + 1]) / factorDegree;
          lastLat += deltaLat;
          lastLng += deltaLng;

          if (thirdDim) {
            var deltaZ = toSigned(decoder[i + 2]) / factorZ;
            lastZ += deltaZ;
            res.push([lastLat, lastLng, lastZ]);
            i += 3;
          } else {
            res.push([lastLat, lastLng]);
            i += 2;
          }
        }

        if (i !== decoder.length) {
          throw new Error('Invalid encoding. Premature ending reached');
        }

        return {
          polyline: res
        };
      }

      function decodeChar(char) {
        var charCode = char.charCodeAt(0);
        return DECODING_TABLE[charCode - 45];
      }

      function decodeUnsignedValues(encoded) {
        var result = Num(0);
        var shift = Num(0);
        var resList = [];

        encoded.split('').forEach(function (char) {
          var value = Num(decodeChar(char));
          result |= (value & Num(0x1F)) << shift;
          if ((value & Num(0x20)) === Num(0)) {
            resList.push(result);
            result = Num(0);
            shift = Num(0);
          } else {
            shift += Num(5);
          }
        });

        if (shift > 0) {
          throw new Error('Invalid encoding');
        }

        return resList;
      }

      function decodeHeader(version, encodedHeader) {
        if (+version.toString() !== FORMAT_VERSION) {
          throw new Error('Invalid format version');
        }
        var headerNumber = +encodedHeader.toString();
        var precision = headerNumber & 15;
        var thirdDim = headerNumber >> 4 & 7;
        var thirdDimPrecision = headerNumber >> 7 & 15;
        return { precision: precision, thirdDim: thirdDim, thirdDimPrecision: thirdDimPrecision };
      }

      function toSigned(val) {
        // Decode the sign from an unsigned value
        var res = val;
        if (res & Num(1)) {
          res = ~res;
        }
        res >>= Num(1);
        return +res.toString();
      }

      // Configurer le router
      customRouter.configure();

      for (var i = 0; i < viaTab.length; i++) {
        if (i === 0) {
          // origin vers 1 er via
          routingParameters['origin'] = origin;
          routingParameters['destination'] = viaTab[i];
          customRouter.calculateRoute(true, false, this.get('ajax'), routingParameters, onResult, function () {
            /* aucun traitement, mais on fait disparaître l'erreur dans la console */
          });
        } else {
          var viaLength = viaTab.slice(i, i + 199).length; // size of chunck
          // via vers via
          routingParameters['origin'] = viaTab[i - 1];
          if (viaLength < 198) {
            routingParameters['destination'] = destination;
            routingParameters['via'] = new H.service.Url.MultiValueQueryParameter(viaTab.slice(i, viaTab.length - 1));
            customRouter.calculateRoute(false, true, this.get('ajax'), routingParameters, onResult, function () {
              /* aucun traitement, mais on fait disparaître l'erreur dans la console */
            });
          } else {
            routingParameters['destination'] = viaTab[i + viaLength];
            routingParameters['via'] = new H.service.Url.MultiValueQueryParameter(viaTab.slice(i, i + 199));
            customRouter.calculateRoute(false, false, this.get('ajax'), routingParameters, onResult, function () {
              /* aucun traitement, mais on fait disparaître l'erreur dans la console */
            });
          }
          i = i + viaLength;
        }
      }
      this.set('map', map);

      // Réinitialiser markers de position tronçons
      this.set('showSectionsPositions', false);
      this.set('positionsMarkers', []);
    },


    actions: {
      changeDisplaySectionsPositions: function changeDisplaySectionsPositions() {
        var _this2 = this;

        this.toggleProperty('showSectionsPositions');

        if (this.get('showSectionsPositions')) {
          var aggregatedSections = this.get('tour.aggregatedSections');
          var aggSectionsPositionLabels = [];

          // Afficher la ou les positions du tronçon dans la tournée (peut être présent plusieurs fois dans une même tournée)
          for (var indexAggSection = 0; indexAggSection < aggregatedSections.length; indexAggSection++) {
            var aggregatedSection = aggregatedSections.objectAt(indexAggSection);
            var newPositionLabel = indexAggSection + 1;
            var oldPositionLabel = aggSectionsPositionLabels[aggregatedSection.publicId];

            if (!_lodash.default.isNil(oldPositionLabel)) {
              newPositionLabel = oldPositionLabel.label + '-' + newPositionLabel;
            }

            var centroid = _map.default.getCentroid(aggregatedSection['coordinates']);
            aggSectionsPositionLabels[aggregatedSection.publicId] = {
              label: newPositionLabel,
              lat: centroid.lat,
              lng: centroid.lng
            };
          }

          var posMarkers = [];

          // Gestion du menu
          var ui = new H.ui.UI(this.get('map'));

          ui.addControl('custom_settings_control', new H.ui.MapSettingsControl({
            baseLayers: [{
              label: 'Normal Map',
              layer: rasterTileLayerMapview
            }, {
              label: 'Satellite',
              layer: rasterTileLayerSat
            }],
            layers: []
          }));

          aggSectionsPositionLabels.forEach(function (aggText) {
            if (typeof aggText.label !== 'string') {
              aggText.label = aggText.label.toString();
            }
            var marker = _map.default.addTextMarker(_this2.get('map'), aggText.label, aggText.lat, aggText.lng);
            addPositionBubble(ui, marker, aggText.label);
            posMarkers.push(marker);
          });

          this.set('positionsMarkers', posMarkers);
        } else {
          this.get('map').removeObjects(this.get('positionsMarkers'));
          this.set('positionsMarkers', []);
        }
      }
    }
  });
});