define('sags-front-bo/controllers/administrator/locked-precontrols', ['exports', 'sags-front-bo/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    notifications: Ember.inject.service(),
    model: [],

    // Colonnes du tableau
    tableColumns: [{
      'key': 'identification',
      'displayName': 'Matricule'
    }, {
      'key': 'firstName',
      'displayName': 'Prénom'
    }, {
      'key': 'lastName',
      'displayName': 'Nom'
    }, {
      'key': 'lockedCount',
      'displayName': 'VAO en cours'
    }],

    // Icones pour action
    actionIcons: [{
      icon: 'close',
      tooltip: 'Fermer'
    }],

    // Methode executee au chargement de la page
    initRequest: function () {
      this.getPrecontrolsLocked();
    }.on('init'),

    // Recuperer les données du tableau
    getPrecontrolsLocked: function getPrecontrolsLocked() {
      var _this = this;

      return this.get('ajax').request(_environment.default.APP.host + '/precontrol/api/locked-precontrols', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          city: 'Paris'
        },
        contentType: 'application/json'
      }).then(function (data) {
        _this.set('model', data);
      }).catch(function (reason) {
        alert("Erreur lors de la récupération des données " + reason);
      });
    },


    actions: {

      // Débloquer les precontroles Locked pour l'agent choisi
      unlockPrecontrols: function unlockPrecontrols(rowData) {
        var _this2 = this;

        return this.get('ajax').request(_environment.default.APP.host + '/precontrol/api/unlock-precontrols', {
          method: 'PUT',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            city: 'Paris',
            userId: rowData.id
          },
          dataType: 'text'
        }).then(function () {
          _this2.get('notifications').success('Les statuts des précontrôles ont été mis à jour');
          _this2.getPrecontrolsLocked();
        }).catch(function () {
          _this2.get('notifications').error('Une erreur est survenue lors de la mise à jour des précontrôles');
        });
      }
    }
  });
});