define("sags-front-bo/helpers/valid-vehicle-type-plate", ["exports", "sags-front-bo/utils/dictionary"], function (exports, _dictionary) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.validVehicleTypePlate = validVehicleTypePlate;
  function validVehicleTypePlate(params) {

    var vehicleBrandFromSiv = params[0].toUpperCase();
    var vehicleTypeFromSiv = _dictionary.default.vehiculeType[params[1].toUpperCase()];
    var vehicleType = _dictionary.default.vehiculeType[params[2].toUpperCase()];
    var vehicleBrand = params[3].toUpperCase();

    if (vehicleBrandFromSiv !== "UNKNOWN" && vehicleBrandFromSiv !== vehicleBrand) {
      return false;
    } else if (vehicleTypeFromSiv !== "Non défini" && vehicleType !== "Non réglementé" && vehicleTypeFromSiv !== vehicleType) {
      return false;
    }

    return true;
  }

  exports.default = Ember.Helper.helper(validVehicleTypePlate);
});