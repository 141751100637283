define('sags-front-bo/components/view-attachment', ['exports', 'sags-front-bo/utils/converter'], function (exports, _converter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var _zoom = 1;
  var x = void 0,
      y = void 0;

  exports.default = Ember.Component.extend({
    pdfData: Ember.computed('attachmentBase64', function () {
      return (0, _converter.base64toObjectUrl)(this.get('attachmentBase64'), 'application/pdf');
    }),

    actions: {
      closeAttachmentModal: function closeAttachmentModal() {
        _zoom = 1;
        this.get('closeAttachmentModal')();
      },
      move: function move(event) {
        var scrollWindow = event.currentTarget;
        if (x && y) {
          scrollWindow.scrollBy((event.clientX - x) * 2, (event.clientY - y) * 2);
        }

        x = event.clientX;
        y = event.clientY;
      },
      zoom: function zoom(event) {
        var image = event.currentTarget;

        if (event.deltaY < 0) {
          _zoom += 0.1;
          image.style.transform = 'scale(' + _zoom + ')';
        } else if (event.deltaY > 0 && _zoom >= 0.75) {
          _zoom -= 0.1;
          image.style.transform = 'scale(' + _zoom + ')';
        }
      }
    }
  });
});