define('sags-front-bo/controllers/administrator/health', ['exports', 'sags-front-bo/config/environment', 'sags-front-bo/utils/format-delta-time'], function (exports, _environment, _formatDeltaTime) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    totalTimeControl: "",
    totalTimePrecontrol: "",

    checkHealthControl: function checkHealthControl() {
      var _this = this;

      this.set('totalTimeControl', "");
      var startDate = Date.now();

      return this.get('ajax').request(_environment.default.APP.host + '/control/api/health', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          city: 'Paris'
        }
      }).then(function () {
        var endDate = Date.now();
        var totalTime = endDate - startDate;

        _this.set('totalTimeControl', _formatDeltaTime.default.deltaTimeToString(totalTime));
        _this.get('notifications').success('Communication avec le service Control établie');
      }).catch(function () {
        _this.get('notifications').error('Le service Control est actuellement indisponible');
      });
    },
    checkHealthPrecontrol: function checkHealthPrecontrol() {
      var _this2 = this;

      this.set('totalTimePrecontrol', "");
      var startDate = Date.now();

      return this.get('ajax').request(_environment.default.APP.host + '/precontrol/api/health', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          city: 'Paris'
        }
      }).then(function () {
        var endDate = Date.now();
        var totalTime = endDate - startDate;

        _this2.set('totalTimePrecontrol', _formatDeltaTime.default.deltaTimeToString(totalTime));
        _this2.get('notifications').success('Communication avec le service Precontrol établie');
      }).catch(function () {
        _this2.get('notifications').error('Le service Precontrol est actuellement indisponible');
      });
    },


    actions: {
      checkHealth: function checkHealth() {
        this.get('notifications').info('Connexion aux services en cours');
        this.checkHealthControl();
        this.checkHealthPrecontrol();
      }
    }
  });
});