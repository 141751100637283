define('sags-front-bo/models/planning', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    start: _emberData.default.attr('date'),
    end: _emberData.default.attr('date'),
    resourceId: _emberData.default.attr('string'),
    tripType: _emberData.default.attr('string'),
    optional: _emberData.default.attr('boolean'),
    additional: _emberData.default.attr('boolean'), // tournée complémentaire
    additionalTime: _emberData.default.attr('number'), // durée en mn du temps complémentaire
    title: _emberData.default.attr('string')
  });
});