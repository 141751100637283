define('sags-front-bo/utils/marker-factory', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /* global L */

  var markerFactory = {

    numPopup: 0,

    // https://github.com/Leaflet/Leaflet/blob/main/src/layer/marker/Icon.Default.js
    createStartIcon: function createStartIcon() {
      return L.icon({
        iconUrl: '../assets/images/marker_start.png',
        shadowUrl: '../assets/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41]
      });
    },

    createStopIcon: function createStopIcon() {
      return L.icon({
        iconUrl: '../assets/images/marker_stop.png',
        shadowUrl: '../assets/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41]
      });
    },

    createTooltipMsg: function createTooltipMsg(isStart, pointNameDisplay) {
      return (isStart ? 'Point de départ' : 'Point d\'arrivée') + ' ' + (pointNameDisplay !== '' ? ': ' + pointNameDisplay : '');
    },
    escapeQuotes: function escapeQuotes(text) {
      return text.replace(/'/g, '&apos;').replace(/"/g, '&quot;');
    },
    createPoint: function createPoint(isStart, coordinate, pointName) {
      var pointNameDisplay = markerFactory.escapeQuotes(pointName);
      this.numPopup = this.numPopup + 1;
      var localNumPopup = this.numPopup;

      L.Marker.include({
        isStart: false,
        pointName: '',
        layerType: ''
      });

      var marker = L.marker(coordinate, { icon: isStart ? this.createStartIcon() : this.createStopIcon() });
      marker.layerType = 'Gate';
      marker.isStart = isStart;
      marker.pointName = pointName;
      marker.bindTooltip(markerFactory.createTooltipMsg(isStart, pointNameDisplay));

      var markerPopup = function markerPopup(pointNameDisplay) {
        return '' + '<div style="text-align: center; margin-bottom: 3px;">' + '<input id="point_name_' + localNumPopup + '" type="text" value="' + pointNameDisplay + '" style="min-width: 180px" />' + '</div>' + '<div style="text-align: center; margin-bottom: 3px;">' + '<button id="point_start_' + localNumPopup + '" class="btn" style="min-width: 80px" type="button" >Départ</button>' + '<button id="point_stop_' + localNumPopup + '" class="btn" style="min-width: 80px" type="button" >Arrivée</button>' + '</div>';
      };

      marker.bindPopup(markerPopup(pointNameDisplay));

      var clickPopup = function clickPopup() {
        var inputPointName = document.getElementById("point_name_" + localNumPopup);
        var buttonPointDepart = document.getElementById("point_start_" + localNumPopup);
        var buttonPointArrivee = document.getElementById("point_stop_" + localNumPopup);
        var isStart = marker.isStart;
        if (isStart) {
          buttonPointDepart.classList.add('btn-primary');
          buttonPointArrivee.classList.add('btn-secondary');
          marker.setIcon(markerFactory.createStartIcon());
        } else {
          buttonPointArrivee.classList.add('btn-success');
          buttonPointDepart.classList.add('btn-secondary');
          marker.setIcon(markerFactory.createStopIcon());
        }
        inputPointName.addEventListener("focusout", function (e) {
          marker.pointName = e.target.value;
          var pointNameDisplay = markerFactory.escapeQuotes(marker.pointName);
          marker.bindTooltip(markerFactory.createTooltipMsg(isStart, pointNameDisplay));
        });
        buttonPointDepart.addEventListener("click", function () {
          buttonPointDepart.classList.add('btn-primary');
          buttonPointArrivee.classList.remove('btn-success');
          buttonPointArrivee.classList.add('btn-secondary');
          marker.setIcon(markerFactory.createStartIcon());
          var pointNameDisplay = markerFactory.escapeQuotes(marker.pointName);
          marker.bindTooltip(markerFactory.createTooltipMsg(true, pointNameDisplay));
          marker.isStart = true;
        });
        buttonPointArrivee.addEventListener("click", function () {
          buttonPointArrivee.classList.add('btn-success');
          buttonPointDepart.classList.remove('btn-primary');
          buttonPointDepart.classList.add('btn-secondary');
          marker.setIcon(markerFactory.createStopIcon());
          var pointNameDisplay = markerFactory.escapeQuotes(marker.pointName);
          marker.bindTooltip(markerFactory.createTooltipMsg(false, pointNameDisplay));
          marker.isStart = false;
        });
      };

      marker.on("popupopen", clickPopup);
      return marker;
    }
  };

  exports.default = markerFactory;
});