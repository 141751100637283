define('sags-front-bo/routes/white-list-bis/auto-mode', ['exports', 'sags-front-bo/mixins/access-controller', 'sags-front-bo/utils/dictionary'], function (exports, _accessController, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    model: function model() {
      var _this = this;

      return _dictionary.default.fetchSettingsForWhiteListBis(this.get('ajax'), this.get('session'), this.get('currentUser')).then(function (settings) {
        return Ember.RSVP.hash({
          autoAddApp: settings.autoAddApp,
          autoAddRAPO: settings.autoAddRAPO
        });
      }).then(function (data) {
        _this.set('globalErrorMessage', null);
        return data;
      }).catch(function (reason) {
        return _this.set('globalErrorMessage', reason);
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // FIXME Voir pourquoi la ligne suivante a été écrite
      // var model = this.get('controller.model');
      var selectedModes = this.controller.get('selectedModes');
      Ember.set(selectedModes, Object.keys(model).get(0), model.autoAddApp);
      Ember.set(selectedModes, Object.keys(model).get(1), model.autoAddRAPO);
    }
  });
});