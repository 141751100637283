define('sags-front-bo/controllers/controls/index', ['exports', 'moment', 'sags-front-bo/utils/dictionary', 'sags-front-bo/config/environment', 'lodash'], function (exports, _moment, _dictionary, _environment, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    tableColumns: [{
      'key': 'datetime',
      'displayName': 'Date du contrôle',
      transform: function transform(date) {
        if (_lodash.default.isNil(date)) {
          return '';
        }
        return (0, _moment.default)(date).format('DD/MM/YYYY HH:mm:ss');
      }
    }, {
      'key': 'parkingStatus',
      'displayName': 'Statut du stationnement',
      transform: function transform(statusId) {
        return _dictionary.default.parkingStatus[statusId];
      }
    }, {
      'key': 'lastTicket.endDate',
      'displayName': 'Date de fin stationnement',
      transform: function transform(date) {
        if (_lodash.default.isNil(date)) {
          return '...';
        }

        return (0, _moment.default)(date).format('DD/MM/YYYY HH:mm:ss');
      }
    }, {
      'key': 'vehiclePlate',
      'displayName': 'Immatriculation'
    }, {
      'key': 'address',
      'displayName': 'N° et voie'
    }, {
      'key': 'tariffZone',
      'displayName': 'Zone de stationnement',
      'customComponent': 'tariff-zone-element-control'
    }, {
      'key': 'status',
      'displayName': 'Statut',
      'customComponent': 'open-control-pictures'
    }, {
      'key': 'reason',
      'displayName': 'Motif',
      'customComponent': 'control-reason-element'
    }, {
      'key': 'agent.identification',
      'displayName': 'Matricule'
    }, {
      'key': 'fps.legalId',
      'displayName': 'Référence du FPS',
      'customComponent': 'link-to-detail',
      'sortable': false
    }],

    filters: {
      parkingStatus: {
        comparator: 'equals',
        value: '*'
      },
      vehiclePlate: {
        comparator: 'contains',
        value: ''
      },
      tariffZone: {
        comparator: 'equals',
        value: '*'
      },
      agent_identification: {
        comparator: 'contains',
        value: ''
      },
      address: {
        comparator: 'contains',
        value: ''
      }

    },

    serverSideFilters: {
      startDatetime: {
        comparator: 'dateEquals',
        value: new Date()
      },
      endDatetime: {
        comparator: 'dateEquals',
        value: new Date()
      }
    },

    possibleParkingStatuses: _dictionary.default.convertToArray(_dictionary.default.parkingStatus, [{ key: '*', value: 'Tous' }]),

    linkComponentConfig: {
      targetRoute: 'fps.detail',
      targetItem: 'fps.externalId'
    },

    refreshWithServerSideFilter: function refreshWithServerSideFilter(controller, store, typeOfdate) {

      var startDatetime = controller.get('serverSideFilters.startDatetime.value');
      var endDatetime = new Date(startDatetime);
      if (_lodash.default.isNil(startDatetime) || _lodash.default.isNil(endDatetime)) {

        var today = new Date();
        // Remplir le champ "Date du contrôle" avec la date du jour
        if (_lodash.default.isNil(startDatetime)) {
          today.setHours(0, 0, 0, 0);
          controller.set('serverSideFilters.startDatetime.value', today);
        } else {
          today.setHours(10, 0, 0, 999);
          controller.set('serverSideFilters.endDatetime.value', today);
        }
      } else {

        if (typeOfdate === "startDate") {
          endDatetime.setHours(startDatetime.getHours() + 2, startDatetime.getMinutes(), startDatetime.getSeconds());
          $("#control-end-date-search").datetimepicker("date", endDatetime);
        }
        endDatetime.setSeconds(60);
        startDatetime.setSeconds(0);
        controller.set('content', store.query('control', {
          startDatetime: startDatetime.toLocaleString("fr-FR"),
          endDatetime: endDatetime.toLocaleString("fr-FR")
        }));
      }
    },

    actions: {
      setStartDatetime: function setStartDatetime() {
        this.refreshWithServerSideFilter(this, this.store, "startDate");
      },
      setEndDatetime: function setEndDatetime() {
        this.refreshWithServerSideFilter(this, this.store, "endDate");
      },
      openPictures: function openPictures(item) {
        var _this = this;

        this.set('isControlPhotosVisible', true);

        var url = _environment.default.APP.host + '/control/api/controls/' + item.get('id') + '/pictures';
        this.get('ajax').request(url, {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          },
          contentType: 'application/json'
        }).then(function (controlPictures) {
          _this.set('controlPictures', controlPictures);
          if (!_lodash.default.isEmpty(controlPictures)) {
            _this.set('mainControlPicture', controlPictures[0]);
          }
        });
      },
      closeControlPicturesModal: function closeControlPicturesModal() {
        this.set('isControlPhotosVisible', false);
        this.set('controlPictures', undefined);
        this.set('mainControlPicture', undefined);
      },
      switchMainImage: function switchMainImage(controlPicture) {
        this.set('mainControlPicture', controlPicture);
      }
    }
  });
});