define('sags-front-bo/routes/teams/planning', ['exports', 'sags-front-bo/mixins/access-controller'], function (exports, _accessController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    currentUser: Ember.inject.service(),

    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        teams: this.get('currentUser.user.role') === 'SUPERVISOR' || this.get('currentUser.user.role') === 'SUPERVISOR_EQPT' ? this.store.query('team', { supervisorId: this.get('currentUser.user.id') }) : this.store.findAll('team'),
        team: null,
        agents: null,
        agent: null
      }).then(function (data) {
        _this.set('globalErrorMessage', null);
        return data;
      }).catch(function (reason) {
        return _this.set('globalErrorMessage', reason);
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      // Si on vient de l'édition d'un agent
      if (controller.get('agentEdit')) {
        model.agent = controller.get('agentEdit');
        controller.set('isOk', true);
        controller.set('isAgentLoaded', true);
      }
    },
    resetController: function resetController(controller) {
      controller.set('agentEdit', null);
      controller.set('isPlanningAgentOnly', false);
    }
  });
});