define('sags-front-bo/models/control', ['exports', 'ember-data', 'sags-front-bo/utils/dictionary', 'lodash'], function (exports, _emberData, _dictionary, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    datetime: _emberData.default.attr('date'),
    parkingStatus: _emberData.default.attr('string'),
    parkingTickets: _emberData.default.hasMany('parking-ticket'),
    vehiclePlate: _emberData.default.attr('string'),
    latitude: _emberData.default.attr('number'),
    longitude: _emberData.default.attr('number'),
    streetNumber: _emberData.default.attr('string'),
    streetNumberBis: _emberData.default.attr('string'),
    streetType: _emberData.default.attr('string'),
    streetName: _emberData.default.attr('string'),
    zipCode: _emberData.default.attr('number'),
    city: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    tariffZone: _emberData.default.attr('string'),
    residentialSector: _emberData.default.attr('string'),
    pmr: _emberData.default.attr('string'),
    agent: _emberData.default.attr(),
    fps: _emberData.default.belongsTo('fps'),
    status: _emberData.default.attr('string'),
    reason: _emberData.default.attr('string'),
    // renseigné uniquement sur la liste des contrôles
    hasPhotos: _emberData.default.attr('boolean'),
    vehicleBrand: _emberData.default.attr('string'),
    vehiculeType: _emberData.default.attr('string'),
    speed: _emberData.default.attr('speed'),

    lastTicket: Ember.computed('parkingTickets.@each.endDate', function () {
      var tickets = this.get('parkingTickets');
      var lastTicket = null;
      if (!_lodash.default.isEmpty(tickets)) {
        tickets.forEach(function (ticket) {
          if (ticket.get('endDate') === undefined) {
            lastTicket = undefined; // l'une des dates n'est pas chargée => le dernier ticket est indéfini
          } else if (lastTicket !== undefined) {
            if (lastTicket === null || lastTicket.get('endDate') < ticket.get('endDate')) {
              lastTicket = ticket;
            }
          }
        });
      }

      return lastTicket;
    }),

    address: Ember.computed('streetNumber', 'streetNumberBis', 'streetType', 'streetName', function () {
      var address = '';
      if (!_lodash.default.isNil(this.get('streetNumber'))) {
        address = this.get('streetNumber');
      }
      if (!_lodash.default.isNil(this.get('streetNumberBis'))) {
        address += (_lodash.default.isEmpty(address) ? '' : ' ') + _dictionary.default.streetNumberBis[this.get('streetNumberBis')];
      }
      address += (_lodash.default.isEmpty(address) ? '' : ' ') + _dictionary.default.streetType[this.get('streetType')] + ' ' + this.get('streetName');
      return address;
    })
  });
});