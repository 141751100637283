define('sags-front-bo/controllers/administrator/purge-precontrols', ['exports', 'sags-front-bo/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    notifications: Ember.inject.service(),
    maxDate: new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(23, 59, 0, 0)),

    serverSideFilters: {
      date: {
        value: new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(23, 59, 0, 0))
      }
    },

    refreshWithServerSideFilter: function refreshWithServerSideFilter(controller) {
      var date = controller.get('serverSideFilters.date.value');
      controller.set('serverSideFilters.date.value', date);
    },


    actions: {

      // Mettre a jour la variable date selon la selection dans l'ecran
      setDate: function setDate(date) {
        this.set('date', date);
      },


      // Lancer la requete de purge des precontroles
      purgePrecontrols: function purgePrecontrols() {
        var _this = this;

        if (confirm("Êtes-vous sûr ?")) {
          return this.get('ajax').request(_environment.default.APP.host + '/control/api/administrator/purge-precontrols', {
            method: 'PUT',
            headers: {
              Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
              city: 'Paris',
              dateTime: this.serverSideFilters.date.value.toLocaleString()
            },
            contentType: 'application/json'
          }).then(function () {
            _this.get('notifications').success('Les pré-contrôles ont été purgés avec succès');
          }).catch(function () {
            _this.get('notifications').error('Une erreur est survenue lors de la purge');
          });
        }
      }
    }
  });
});