define('sags-front-bo/helpers/format-date', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatDate = formatDate;
  var helper = Ember.Helper.helper;
  function formatDate(param) {
    var date = param[0];
    var format = param[1];
    return (0, _moment.default)(date).format(format);
  }

  exports.default = helper(formatDate);
});