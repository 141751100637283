define('sags-front-bo/routes/devices/index', ['exports', 'sweetalert', 'sags-front-bo/config/environment', 'sags-front-bo/mixins/access-controller', 'lodash'], function (exports, _sweetalert, _environment, _accessController, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    notifications: Ember.inject.service('notifications'),
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),

    model: function model() {
      return this.store.findAll('device');
    },

    actions: {
      deleteDevice: function deleteDevice(device) {
        var _this = this;

        (0, _sweetalert.default)({
          title: 'Confirmation',
          text: 'Voulez-vous vraiment supprimer l\'\xE9quipement "' + device.get('imei') + '" ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmer',
          cancelButtonText: 'Annuler',
          target: '#app-container'
        }).then(function () {
          Ember.run(function () {
            device.destroyRecord().then(function () {
              _this.get('notifications').success('L\'équipement a été supprimé avec succès');
            }).catch(function (error) {
              device.rollbackAttributes();

              if (!_lodash.default.isEmpty(error.errors) && error.errors[0].detail === 'Device assigned to a car') {
                _this.get('notifications').error('L\'équipement n\'a pas pu être supprimé car il est associé à un véhicule');
              } else {
                _this.get('notifications').error('L\'équipement n\'a pas pu être supprimé');
              }
            });
          });
        }).catch(function () {/* aucun traitement, mais on fait disparaître l'erreur dans la console */});
      },
      lockState: function lockState(device) {
        var _this2 = this;

        if (!_lodash.default.isNil(device)) {
          device.set('state', 'BLOCKED');
          device.save().then(function () {
            _this2.get('notifications').success('L\'équipement a été bloqué avec succès');
          }).catch(function () {
            device.rollbackAttributes();
            _this2.get('notifications').error('L\'équipement n\'a pas pu être bloqué');
          });
        }
      },
      unLockState: function unLockState(device) {
        var _this3 = this;

        if (!_lodash.default.isNil(device)) {
          device.set('state', 'WORKING');
          device.save().then(function () {
            _this3.get('notifications').success('L\'équipement a été débloqué avec succès');
          }).catch(function () {
            device.rollbackAttributes();
            _this3.get('notifications').error('L\'équipement n\'a pas pu être débloqué');
          });
        }
      },
      disconnect: function disconnect(device) {
        var _this4 = this;

        if (!_lodash.default.isNil(device)) {
          device.set('assignedToId.fullName', null);
          this.get('ajax').request(_environment.default.APP.host + '/api/disconnect/ + ' + device.get('assignedToId.id'), {
            method: 'PUT',
            headers: {
              Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
              City: this.get('currentUser.city')
            }
          }).then(function () {
            _this4.get('notifications').success('L\'équipement a été libéré avec succès');
          }).catch(function () {
            device.rollbackAttributes();
            _this4.get('notifications').error('L\'équipement n\'a pas pu être libéré');
          });
        }
      }
    }
  });
});