define('sags-front-bo/utils/coordinates-mapper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var coordinatesMapper = {
    // inverser la latitude et longitude d'une liste de coordonnées
    // Exemple: from coordinates: [[6.237275,46.198963]]  to coordinates: [[46.198963, 6.237275]]
    switchLatitudeLongitudeOfPolygones: function switchLatitudeLongitudeOfPolygones(allCoordinates) {
      return allCoordinates.map(function (coordinates) {
        return coordinates.map(function (coordinate) {
          return [coordinate[1], coordinate[0]];
        });
      });
    },

    switchLatitudeLongitudeOfPoints: function switchLatitudeLongitudeOfPoints(allPoints) {
      return allPoints.map(function (point) {
        return [point[1], point[0]];
      });
    },

    switchLatitudeLongitudeOfPolygonesOfSectors: function switchLatitudeLongitudeOfPolygonesOfSectors(sectors) {
      var mappedSectors = [];
      sectors.forEach(function (sector) {
        var sectorCoordinates = sector.get('coordinates').map(function (coordinate) {
          return [coordinate[1], coordinate[0]];
        });
        mappedSectors.push(Ember.Object.create({
          name: sector.get('name'),
          startZone: sector.get('startZone'),
          startName: sector.get('startName'),
          stopZone: sector.get('stopZone'),
          stopName: sector.get('stopName'),
          coordinates: sectorCoordinates
        }));
      });
      return mappedSectors;
    }
  };

  exports.default = coordinatesMapper;
});