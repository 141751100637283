define('sags-front-bo/controllers/controls/white-list-edition', ['exports', 'sags-front-bo/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var table = [];

  var filename = "";

  exports.default = Ember.Controller.extend({

    currentUser: Ember.inject.service('current-user'),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),

    // Liste des variables
    filters: {
      number: {
        comparator: 'contains',
        value: ''
      }
    },
    exporter: {
      text: {
        comparator: 'contains',
        value: ''
      }
    },
    verification: {
      peutTraiter: {
        comparator: 'equals',
        value: true
      }
    },

    /*
     * Méthode pour convertir notre contenu qui est un string en un fichier xlsx
     */
    exportToXlsx: function exportToXlsx() {
      var resultatFichier = "vehicle_plate\r\n";
      var result = this.get('exporter.text.value');
      for (var pos = 0; pos < result.length; pos++) {
        resultatFichier += result[pos] + "\r\n";
      }
      resultatFichier = "data:application/xlsx," + encodeURIComponent(resultatFichier);
      var x = document.createElement("A");
      x.setAttribute("href", resultatFichier);
      x.setAttribute("download", "white_list_" + this.get('currentUser.city') + ".xlsx");
      document.body.appendChild(x);
      x.click();
    },


    /*
     * Méthode pour vérifier que notre contenu respecte les régles (interdiction d'avoir un tiret)
     */
    verificationImport: function verificationImport() {
      for (var i = 0; i < table.length; i++) {
        if (table[i].includes("-")) {
          this.set('verification.peutTraiter.value', false);
        }
      }
    },


    actions: {
      Importer: function Importer() {
        var _this = this;

        this.set('isLoadingImport', true);
        this.verificationImport();
        if (this.get('verification.peutTraiter.value')) {
          this.get('notifications').error("Cette operation peut mettre du temps (en fonction du nombre d'éléments présent dans votre liste)");
          setTimeout(function () {
            _this.set('isLoadingImport', false);
          }, 168 * table.length);
          this.get('ajax').request(_environment.default.APP.host + '/control/api/white-lists-import', { // lien vers la requete d'importation
            method: 'POST',
            headers: {
              Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
              City: this.get('currentUser.city')
            },
            data: {
              tabletraitement: table
            },
            contentType: 'application/json'
          }).then(function () {
            _this.get('notifications').success("L'importation a bien été effectué avec le fichier " + filename);
            _this.set('isLoadingImport', false);
          });
        } else {
          this.set('verification.peutTraiter.value', true);
          this.get('notifications').error("Problèmes dans la manière de rédiger votre fichier, vérifier qu'il n'y ait pas de tiret(s) ou que le fichier a bien été sélectionné");
          this.set('isLoadingImport', false);
        }
      },
      Exporter: function Exporter() {
        var _this2 = this;

        this.set('isLoadingImport', true);
        this.get('ajax').request(_environment.default.APP.host + '/control/api/white-lists-export', { // lien vers la requete d'exportation
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          },
          contentType: 'application/json'
        }).then(function (rep) {
          _this2.set('exporter.text.value', rep);
          _this2.exportToXlsx();
          _this2.get('notifications').success("L'exportation a bien été effectué dans vos téléchargement sous le nom de White_List-data");
          _this2.set('isLoadingImport', false);
        });
      },
      ChoixFichier: function ChoixFichier() {

        document.getElementById('fichier').addEventListener('change', function () {
          var _this3 = this;

          var fichier = new FileReader();
          fichier.onload = function () {
            _this3.arrayBuffer = fichier.result;
            var data = new Uint8Array(_this3.arrayBuffer);
            var arr = new Array();

            for (var i = 0; i !== data.length; i++) {
              arr[i] = String.fromCharCode(data[i]);
            }

            var bstr = arr.join('');
            // eslint-disable-next-line no-undef
            var workbook = XLSX.read(bstr, {
              type: 'binary',
              cellDates: true
            });
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            // eslint-disable-next-line no-undef
            _this3.worksheet = XLSX.utils.sheet_to_json(worksheet, {
              raw: true
            });
            var monTab = [];
            for (var _i = 0; _i < _this3.worksheet.length; _i++) {

              var value = Object.values(_this3.worksheet[_i]);
              monTab.push(value[0]);
            }
            table = monTab;
          };
          fichier.readAsArrayBuffer(this.files[0]);

          filename = this.files[0].name;
        });
      }
    }
  });
});