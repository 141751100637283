define('sags-front-bo/controllers/controls/detailed-activities', ['exports', 'sags-front-bo/config/environment', 'moment', 'sags-front-bo/utils/dictionary'], function (exports, _environment, _moment, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var DATE_FORMAT = { year: 'numeric', month: '2-digit', day: '2-digit' };
  var NB_PREVIOUS_DAY = 300;
  var CONTROLS_TYPES_AGENT = ['PMR', '2RM', 'VL (dont épi/bataille)', 'Autocar', 'Poids Lourd'];
  var CONTROLS_TYPES_AGENTBO = ['FPS', 'NON FPS non technique', 'NON FPS technique (image inexploitable)', 'NON FPS technique (hors bande payante'];
  var CONTROLS_TYPES_LAPI = [];
  var TYPE_OFFICER = {
    Agent: 'Agent à pied',
    LAPI: 'LAPI',
    AgentBO: 'Agent back-office',
    Car: 'Voiture'
  };

  var MODE = {
    AgentZoneA: 0,
    AgentZoneB: 1,
    AgentZoneC: 2,
    LapiZoneA: 3,
    LapiZoneB: 4,
    LapiZoneC: 5,
    VaoZoneA: 6,
    VaoZoneB: 7,
    VaoZoneC: 8,
    CarZoneA: 9,
    CarZoneB: 10,
    CarZoneC: 11
  };

  var maxDate = new Date();
  var todayDate = new Date();
  var minDate = (0, _moment.default)(maxDate).subtract(NB_PREVIOUS_DAY, 'days').toDate().toLocaleDateString('en', DATE_FORMAT);
  maxDate = maxDate.toLocaleDateString('en', DATE_FORMAT);
  todayDate = todayDate.toLocaleDateString('fr', DATE_FORMAT);

  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    typeOfficer: [TYPE_OFFICER.Agent, TYPE_OFFICER.LAPI, TYPE_OFFICER.AgentBO, TYPE_OFFICER.Car],
    currentTypeOfficer: '',
    firstDate: minDate,
    secondDate: maxDate,
    selectedDate: todayDate,
    nbStatsItems: '',
    aggregatedSection: [],
    selectedAggregatedSection: [],
    districts: [],
    selectedDistricts: [],
    agents: [],
    completeAgentsList: [], // Complete list of agents
    selectedAgents: [],
    timeslot: [], // List of timeslot to show in the component
    completeTimeslot: [], // List of timeslot to use if selectedTimeslot is empty
    selectedTimeslot: [], // List of selected timeslots
    allTimeslotSelected: true,
    controlType: [], // List of types of controls
    selectedControlType: [], // List of selected types of controls
    dataTableAgentZoneA: [],
    dataTableAgentZoneB: [],
    dataTableAgentZoneC: [],
    dataTableAgentZoneABSizeIndex0: '', // Size of selectedControlType minus 1, usefull for table formating
    dataTableAgentZoneABColspan: '', // Size of selectedDistricts plus 1, usefull for table formating
    dataTableLapiZoneA: [],
    dataTableLapiZoneB: [],
    dataTableLapiZoneC: [],
    dataTableLapiZoneABColspanInvalid: '', // Fixed size of first row header Invalid that contains "Reject rate column", usefull for table formating
    dataTableLapiZoneABColspan: '', // Fixed size of first row header that does not contains "Reject rate column", usefull for table formating
    dataTableVaoZoneA: [],
    dataTableVaoZoneB: [],
    dataTableVaoZoneC: [],
    dataTableAgentVaoABColspan: '',
    dataTableCarZoneA: [],
    dataTableCarZoneB: [],
    dataTableCarZoneC: [],
    isLoadingImport: false,
    updateDisabled: true,

    initRequest: function () {
      this.getNumberOfStatisticsItems(this);
      this.getAggregatedSection(this);
      this.getDistricts(this);
    }.on('init'),

    getNumberOfStatisticsItems: function getNumberOfStatisticsItems(that) {
      return that.get('ajax').request(_environment.default.APP.host + '/control/api/statistics/stats', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + that.get('session.session.authenticated.id_token'),
          City: that.get('currentUser.city'),
          startDatetime: that.selectedDate
        },
        contentType: 'application/json'
      }).then(function (data) {
        that.set('nbStatsItems', data.toString());
      });
    },
    getAggregatedSection: function getAggregatedSection(that) {
      return that.get('ajax').request(_environment.default.APP.host + '/gis/api/sectors', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + that.get('session.session.authenticated.id_token'),
          City: that.get('currentUser.city')
        },
        contentType: 'application/json'
      }).then(function (data) {
        var size = Object.keys(data).length;
        var array = [];
        for (var i = 0; i < size; i++) {
          array.push(data[i].name);
        }
        that.set('aggregatedSection', [].concat(_toConsumableArray(new Set(array.sort(function (a, b) {
          return a - b;
        })))));
      });
    },
    getDistricts: function getDistricts(that) {
      return that.get('ajax').request(_environment.default.APP.host + '/gis/api/sectors/sectors-zipcodes', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + that.get('session.session.authenticated.id_token'),
          City: that.get('currentUser.city'),
          sectorsList: that.get('selectedAggregatedSection')
        },
        contentType: 'application/json'
      }).then(function (data) {
        var size = Object.keys(data).length;
        var array = [];
        for (var i = 0; i < size; i++) {
          array.push(data[i] - 75000);
        }
        that.set('districts', array);
      });
    },
    getAgentsBySectorAndType: function getAgentsBySectorAndType(that) {
      if (that.currentTypeOfficer.length !== 0) {
        if (that.currentTypeOfficer === TYPE_OFFICER.Agent || that.currentTypeOfficer === TYPE_OFFICER.LAPI) {
          return that.get('ajax').request(_environment.default.APP.host + '/gis/api/agentsBySectors', {
            method: 'GET',
            headers: {
              Authorization: 'Bearer ' + that.get('session.session.authenticated.id_token'),
              City: that.get('currentUser.city'),
              sectorsList: that.selectedAggregatedSection,
              typeAgent: that.getCorrectedTypeAgent(that.currentTypeOfficer)
            },
            contentType: 'application/json'
          }).then(function (data) {
            var size = Object.keys(data).length;
            var array = [];
            for (var i = 0; i < size; i++) {
              array.push(data[i]);
            }
            // TODO: Redmine #187587
            // Début du block à retirer
            if (that.currentTypeOfficer === TYPE_OFFICER.LAPI) {
              array.push("atol");
            }
            // Fin du block à retirer

            that.set('agents', array);
            that.set('completeAgentsList', array);
          });
        } else if (that.currentTypeOfficer === TYPE_OFFICER.AgentBO) {
          return that.get('ajax').request(_environment.default.APP.host + '/api/user', {
            method: 'GET',
            headers: {
              Authorization: 'Bearer ' + that.get('session.session.authenticated.id_token'),
              City: that.get('currentUser.city'),
              authorities: ["ROLE_ADMINISTRATOR", "ROLE_TERRITORY_MANAGER", "ROLE_SUPERVISOR", "ROLE_AGENT_BO", "ROLE_AGENT_VAO", "ROLE_VERIF_VAO", "ROLE_TERRITORY_MANAGER_EQPT", "ROLE_SUPERVISOR_EQPT"]
            },
            contentType: 'application/json'
          }).then(function (data) {
            var size = Object.keys(data).length;
            var array = [];
            for (var i = 0; i < size; i++) {
              array.push(data[i]);
            }

            that.set('agents', array);
            that.set('completeAgentsList', array);
          });
        } else if (that.currentTypeOfficer === TYPE_OFFICER.Car) {
          return that.get('ajax').request(_environment.default.APP.host + '/api/cars', {
            method: 'GET',
            headers: {
              Authorization: 'Bearer ' + that.get('session.session.authenticated.id_token'),
              City: that.get('currentUser.city')
            },
            contentType: 'application/json'
          }).then(function (data) {
            var size = Object.keys(data).length;
            var array = [];
            for (var i = 0; i < size; i++) {
              array.push(data[i].technicalId);
            }

            that.set('agents', [].concat(_toConsumableArray(new Set(array.sort(function (a, b) {
              return a - b;
            })))));
            that.set('completeAgentsList', [].concat(_toConsumableArray(new Set(array.sort(function (a, b) {
              return a - b;
            })))));
          });
        }
      }
    },


    // Return the list of timeslot for the given type agent
    getTimeslots: function getTimeslots(that) {
      return that.get('ajax').request(_environment.default.APP.host + '/control/api/timeslots', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + that.get('session.session.authenticated.id_token'),
          City: that.get('currentUser.city'),
          typeAgent: that.getCorrectedTypeAgent(that.currentTypeOfficer)
        },
        contentType: 'application/json'
      }).then(function (data) {
        var size = Object.keys(data).length;
        var array = [];
        for (var i = 0; i < size; i++) {
          array.push(data[i]);
        }
        that.set('timeslot', array);
        that.set('completeTimeslot', array);
      });
    },
    getCorrectedTypeAgent: function getCorrectedTypeAgent(type) {
      var correctedType = void 0;

      if (type === TYPE_OFFICER.Agent) {
        correctedType = "ROLE_AGENT";
      } else if (type === TYPE_OFFICER.AgentBO) {
        correctedType = "ROLE_AGENT_VAO";
      } else if (type === TYPE_OFFICER.LAPI || type === TYPE_OFFICER.Car) {
        correctedType = "ROLE_LAPI";
      }

      return correctedType;
    },
    getCorrectedSelectionTypes: function getCorrectedSelectionTypes(controlsType) {
      var list = [];

      for (var i = 0; i < controlsType.length; i++) {
        list.push(_dictionary.default.selectionTypes[controlsType[i]]);
      }

      return list;
    },


    // Set the controlType array definition according to the selected type
    updateControlListByType: function updateControlListByType(that, type) {
      if (type === TYPE_OFFICER.Agent) {
        that.set('controlType', CONTROLS_TYPES_AGENT);
      } else if (type === TYPE_OFFICER.AgentBO) {
        that.set('controlType', CONTROLS_TYPES_AGENTBO);
      } else if (type === TYPE_OFFICER.LAPI || type === TYPE_OFFICER.Car) {
        that.set('controlType', CONTROLS_TYPES_LAPI);
      } else {
        that.set('controlType', []);
      }
    },


    // Return the data according to the selected type
    getDetailedActivityData: function getDetailedActivityData(that, zone) {
      that.set('isLoadingImport', true);

      return that.get('ajax').request(_environment.default.APP.host + '/control/api/detailedActivity/data', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + that.get('session.session.authenticated.id_token'),
          City: that.get('currentUser.city'),
          startDatetime: that.selectedDate,
          listAgentName: that.selectedAgents.length !== 0 ? that.selectedAgents : that.completeAgentsList,
          listTimeslot: that.selectedTimeslot.length !== 0 ? that.selectedTimeslot : that.completeTimeslot,
          selectionTypeList: that.selectedControlType.length !== 0 ? that.getCorrectedSelectionTypes(that.selectedControlType) : that.getCorrectedSelectionTypes(that.controlType),
          districts: that.selectedDistricts.length !== 0 ? that.selectedDistricts : that.districts,
          mode: zone
        },
        contentType: 'application/json'
      }).then(function (data) {
        if (zone === MODE.AgentZoneA || zone === MODE.AgentZoneB) {
          that.set('dataTableAgentZoneABSizeIndex0', (data[0].length - 1).toString());
          that.set('dataTableAgentZoneABColspan', that.selectedDistricts.length !== 0 ? (that.selectedDistricts.length + 1).toString() : (that.districts.length + 1).toString());
        } else if (zone === MODE.LapiZoneA || zone === MODE.LapiZoneB || zone === MODE.CarZoneA || zone === MODE.CarZoneB) {
          that.set('dataTableLapiZoneABColspan', that.selectedDistricts.length !== 0 ? (that.selectedDistricts.length + 2).toString() : (that.districts.length + 2).toString());
          that.set('dataTableLapiZoneABColspanInvalid', that.selectedDistricts.length !== 0 ? (that.selectedDistricts.length + 3).toString() : (that.districts.length + 3).toString());
        } else if (zone === MODE.VaoZoneA || zone === MODE.VaoZoneB) {
          that.set('dataTableAgentVaoABColspan', that.selectedControlType.length !== 0 ? (that.selectedControlType.length * 2).toString() : 8);
        }

        switch (zone) {
          case MODE.AgentZoneA:
            that.set('dataTableAgentZoneA', data);
            break;
          case MODE.AgentZoneB:
            that.set('dataTableAgentZoneB', data);
            break;
          case MODE.AgentZoneC:
            that.set('dataTableAgentZoneC', data);
            break;
          case MODE.LapiZoneA:
            that.set('dataTableLapiZoneA', data);
            break;
          case MODE.LapiZoneB:
            that.set('dataTableLapiZoneB', data);
            break;
          case MODE.LapiZoneC:
            that.set('dataTableLapiZoneC', data);
            break;
          case MODE.VaoZoneA:
            that.set('dataTableVaoZoneA', data);
            break;
          case MODE.VaoZoneB:
            that.set('dataTableVaoZoneB', data);
            break;
          case MODE.VaoZoneC:
            that.set('dataTableVaoZoneC', data);
            break;
          case MODE.CarZoneA:
            that.set('dataTableCarZoneA', data);
            break;
          case MODE.CarZoneB:
            that.set('dataTableCarZoneB', data);
            break;
          case MODE.CarZoneC:
            that.set('dataTableCarZoneC', data);
            break;
        }
      }).finally(function () {
        that.set('isLoadingImport', false);
      });
    },
    prepareCsv: function prepareCsv(that, data, filename, index) {
      var csvFile = [];
      // Récupération des 'stringValue' seulement
      for (var i = 0; i < data.length; i++) {
        var line = [];
        for (var j = 0; j < data[i].length; j++) {
          line.push(data[i][j].stringValue);
        }
        csvFile.push(line);
      }

      // Correction selon tableaux et décalages
      if (index === MODE.AgentZoneA || index === MODE.AgentZoneB || index === MODE.LapiZoneA || index === MODE.LapiZoneB || index === MODE.CarZoneA || index === MODE.CarZoneB) {
        csvFile[1].splice(0, 0, " ");
      } else if (index === MODE.VaoZoneA || index === MODE.VaoZoneB) {
        for (var _i = 0; _i < 6; _i++) {
          csvFile[1].splice(0, 0, " ");
        }
      }

      // Dernière mise en forme pour envoie
      csvFile = csvFile.map(function (row) {
        return row.join(';');
      }).join('\n');
      that.downloadCsv(that, csvFile, filename);
    },
    downloadCsv: function downloadCsv(that, csvFile, filename) {
      csvFile = "data:application/csv," + encodeURIComponent(csvFile);
      var x = document.createElement("A");
      x.setAttribute("href", csvFile);
      x.setAttribute("download", that.selectedDate.replace(/\//g, '_') + '_' + filename + ".csv");
      document.body.appendChild(x);
      x.click();
    },


    actions: {
      setTypeOfficer: function setTypeOfficer(officer) {
        this.set('currentTypeOfficer', officer);
        this.get('updateControlListByType')(this, officer);

        if (officer === null) {
          this.set('timeslot', []);
          this.set('agents', []);
          this.set('selectedAgents', []);
          this.set('selectedControlType', []);
          this.set('updateDisabled', true);
        } else {
          this.set('timeslot', []);
          this.set('agents', []);
          this.set('selectedAgents', []);
          this.set('selectedControlType', []);
          this.set('updateDisabled', false);

          this.get('getAgentsBySectorAndType')(this);
          this.get('getTimeslots')(this);

          var districtsList = this.districts;
          districtsList = districtsList.filter(function (item) {
            return item !== 0;
          });

          if (officer === TYPE_OFFICER.LAPI || officer === TYPE_OFFICER.Car) {
            districtsList.unshift(0);
          }

          this.set('districts', districtsList);
        }
      },
      setDate: function setDate(date) {
        this.selectedDate = date.toLocaleDateString();
        this.get('getNumberOfStatisticsItems')(this);
      },
      setAggregatedSection: function setAggregatedSection(section) {
        var oldSection = this.selectedAggregatedSection;

        if (section.length === 0 || section.length !== 0 && oldSection !== section) {
          this.set('selectedDistricts', []);
          this.set('selectedAgents', []);
          this.set('selectedControlType', []);
        }

        this.set('selectedAggregatedSection', section);
        this.get('getDistricts')(this);
        this.get('getAgentsBySectorAndType')(this);
      },
      setDistricts: function setDistricts(districts) {
        this.set('selectedDistricts', districts)(this);
      },
      setSelectedAgents: function setSelectedAgents(agents) {
        this.set('selectedAgents', agents)(this);
      },
      setSelectedTimeslot: function setSelectedTimeslot(timeslot) {
        this.set('selectedTimeslot', timeslot)(this);
      },
      setSelectedControlByTypeAgent: function setSelectedControlByTypeAgent(controlsType) {
        this.set('selectedControlType', controlsType)(this);
      },
      resetFilters: function resetFilters() {
        this.set('timeslot', []);
        this.set('selectedTimeslot', []);
        this.set('currentTypeOfficer', []);
        this.set('selectedAgents', []);
        this.set('agents', []);
        this.set('selectedControlType', []);
        this.set('controlType', []);
        this.set('selectedDistricts', []);
        this.set('selectedAggregatedSection', []);
        this.set('updateDisabled', true);
      },
      updateTables: function updateTables() {
        if (this.currentTypeOfficer === TYPE_OFFICER['Agent']) {
          this.get('getDetailedActivityData')(this, MODE.AgentZoneA);
          this.get('getDetailedActivityData')(this, MODE.AgentZoneB);
          this.get('getDetailedActivityData')(this, MODE.AgentZoneC);
        } else if (this.currentTypeOfficer === TYPE_OFFICER['LAPI']) {
          this.get('getDetailedActivityData')(this, MODE.LapiZoneA);
          this.get('getDetailedActivityData')(this, MODE.LapiZoneB);
          this.get('getDetailedActivityData')(this, MODE.LapiZoneC);
        } else if (this.currentTypeOfficer === TYPE_OFFICER['AgentBO']) {
          this.get('getDetailedActivityData')(this, MODE.VaoZoneA);
          this.get('getDetailedActivityData')(this, MODE.VaoZoneB);
          this.get('getDetailedActivityData')(this, MODE.VaoZoneC);
        } else {
          this.get('getDetailedActivityData')(this, MODE.CarZoneA);
          this.get('getDetailedActivityData')(this, MODE.CarZoneB);
          this.get('getDetailedActivityData')(this, MODE.CarZoneC);
        }

        if (this.selectedTimeslot.length === this.completeTimeslot.length || this.selectedTimeslot.length === 0) {
          this.set('allTimeslotSelected', true);
        } else {
          this.set('allTimeslotSelected', false);
        }
      },
      exportCsv: function exportCsv(index) {
        switch (index) {
          case MODE.AgentZoneA:
            this.get('prepareCsv')(this, this.dataTableAgentZoneA, 'AgentZoneA', index);
            break;
          case MODE.AgentZoneB:
            this.get('prepareCsv')(this, this.dataTableAgentZoneB, 'AgentZoneB', index);
            break;
          case MODE.AgentZoneC:
            this.get('prepareCsv')(this, this.dataTableAgentZoneC, 'AgentZoneC', index);
            break;
          case MODE.LapiZoneA:
            this.get('prepareCsv')(this, this.dataTableLapiZoneA, 'LapiZoneA', index);
            break;
          case MODE.LapiZoneB:
            this.get('prepareCsv')(this, this.dataTableLapiZoneB, 'LapiZoneB', index);
            break;
          case MODE.LapiZoneC:
            this.get('prepareCsv')(this, this.dataTableLapiZoneC, 'LapiZoneC', index);
            break;
          case MODE.VaoZoneA:
            this.get('prepareCsv')(this, this.dataTableVaoZoneA, 'VaoZoneA', index);
            break;
          case MODE.VaoZoneB:
            this.get('prepareCsv')(this, this.dataTableVaoZoneB, 'VaoZoneB', index);
            break;
          case MODE.VaoZoneC:
            this.get('prepareCsv')(this, this.dataTableVaoZoneC, 'VaoZoneC', index);
            break;
          case MODE.CarZoneA:
            this.get('prepareCsv')(this, this.dataTableCarZoneA, 'CarZoneA', index);
            break;
          case MODE.CarZoneB:
            this.get('prepareCsv')(this, this.dataTableCarZoneB, 'CarZoneB', index);
            break;
          case MODE.CarZoneC:
            this.get('prepareCsv')(this, this.dataTableCarZoneC, 'CarZoneC', index);
            break;
        }
      }
    }
  });
});