define('sags-front-bo/controllers/cars/index', ['exports', 'sags-front-bo/utils/dictionary'], function (exports, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    tableColumns: [{
      'key': 'plate',
      'displayName': 'Immatriculation'
    }, {
      'key': 'brand',
      'displayName': 'Marque'
    }, {
      'key': 'type',
      'displayName': 'Type',
      transform: function transform(typeId) {
        return _dictionary.default.carType[typeId];
      }
    }, {
      'key': 'state',
      'displayName': 'Statut',
      transform: function transform(typeId) {
        return _dictionary.default.carStatus[typeId];
      }
    }, {
      'key': 'assignedToId.imei',
      'displayName': 'Equipement'
    }, {
      'key': 'cities',
      'displayName': 'Villes',
      transform: function transform(cities) {
        return cities.join(', ');
      }
    }],
    filters: {
      plate: {
        comparator: 'contains',
        value: ''
      },
      type: {
        comparator: 'equals',
        value: '*'
      },
      state: {
        comparator: 'equals',
        value: '*'
      },
      brand: {
        comparator: 'contains',
        value: ''
      },
      assignedToId_imei: {
        comparator: 'equals',
        value: '*'
      }
    },

    possibleCarTypes: _dictionary.default.convertToArray(_dictionary.default.carType, [{ key: '*', value: 'Tous' }]),
    possibleCarStatuses: _dictionary.default.convertToArray(_dictionary.default.carStatus, [{ key: '*', value: 'Tous' }]),

    ButtonVisibility: function ButtonVisibility(one, elementId, currentUser) {
      var currentUserRole = currentUser.user.data.role;
      one.set('isIcon0Visible_' + elementId, Ember.computed('state', function () {
        return one.get('state') === 'WORKING' && currentUserRole !== 'TERRITORY_MANAGER';
      }));

      one.set('isIcon1Visible_' + elementId, Ember.computed('state', function () {
        return one.get('state') === 'REPAIRING' && currentUserRole !== 'TERRITORY_MANAGER';
      }));

      one.set('isIcon2Visible_' + elementId, Ember.computed('state', function () {
        return currentUserRole !== 'TERRITORY_MANAGER';
      }));
    },


    actionIcons: [{ icon: 'wrench', tooltip: 'A réparer' }, { icon: 'cogs', tooltip: 'En service' }, { icon: 'trash', tooltip: 'Supprimer' }],

    assignedDevices: Ember.computed('model.devices.@each', function () {
      var listOfDevices = this.get('model.devices').map(function (device) {
        return { key: device.get('imei'), value: device.get('imei') };
      });
      listOfDevices.unshiftObject({ key: '*', value: 'Tous' });
      return listOfDevices;
    })
  });
});