define('sags-front-bo/models/aggregated-section', ['exports', 'ember-data', 'lodash'], function (exports, _emberData, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    publicId: _emberData.default.attr('number'),
    agentScore: _emberData.default.attr('number'),
    lapiScore: _emberData.default.attr('number'),
    numberOfPlaces: _emberData.default.attr('number'),
    coordinates: _emberData.default.attr(''),
    tariffZone: _emberData.default.attr('string'),
    residentialSector: _emberData.default.attr('string'),
    parity: _emberData.default.attr('string'),
    roadType: _emberData.default.attr('string'),
    roadName: _emberData.default.attr('string'),
    typeStat: _emberData.default.attr('string'),
    parkingType: _emberData.default.attr('string'),
    zipCode: _emberData.default.attr('string'),
    rules: _emberData.default.hasMany('rule'),
    statReg: _emberData.default.attr('string'),

    address: Ember.computed('roadType', 'roadName', 'zipCode', function () {
      var address = '';
      if (!_lodash.default.isEmpty(this.get('roadType'))) {
        address = this.get('roadType');
      }
      if (!_lodash.default.isEmpty(this.get('roadName'))) {
        address += (_lodash.default.isEmpty(address) ? '' : ' ') + this.get('roadName');
      }
      address += (_lodash.default.isEmpty(address) ? '' : ' ') + this.get('zipCode');

      return address;
    })
  });
});