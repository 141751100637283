define('sags-front-bo/utils/format-delta-time', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function deltaTimeToString(deltaTime) {

    var milliseconds = deltaTime % 1000;
    var seconds = Math.floor(deltaTime / 1000 % 60);
    var minutes = Math.floor(deltaTime / 60000 % 60);
    var hours = Math.floor(deltaTime / 3600000);
    var formattedTime = [];

    if (hours > 0) {
      formattedTime.push(hours + ' h');
    }
    if (minutes > 0) {
      formattedTime.push(minutes + ' min');
    }
    if (seconds > 0) {
      formattedTime.push(seconds + ' sec');
    }
    if (milliseconds > 0) {
      formattedTime.push(milliseconds + ' ms');
    }

    return formattedTime.join(' ');
  }

  exports.deltaTimeToString = deltaTimeToString;
});