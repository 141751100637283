define('sags-front-bo/components/rapo/instruction/global-instruction', ['exports', 'sweetalert', 'lodash', 'sags-front-bo/config/environment', 'sags-front-bo/utils/download-file', 'sags-front-bo/utils/dictionary'], function (exports, _sweetalert, _lodash, _environment, _downloadFile, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('showPreliminaryQuestionsBlock', true);
      this.set('showCompletnessForm', false);

      // Chargement de l'instruction en cours (si existante)
      this.send('getRapoInstructions');
    },

    rapo: Ember.computed.alias('model.rapo'),

    disableEnableInstructionsQuestions: function disableEnableInstructionsQuestions(questions, allInstructions, isInstructionsEditable) {
      if (!_lodash.default.isNil(questions)) {
        questions.forEach(function (question) {
          // Désactiver la question sauf la dernière pour pouvoir y répondre
          Ember.set(question, 'disabled', true);
          if (isInstructionsEditable && question.id === allInstructions[allInstructions.length - 1].id) {
            Ember.set(question, 'disabled', false);
          }
        });
      }
    },


    oldInstructionsWorkflow: Ember.computed('rapo.instructionResponses.[]', function () {
      return !_lodash.default.isEmpty(this.get('rapo.instructionResponses'));
    }),

    enableMailLetter: Ember.computed('enableAcceptRapo', 'enableRejectRapo', function () {
      return this.get('enableAcceptRapo') || this.get('enableRejectRapo');
    }),

    resetDecisionInstructionFieldsObserver: Ember.observer('resetDecisionInstructionFields', function () {
      // On cache forcément les informations de la décision finale
      this.set('enableAcceptRapo', false);
      this.set('enableRejectRapo', false);
      this.set('rapo.decisionArgument', null);
      this.set('missingAttachmentsForInstruction', []);
    }),

    preliminaryQuestions: Ember.computed('instructions.[]', 'isInstructionsEditable', function () {
      var allInstructions = this.get('instructions');
      var endOfPqIndex = _lodash.default.findIndex(allInstructions, function (instruction) {
        return instruction.endOfPq;
      });
      var questions = endOfPqIndex !== -1 ? allInstructions.slice(0, endOfPqIndex) : allInstructions;

      this.get('disableEnableInstructionsQuestions')(questions, allInstructions, this.get('isInstructionsEditable'));

      return questions;
    }),

    otherQuestions: Ember.computed('instructions.[]', 'isInstructionsEditable', function () {
      var allInstructions = this.get('instructions');
      var endOfPqIndex = _lodash.default.findIndex(allInstructions, function (instruction) {
        return instruction.endOfPq;
      });
      var questions = endOfPqIndex !== -1 ? allInstructions.slice(endOfPqIndex) : [];

      this.get('disableEnableInstructionsQuestions')(questions, allInstructions, this.get('isInstructionsEditable'));

      return questions;
    }),

    showRollbackButton: Ember.computed('instructions.[]', 'isInstructionsEditable', function () {
      return this.get('isInstructionsEditable') && this.get('instructions').length > 1;
    }),

    isResponseFormatEditable: Ember.computed('isInstructionsEditable', 'model.currentAgent.identification', 'rapo.status', function () {
      return this.get('isInstructionsEditable') || this.get('rapo.status') === 'TO_VALIDATE' && this.get('instructedByAgent.identification') !== this.get('model.currentAgent.identification') && this.get('isAgentAuthorized');
    }),

    rapoInstructionInProgress: Ember.computed('rapo.responseFormat', function () {
      return _lodash.default.isNil(this.get('rapo.responseFormat'));
    }),

    // Mettre à jour le RAPO sur le serveur de FPS
    validateRapoOnControlSide: function validateRapoOnControlSide(that) {
      if (that.get('rapo.issue') === 'ACCEPTANCE') {
        // créer un FPS rectificatif en copiant le FPS initial avec un montant = 0 €
        that.set('rapo.correctiveFps', {
          vehiclePlate: that.get('rapo.fullFps.vehiclePlate'),
          vehiclePlateCountry: that.get('rapo.fullFps.vehiclePlateCountry'),
          vehicleBrand: that.get('rapo.fullFps.vehicleBrand'),
          vehicleModel: that.get('rapo.fullFps.vehicleModel'),
          creationDate: that.get('rapo.fullFps.creationDate'),
          validityDate: that.get('rapo.fullFps.validityDate'),
          amount: 0,
          deductionTotal: 0,
          status: 'SENT',
          significantParkingTickets: that.get('rapo.fullFps.significantParkingTickets'),
          tariffZone: that.get('rapo.fullFps.tariffZone'),
          residentialSector: that.get('rapo.fullFps.residentialSector'),
          streetNumber: that.get('rapo.fullFps.streetNumber'),
          streetNumberBis: that.get('rapo.fullFps.streetNumberBis'),
          streetType: that.get('rapo.fullFps.streetType'),
          streetName: that.get('rapo.fullFps.streetName'),
          claimerFirstName: that.get('rapo.claimerFirstName'),
          claimerLastName: that.get('rapo.claimerLastName'),
          claimerCivility: that.get('rapo.claimerCivility'),
          claimerEmail: that.get('rapo.claimerEmail'),
          claimerStreetNumber: that.get('rapo.claimerStreetNumber'),
          claimerStreetNumberBis: that.get('rapo.claimerStreetNumberBis'),
          claimerStreetType: that.get('rapo.claimerStreetType'),
          claimerStreetName: that.get('rapo.claimerStreetName'),
          claimerAdditionalAddress: that.get('rapo.claimerAdditionalAddress'),
          claimerZipCode: that.get('rapo.claimerZipCode'),
          claimerCity: that.get('rapo.claimerCity'),
          claimerCountry: that.get('rapo.claimerCountry')
        });
      }

      var ajaxRequest = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + that.get('session.session.authenticated.id_token'),
          City: that.get('currentUser.city')
        },
        contentType: 'application/json'
      };

      ajaxRequest.data = {};

      if (!_lodash.default.isNil(that.get('rapo.correctiveFps'))) {
        // joindre les informations du mandataire dans le FPS qui va être créé
        ['mandataryCivility', 'mandataryFirstName', 'mandataryLastName', 'mandataryStreetNumber', 'mandataryStreetNumberBis', 'mandataryStreetType', 'mandataryStreetName', 'mandataryZipCode', 'mandataryCity', 'mandataryCountry'].forEach(function (field) {
          that.set('rapo.correctiveFps.' + field, that.get('rapo.' + field));
        });

        // remplacer les informations de l'agent avec celui qui est connecté
        var indexNumber = undefined;
        that.get('currentUser.user.userCities').forEach(function (userCity) {
          if (userCity.city.name === that.get('currentUser.city')) {
            indexNumber = userCity.indexNumber;
          }
        });

        that.set('rapo.correctiveFps.agent', {
          identification: that.get('currentUser.user.identification'),
          name: that.get('currentUser.user.fullName'),
          authorizedNumber: that.get('currentUser.user.authorizedNumber'),
          indexNumber: indexNumber
        });

        // inclure le FPS rectificatif en corps de requête
        ajaxRequest.contentType = 'application/json';
        ajaxRequest.data = {
          correctiveFps: that.get('rapo.correctiveFps'),
          newBuyerFps: _lodash.default.isEmpty(that.get('rapo.newBuyer')) ? null : {
            civility: that.get('rapo.newBuyer.civility'),
            firstName: that.get('rapo.newBuyer.firstName'),
            lastName: that.get('rapo.newBuyer.lastName'),
            email: that.get('rapo.newBuyer.email'),
            streetNumber: that.get('rapo.newBuyer.streetNumber'),
            streetNumberBis: that.get('rapo.newBuyer.streetNumberBis'),
            streetType: that.get('rapo.newBuyer.streetType'),
            streetName: that.get('rapo.newBuyer.streetName'),
            additionalAddress: that.get('rapo.newBuyer.additionalAddress'),
            zipCode: that.get('rapo.newBuyer.zipCode'),
            city: that.get('rapo.newBuyer.city'),
            country: that.get('rapo.newBuyer.country')
          }
        };
      }

      return that.get('store').queryRecord('rapo', { number: that.get('rapo.number') }).then(function (rapo) {
        if (rapo.get('status') !== 'TO_VALIDATE' || !_lodash.default.isNil(rapo.get('correctiveFps.legalId'))) {
          // le RAPO n'est plus à valider, ou on a déjà créé son FPS rectificatif : on arrête tout.
          throw new Error('RAPO was modified on server side and is no more to validate.');
        }

        return that.get('ajax').request(_environment.default.APP.host + '/control/api/fps/validate-rapo?externalId=' + that.get('rapo.fullFps.externalId') + ('&isAccepted=' + (that.get('rapo.issue') !== 'REJECTION') + '&etag=' + encodeURIComponent(that.get('rapo.fullFps.etag'))), ajaxRequest);
      }).then(function (response) {
        if (!_lodash.default.isNil(that.get('rapo.correctiveFps'))) {
          that.get('notifications').success('Un nouveau FPS rectificatif a \xE9t\xE9 cr\xE9\xE9. Num\xE9ro du FPS : ' + response.correctiveFps.legalId);
          // mettre à jour le FPS rectificatif sur la base RAPO
          ajaxRequest.data = response;
          return that.get('ajax').request(_environment.default.APP.host + '/rapo/api/rapos/' + that.get('rapo.id') + '/treatment-fps', ajaxRequest);
        }

        // si pas de FPS rectificatif, on passe à la suite sans attendre
        return Ember.RSVP.resolve(null);
      }).then(function (rapoTreamentFps) {
        // synchroniser le modèle local avec la base.
        if (!_lodash.default.isNil(that.get('rapo.correctiveFps')) && !_lodash.default.isNil(rapoTreamentFps)) {
          that.set('rapo.correctiveFps', rapoTreamentFps.correctiveFps);
        }
      }).catch(function (error) {
        if (error.message === 'RAPO was modified on server side and is no more to validate.') {
          that.get('notifications').error('Le statut du RAPO a été modifié depuis son chargement, merci de rafraîchir la page.');
        } else {
          that.get('notifications').error('Impossible de valider le RAPO');
        }
        // empêcher l'exécution de la suite de l'instruction RAPO
        throw error;
      });
    },


    actions: {
      // Affiche ou non du bloc contenant les questions préliminaires
      showHidePqBlock: function showHidePqBlock() {
        this.toggleProperty('showPreliminaryQuestionsBlock');
      },
      getRapoInstructions: function getRapoInstructions() {
        var _this = this;

        var workflowProcessId = this.get('rapo.workflowProcessId');

        if (!_lodash.default.isNil(workflowProcessId)) {
          this.set('isLoadingNextInstructionTask', true);

          this.get('ajax').request(_environment.default.APP.host + '/rapo/api/workflow/' + workflowProcessId + '/tasks', {
            headers: {
              Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
              City: this.get('currentUser.city')
            },
            contentType: 'application/json'
          }).then(function (response) {
            _this.set('instructions', response.tasks);
            var rapoDecision = response.rapoDecision;
            if (rapoDecision !== null) {
              if (rapoDecision.completion) {
                _this.set('missingAttachmentsForInstruction', rapoDecision.missingAttachments);
              } else {
                _this.set('enableAcceptRapo', rapoDecision.acceptation);
                if (!_this.get('rapo.status') === 'REINSTRUCTING') {
                  _this.set('enableRejectRapo', !rapoDecision.acceptation);
                }
                if (_this.get('rapo.decisionArgument') === null) {
                  _this.set('rapo.decisionArgument', rapoDecision.argumentation);
                }
                if (_this.get('rapo.code') === null) {
                  _this.set('rapo.code', rapoDecision.code);
                }
              }
            }
          }).catch(function () {
            _this.get('notifications').error('Erreur lors du chargement de l\'instruction du rapo');
          }).finally(function () {
            _this.set('isLoadingNextInstructionTask', false);
          });
        }
      },
      completeInstructionStep: function completeInstructionStep(instruction, instructionFormProperty) {
        var _this2 = this;

        var processInstanceId = this.get('rapo.workflowProcessId');

        var properties = {};
        instruction.formProperties.forEach(function (item) {
          properties[item.id] = false; // Necessaire d'avoir toutes les proprietes pour Activiti
        });
        properties[instructionFormProperty.id] = true;
        properties['isCompleted'] = this.get('rapo.status') === 'REINSTRUCTING' && !_lodash.default.isNil(this.get('rapo.limitCompletionDate'));

        Ember.set(instructionFormProperty, 'value', true);

        this.set('isLoadingNextInstructionTask', true);

        this.get('ajax').request(_environment.default.APP.host + '/rapo/api/workflow/complete-task', {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          },
          data: {
            processInstanceId: processInstanceId,
            variables: properties
          },
          contentType: 'application/json'
        }).then(function (_ref) {
          var nextTask = _ref.nextTask,
              rapoDecision = _ref.rapoDecision;

          if (nextTask) {
            if (nextTask.endOfPq) {
              _this2.set('showPreliminaryQuestionsBlock', false);
            }

            _this2.get('instructions').pushObject(nextTask);
          }

          if (rapoDecision !== null) {
            if (rapoDecision.completion) {
              _this2.set('missingAttachmentsForInstruction', rapoDecision.missingAttachments);
            } else {
              _this2.set('enableAcceptRapo', rapoDecision.acceptation);
              _this2.set('enableRejectRapo', !rapoDecision.acceptation);
              _this2.set('rapo.decisionArgument', rapoDecision.argumentation);
              _this2.set('rapo.code', rapoDecision.code);
              _this2.set('rapo.decisionReason', rapoDecision.motif);
            }
          }

          window.scrollTo(0, document.body.scrollHeight);
        }).catch(function () {
          _this2.get('notifications').error('Erreur lors de la récupération de la prochaine question d\'instruction.');
        }).finally(function () {
          _this2.set('isLoadingNextInstructionTask', false);
        });
      },
      enableSendCompletionButton: function enableSendCompletionButton() {
        this.set('isSendToCompletionButtonDisabled', $('.missing-attachment:checked').length < 1);
      },


      // Envoyer le dossier en complétude
      sendCompletion: function sendCompletion() {
        if (_lodash.default.isNil(this.get('rapo.responseFormat'))) {
          this.get('notifications').error('Veuillez choisir un type d\'envoi.');
        } else {
          var missingAttachments = $('input.missing-attachment:checked').map(function () {
            return this.id;
          }).get();

          this.set('isLoadingRapo', true);
          this.set('rapo.mandatoryMissingAttachments', missingAttachments);

          this.saveRapo('SEND_COMPLETION', 'TO_SEND_COMPLETION');
        }
      },
      rollbackInstructionTask: function rollbackInstructionTask() {
        var _this3 = this;

        var processInstanceId = this.get('rapo.workflowProcessId');

        this.set('isLoadingNextInstructionTask', true);
        this.get('ajax').request(_environment.default.APP.host + '/rapo/api/workflow/' + processInstanceId + '/rollback', {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          },
          contentType: 'application/json'
        }).then(function (_ref2) {
          var processInstanceId = _ref2.processInstanceId,
              tasks = _ref2.tasks;

          _this3.set('rapo.workflowProcessId', processInstanceId);
          _this3.set('instructions', tasks);

          // On cache forcément les informations de la décision finale
          _this3.set('enableAcceptRapo', false);
          _this3.set('enableRejectRapo', false);
          _this3.set('rapo.decisionArgument', null);
          _this3.set('missingAttachmentsForInstruction', []);
        }).catch(function () {
          _this3.get('notifications').error('Erreur lors du chargement de l\'instruction précédente');
        }).finally(function () {
          _this3.set('isLoadingNextInstructionTask', false);
        });
      },


      // Accepter le RAPO
      acceptRAPO: function acceptRAPO() {
        if (_lodash.default.isNil(this.get('rapo.responseFormat'))) {
          this.get('notifications').error('Veuillez choisir un type d\'envoi.');
        } else {
          this.send('acceptOrRejectRapo', 'performAcceptRapo');
        }
      },


      // Refuser le RAPO
      rejectRAPO: function rejectRAPO() {
        if (_lodash.default.isNil(this.get('rapo.responseFormat'))) {
          this.get('notifications').error('Veuillez choisir un type d\'envoi.');
        } else {
          this.send('acceptOrRejectRapo', 'performRejectRapo');
        }
      },
      acceptOrRejectRapo: function acceptOrRejectRapo(actionName) {
        if (this.get('hasBuyer')) {
          var that = this;
          // Petit hack : utiliser la validation required de HTML5 pour le fomulaire du nouvel acquéreur
          $('#buyer-form').submit(function (e) {
            e.preventDefault();

            that.send(actionName);
          });

          $('#validate-buyer-form').trigger('click');
        } else {
          this.set('rapo.newBuyer', null);
          this.send(actionName);
        }
      },
      performRejectRapo: function performRejectRapo() {
        this.set('isLoadingRejectRapo', true);
        if (!_lodash.default.isNil(this.get('rapo.correctiveFps'))) {
          this.set('rapo.issue', 'CORRECTION');
        } else {
          this.set('rapo.issue', 'REJECTION');
          this.set('rapo.correctiveFps', null);
        }

        this.saveRapo('FINISH_INSTRUCTION', 'TO_VALIDATE');
      },
      performAcceptRapo: function performAcceptRapo() {
        var instructions = this.get('instructions');
        var isPMR = true;
        var pmrQuestions = _dictionary.default.pmrQuestions;

        // FIXME elle sert à quoi la boucle ?
        for (var key in pmrQuestions) {
          isPMR = instructions.find(function (i) {
            return i.name === 'Contrôle scooter ?';
          }) !== undefined;
        }

        this.set('isLoadingAcceptRapo', true);
        this.set('rapo.issue', 'ACCEPTANCE');
        this.set('rapo.correctiveFps', null);
        this.set('rapo.isPmr', isPMR);
        this.saveRapo('FINISH_INSTRUCTION', 'TO_VALIDATE');
      },


      // Valider l'instruction
      validateInstruction: function validateInstruction() {
        var _this4 = this;

        this.set('isLoadingValidateInstruction', true);
        this.get('validateRapoOnControlSide')(this).then(function () {
          return _this4.saveRapo('VALIDATE_INSTRUCTION', 'TO_RESPOND');
        }).finally(function () {
          return _this4.set('isLoadingValidateInstruction', false);
        });
      },


      // Refuser l'instruction
      declineInstruction: function declineInstruction() {
        this.set('isLoadingDeclineInstruction', true);
        this.saveRapo('REFUSE_INSTRUCTION', 'TO_REINSTRUCT');
      },
      setResponseFormat: function setResponseFormat(format) {
        this.set('responseFormat', format);
        this.set('rapo.responseFormat', format.key);
      },
      downloadPdfResponse: function downloadPdfResponse() {
        var _this5 = this;

        this.set('isLoadingPdfResponse', true);
        this.get('ajax').request(_environment.default.APP.host + '/rapo/api/rapos/' + this.get('rapo.number') + '/rapoResponse', {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          }
        }).then(function (file) {
          (0, _downloadFile.default)(file.fileName, file.base64, 'application/pdf');
        }).finally(function () {
          return _this5.set('isLoadingPdfResponse', false);
        });
      },
      generateDefence: function generateDefence() {
        var _this6 = this;

        (0, _sweetalert.default)({
          title: 'Confirmation',
          html: '<p class="text-justify">\n                Vous \xEAtes sur le point de g\xE9n\xE9rer un m\xE9moire en d\xE9fense pour un recours devant la CCSP.\n              </p>\n              <p class="text-justify">\n                Cette action ne peut \xEAtre r\xE9alis\xE9e qu\'une seule fois. Les \xE9l\xE9ments t\xE9l\xE9charg\xE9s doivent donc \xEAtre conserv\xE9s.\n              </p>\n              <br/>\n              <p class="text-justify">\n                Confirmez-vous la g\xE9n\xE9ration du m\xE9moire ?\n              </p>',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Télécharger le mémoire',
          cancelButtonText: 'Annuler',
          target: '#app-container'
        }).then(function () {
          _this6.set('isLoadingGenerateDefence', true);
          _this6.set('isGenerateDefenceLoaded', false);
          // Appel pour récupérer l'archive
          var currentUser = _this6.get('currentUser.user');
          _this6.get('ajax').request(_environment.default.APP.host + '/rapo/api/rapos/' + _this6.get('rapo.number') + '/ccsp', {
            method: 'PUT',
            data: {
              agentId: currentUser.get('id'),
              fps: _this6.get('rapo.fullFps'),
              zonesAndSectors: _this6.get('sharedVariable.tariffZones')
            },
            contentType: 'application/json',
            headers: {
              Authorization: 'Bearer ' + _this6.get('session.session.authenticated.id_token'),
              City: _this6.get('currentUser.city')
            }
          }).then(function (file) {
            (0, _downloadFile.default)(file.fileName, file.base64, 'application/zip');
            _this6.set('isGenerateDefenceLoaded', true);
            _this6.set('redirectToCcspClaimForm', true);
          }).catch(function () {
            _this6.get('notifications').error('Erreur lors du chargement du mémoire en défense');
            _this6.set('redirectToCcspClaimForm', false);
          }).finally(function () {
            _this6.set('isLoadingGenerateDefence', false);
            if (_this6.get('redirectToCcspClaimForm')) {
              _this6.openCcspClaimForm(_this6.get('rapo.fpsLegalId'));
            }
          });
        }).catch(function () {/* aucun traitement, mais on fait disparaître l'erreur dans la console */});
      }
    }
  });
});